import styled from 'styled-components';

export const Container = styled.div`
  padding: 1.5rem;
  height: 100%;
`;

export const StoresPanel = styled.div`
  background-color: ${({ theme }) => theme.background_primary};
  border-radius: 0.25rem;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  h2 {
    margin-bottom: 1rem;
  }

  .loading {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .tableActions {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;

export const StoresTable = styled.div`
  margin-top: 1.5rem;
  width: 100%;

  .header {
    border-top: 0.063rem solid ${({ theme }) => theme.background_secondary};
    border-bottom: 0.063rem solid ${({ theme }) => theme.background_secondary};
    padding: 0.5rem 1rem;
    display: grid;
    grid-template-columns: 0.5fr repeat(5, 1fr) 0.5fr;

    p {
      border: none;
      padding: 0;
      font-weight: 700;
    }
  }

  .rowTable {
    padding: 0.5rem 1rem;
    display: grid;
    grid-template-columns: 0.5fr repeat(5, 1fr) 0.5fr;
    border-bottom: 0.063rem solid ${({ theme }) => theme.background_secondary};

    button:first-child {
      color: ${({ theme }) => theme.button_success};
      font-size: 2rem;
      display: flex;
      align-items: center;
    }

    button:last-child {
      color: ${({ theme }) => theme.button_primary};
      font-size: 1.5rem;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
`;

export const SearchBar = styled.div`
  background-color: ${({ theme }) => theme.background_high};
  border: 0.063rem solid ${({ theme }) => theme.font_secondary};
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.font_low};
  margin-left: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  div {
    padding: 0 0.75rem 0 0.75rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.font_low};
    font-size: 1.5rem;
  }

  .inputInput {
    width: 100%;
    color: ${({ theme }) => theme.font_low};
    font-size: 0.875rem;
  }
`;

export const Pill = styled.p`
  background-color: ${props => props.color};
  padding: 0.25rem 0.625rem;
  border-radius: 1rem;
  margin: 0.25rem;
  width: fit-content;
`;
