import React, { createContext, useContext, useState } from 'react';

// Model import
import {
  IBrand,
  IPayment,
  IBrandAndPaymentSettingsContextData,
} from './models';

// Context
export const CurrentBrandContext =
  createContext<IBrandAndPaymentSettingsContextData>(
    {} as IBrandAndPaymentSettingsContextData,
  );

// Provider
export const BrandAndPaymentSettingsProvider: React.FC = ({ children }) => {
  // Local states
  const [current_brand, set_current_brand] = useState<IBrand>();
  const [current_payment, set_current_payment] = useState<IPayment>();

  return (
    <CurrentBrandContext.Provider
      value={{
        current_brand,
        set_current_brand,

        current_payment,
        set_current_payment,
      }}
    >
      {children}
    </CurrentBrandContext.Provider>
  );
};

// Hook
export function useBrandAndPaymentSettings(): IBrandAndPaymentSettingsContextData {
  const context = useContext(CurrentBrandContext);
  return context;
}
