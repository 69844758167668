import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .paymentName {
    display: flex;
    flex-direction: column;
  }

  .saveButton {
    margin-bottom: 0.5rem;
  }
`;
