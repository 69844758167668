import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import { Checkbox } from '@material-ui/core';

import { StatusLabel } from './styles';
import { StatusArrayObject } from '../../constants';

const SelectStatusComponent: React.FC<{
  hidden: boolean;
  statusPillChecked: string[];
  setStatusFilter: any;
  statusFilter: string[];
}> = ({ hidden, statusPillChecked, setStatusFilter, statusFilter }) => {
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);

  const changeStatusFilter = useCallback(
    (eventStatus: string) => {
      if (
        eventStatus === 'show_all' &&
        StatusArrayObject.every(element => statusFilter.includes(element))
      ) {
        return setStatusFilter([]);
      }

      if (eventStatus === 'show_all') {
        return setStatusFilter(StatusArrayObject);
      }

      if (statusFilter.includes(eventStatus)) {
        return setStatusFilter(
          statusFilter.filter(element => element !== eventStatus),
        );
      }
      return setStatusFilter([...statusFilter, eventStatus]);
    },
    [statusFilter, setStatusFilter],
  );

  const showAll = StatusArrayObject.every(element =>
    statusPillChecked.includes(element),
  );

  if (hidden) {
    return <></>;
  }
  return (
    <>
      <div className="pillOptions">
        <div className="options">
          <div className="option">
            <StatusLabel color={themeContext.font_success}>
              {t('@orders/PAID_STATUS', 'Confirmado')}
            </StatusLabel>
            <Checkbox
              checked={statusPillChecked.includes('COMPLETED')}
              onChange={() => {
                changeStatusFilter('COMPLETED');
              }}
              color="primary"
            />
          </div>
          <hr />
          <div className="option">
            <StatusLabel color="rgba(158, 158, 158, 1)">
              {t('@orders/WAITING_STATUS', 'Pendente')}
            </StatusLabel>
            <Checkbox
              checked={statusPillChecked.includes('PENDING')}
              onChange={() => {
                changeStatusFilter('PENDING');
              }}
              color="primary"
            />
          </div>
          <hr />
          <div className="option">
            <StatusLabel color={themeContext.font_danger}>
              {t('@orders/CANCELED_STATUS', 'Cancelado')}
            </StatusLabel>
            <Checkbox
              checked={statusPillChecked.includes('CANCELED')}
              onChange={() => {
                changeStatusFilter('CANCELED');
              }}
              color="primary"
            />
          </div>
          <hr />
          <div className="option">
            <StatusLabel color="rgba(242, 153, 74, 1)">
              {t('@orders/ERROR_STATUS', 'A Ajustar')}
            </StatusLabel>
            <Checkbox
              checked={statusPillChecked.includes('WAIT_ADJUST')}
              onChange={() => {
                changeStatusFilter('WAIT_ADJUST');
              }}
              color="primary"
            />
          </div>
          <hr />
          <div className="option">
            <StatusLabel color={themeContext.font_success}>
              {t('@orders/ADJUSTED', 'Ajustado')}
            </StatusLabel>
            <Checkbox
              checked={statusPillChecked.includes('ADJUSTED')}
              onChange={() => {
                changeStatusFilter('ADJUSTED');
              }}
              color="primary"
            />
          </div>
          <hr />
          <div className="option">
            {t('@orders/ERROR_STATUS', 'Mostrar todos')}
            <Checkbox
              checked={showAll}
              onChange={() => {
                changeStatusFilter('show_all');
              }}
              color="primary"
            />
          </div>
        </div>
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </>
  );
};

export default SelectStatusComponent;
