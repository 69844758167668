import styled from 'styled-components';

export const PackageSectionContent = styled.div`
  display: flex;
  font-weight: 700;
  font-size: 1.125rem;

  .packageItems {
    margin-top: 1rem;
  }

  .listOrderPackages {
    margin-top: 1rem;
    align-items: center;
    flex-direction: row;
  }

  .listOrderPackages span {
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  min-height: -webkit-fill-available;

  .listPackageItems {
    width: 37rem;
  }
`;

export const SectionColumn = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
`;

export const HorizontalDivider = styled.div`
  border: ${({ theme }) => theme.background_secondary} solid 0.063rem;
  justify-self: center;
  align-self: center;
`;

export const SubSectionColumn = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;

  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding-bottom: 0.6rem;

  .integrationStepsColumn {
    width: 35rem;
  }
`;
