import IPillsOptions from 'interfaces/IPillsOptions';
import React from 'react';
import ISortStatus from 'interfaces/ISortStatus';
import { Box, TableCell, TableRow } from '@mui/material';
import { MdFilterAlt, MdOutlineFilterAlt } from 'react-icons/md';
import { StatusArrayObject } from '../../constants';
import { TableHeadStyled } from './styles';
import SortDateToggleButton from './SortDateToggleButton';

type SetSortStatusFunction = React.Dispatch<React.SetStateAction<ISortStatus>>;

const TableHeaderOrder: React.FC<{
  isDetails: boolean;
  setPillsOptions: (pill: IPillsOptions) => void;
  pillsOptions?: IPillsOptions;
  statusPillChecked?: string[];
  sortStatus?: ISortStatus;
  setSortStatus?: SetSortStatusFunction;
}> = ({
  isDetails,
  setPillsOptions,
  pillsOptions,
  statusPillChecked,
  sortStatus,
  setSortStatus,
}) => {
  return (
    <TableHeadStyled>
      <TableRow>
        <TableCell className="fitColumn">
          <Box className="fitContent">
            <Box>Status</Box>
            {isDetails ? (
              ''
            ) : (
              <Box>
                <button
                  type="button"
                  hidden={false}
                  onClick={() => {
                    setPillsOptions({
                      status: !pillsOptions?.status,
                    });
                  }}
                >
                  {statusPillChecked?.length &&
                  statusPillChecked?.length !== StatusArrayObject.length ? (
                    <MdFilterAlt />
                  ) : (
                    <MdOutlineFilterAlt />
                  )}
                </button>
              </Box>
            )}
          </Box>
        </TableCell>
        <TableCell className="fitColumn">
          <Box className="fitContent">
            <Box>Data de criação</Box>
            <Box>
              <SortDateToggleButton
                sortStatus={sortStatus}
                setSortStatus={setSortStatus}
                identifier="created_at"
              />
            </Box>
          </Box>
        </TableCell>
        <TableCell className="fitColumn">
          <Box className="fitContent">
            <Box>Última atualização</Box>
            <Box>
              <SortDateToggleButton
                sortStatus={sortStatus}
                setSortStatus={setSortStatus}
                identifier="updated_at"
              />
            </Box>
          </Box>
        </TableCell>
        <TableCell>Loja</TableCell>
        <TableCell>Valor</TableCell>
        <TableCell>Pagamentos</TableCell>
        <TableCell>CPF do cliente</TableCell>
        <TableCell>Pacotes</TableCell>
        {isDetails ? '' : <TableCell />}
      </TableRow>
    </TableHeadStyled>
  );
};
export default TableHeaderOrder;
