// Action types
export const StoresActionTypes = {
  STORES_SET: '@stores/STORES_SET',
};

export interface IStore {
  id: string;
  code: string;
  name: string;
  enabled: boolean;
  codename: string;
  zipcode: any;
  latitude: any;
  longitude: any;
  street: any;
  number: any;
  complement: any;
  neighborhood: any;
  city: any;
  state: any;
  country: any;
  image_url: string;
  created_at: Date;
  updated_at: Date;
  brands: IBrands[];
  package_types: IPackageTypes[];
  payment_types: IPaymentTypes[];
  payment_providers: IPaymentProviders[];
  opening_periods: IOpeningPeriods[];
}

export interface IBrands {
  id: string;
  store_id: string;
  brand_id: string;
  enabled: boolean;
  created_at: Date;
  updated_at: Date;
  brand: IBrand;
}

export interface IBrand {
  id: string;
  code: number;
  name: string;
  enabled: boolean;
  image_url: string;
  payload: ISimplePayload;
  created_at: Date;
  updated_at: Date;
  providers: IProviders[];
}

export interface ISimplePayload {
  product_code_pattern: string;
  product_color_pattern?: string;
}

export interface IProviders {
  id: string;
  brand_id: string;
  name: string;
  code: string;
  enabled: boolean;
  payload: IPayload;
  created_at: Date;
  updated_at: Date;
}

export interface IPayload {
  key: string;
  token: string;
  base_sudomain: string;
  salescode_pattern: string;
  sales_channel_code: number;
  payment_system_code: number;
}

export interface IPackageTypes {
  id: string;
  store_id: string;
  package_type_id: string;
  enabled: boolean;
  created_at: Date;
  updated_at: Date;
  package_type: IPackageType;
}

export interface IPackageType {
  id: string;
  code: string;
  name: string;
  enabled: boolean;
  created_at: Date;
  updated_at: Date;
}

export interface IPaymentTypes {
  id: string;
  store_id: string;
  payment_type_id: string;
  enabled: boolean;
  created_at: Date;
  updated_at: Date;
  payment_type: IPaymentType;
}

export interface IPaymentType {
  id: string;
  code: string;
  name: string;
  enabled: boolean;
  created_at: Date;
  updated_at: Date;
}

export interface IPaymentProviders {
  id: string;
  store_id: string;
  payment_provider_id: string;
  enabled: boolean;
  payload: ISecondPayload;
  created_at: Date;
  updated_at: Date;
  provider: IProvider;
}

export interface ISecondPayload {
  token: string;
  terminals: string[];
}

export interface IProvider {
  id: string;
  payment_type_id: string;
  name: string;
  code: string;
  enabled: boolean;
  payload: any;
  created_at: Date;
  updated_at: Date;
  payment_type: IPaymentType;
}

export interface IOpeningPeriods {
  id: string;
  store_id: string;
  enabled: boolean;
  description: string;
  specific_day: any;
  weekday: number;
  start_hour: number;
  start_minute: number;
  end_hour: number;
  end_minute: number;
  created_at: Date;
  updated_at: Date;
}

// State
export interface IStoresState {
  stores_loaded: boolean;
  stores_data: IStore[];
}
