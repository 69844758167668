import styled from 'styled-components';

export const LetterLink = styled.div`
  display: flex-start;
  justify-content: space-between;
  justify-content: right;
  color: ${({ theme }) => theme.background_secondary};
`;

export const Link = styled.a`
  display: flex-end;
  color: ${({ theme }) => theme.background_secondary};
  text-decoration: underline;
`;
