import React, { useState } from 'react';
import { useGetOrderStatus } from '@hooks/useGetOrderStatus';
import formatDateHour from '@util/formatDateHour';
import { MdKeyboardArrowDown, MdWarning } from 'react-icons/md';
import { IOrderData } from '@hooks/useCurrentOrder/models';
import { Box, FormattedUserName } from './styles';

const AdjustedStatus: React.FC<{
  order: IOrderData;
}> = ({ order }) => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const { orderStatus } = useGetOrderStatus(order);

  const handleDrawerToggle = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  const manuallyAdjusted = orderStatus?.find(
    (item: any) => item.user && item.user.name,
  );

  if (!manuallyAdjusted) return null;

  return (
    <div style={{ marginBottom: '1rem' }}>
      <Box style={{ display: 'block' }}>
        <div
          key={manuallyAdjusted.id}
          style={{ marginBottom: '1rem' }}
          role="button"
          tabIndex={0}
          onClick={handleDrawerToggle}
          onKeyPress={e => {
            if (e.key === 'Enter' || e.key === ' ') {
              handleDrawerToggle();
            }
          }}
        >
          <p className="adjustedMessage">
            <MdWarning
              style={{
                marginRight: '0.5rem',
                fontSize: '1.2em',
                marginTop: '-2px',
              }}
            />
            <span>
              Atenção: informações de pagamento de pedidos ajustados por
              usuários podem ter sido alteradas fora do sistema e não servem
              para conferência.
            </span>
          </p>
          <p className="adjustedInfo">
            Ajustado em {formatDateHour(manuallyAdjusted.created_at).date} às{' '}
            {formatDateHour(manuallyAdjusted.created_at).hour} por{' '}
            <FormattedUserName>
              {manuallyAdjusted.user.name.toLowerCase()}
            </FormattedUserName>
            <div className="drawer-toggle" style={{ cursor: 'pointer' }}>
              Ver comentário{' '}
              {isDrawerOpen ? (
                <MdKeyboardArrowDown
                  style={{
                    fontSize: '1.8em',
                    marginLeft: '0.2em',
                    verticalAlign: 'middle',
                  }}
                />
              ) : (
                <MdKeyboardArrowDown
                  style={{
                    fontSize: '1.8em',
                    marginLeft: '0.2em',
                    verticalAlign: 'middle',
                  }}
                />
              )}
            </div>
          </p>
          {isDrawerOpen && (
            <div className="drawer-content">
              <p>{manuallyAdjusted.message}</p>
            </div>
          )}
        </div>
        <div className="divider" />
      </Box>
    </div>
  );
};

export default AdjustedStatus;
