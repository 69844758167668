import React from 'react';
import { MdReportProblem } from 'react-icons/md';
import { Box, ChangeStatusLink } from './styles';

interface IModalRef {
  current: {
    show: () => void;
  } | null;
}

const WaitStatus: React.FC<{ changeStatusModalRef: IModalRef }> = ({
  changeStatusModalRef,
}) => {
  return (
    <>
      <div style={{ marginBottom: '1rem' }}>
        <Box>
          <MdReportProblem
            style={{
              color: '#F2994A',
              marginRight: '5px',
              fontSize: '1.2em',
            }}
          />
          <p className="alert">Ajuste Necessário</p>
          <p className="alertMessage">
            Identificamos que esse pedido precisa de ajuste.
          </p>
        </Box>
        <ChangeStatusLink
          type="button"
          onClick={() => {
            changeStatusModalRef?.current?.show();
          }}
        >
          Alterar status para Ajustado
        </ChangeStatusLink>
      </div>
      <div className="divider" />
    </>
  );
};

export default WaitStatus;
