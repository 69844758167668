import styled from 'styled-components';

const HEADER_SIZE = 3.75;
const SIDEBAR_SIZE = 17.75;

interface IContentProps {
  mobileSize: boolean;
  leftMenu: boolean;
}

export const Container = styled.div``;

export const Main = styled.div``;

export const Content = styled.div<IContentProps>`
  height: 100vh;
  padding-top: ${HEADER_SIZE}rem;
  padding-left: ${props =>
    props.mobileSize ? '0' : props.leftMenu ? `${SIDEBAR_SIZE}rem` : '0rem'};
  transition: 0.25s;

  display: flex;
  flex-direction: column;
`;
