import React, { useEffect, useRef, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import {
  MdExitToApp,
  MdOutlineListAlt,
  MdOutlineMemory,
  MdOutlineStoreMallDirectory,
  MdOutlineConstruction,
  MdHelpOutline,
} from 'react-icons/md';
import { AiOutlineHome } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';

import Button from '@components/Button';
import Avatar from '@components/Avatar';

import { signOut } from '@store/modules/auth/actions';
import { setLeftMenu } from '@store/modules/ui/actions';

import { Container, ScrollBar } from './styles';

const Sidebar: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const history = useHistory();
  const themeContext = useContext(ThemeContext);

  const leftMenu = useSelector(state => state.ui.left_menu);
  const activePage = useSelector(state => state.ui.active_page);
  const user = useSelector(state => state.auth);

  const sidebarRef = useRef<HTMLDivElement>(null);

  const activeParent = (key: string) => activePage.startsWith(key);

  useEffect(() => {
    dispatch(
      setLeftMenu(
        !(
          Math.max(
            document.documentElement.clientWidth,
            window.innerWidth || 0,
          ) <= 768
        ),
      ),
    );
  }, [dispatch]);

  useEffect(() => {
    const ref = sidebarRef.current;
    const links = ref?.querySelectorAll('a, button');

    function closeSidebar() {
      if (
        Math.max(
          document.documentElement.clientWidth,
          window.innerWidth || 0,
        ) <= 768
      ) {
        dispatch(setLeftMenu(false));
      }
    }

    links?.forEach(element => {
      element.addEventListener('click', closeSidebar);
    });

    return () => {
      links?.forEach(element => {
        element.removeEventListener('click', closeSidebar);
      });
    };
  });

  const openNewWindow = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <Container
      ref={sidebarRef}
      open={leftMenu}
      mobileSize={
        Math.max(
          document.documentElement.clientWidth,
          window.innerWidth || 0,
        ) <= 768
      }
    >
      <ScrollBar autoHide>
        <div className="content">
          <section className="head">
            <div>
              <Avatar
                name={user.name || t('@sidebar/SOMA_STORE', 'Central Omni')}
                size={45}
                fixedColor={themeContext.background_low}
                noBackground
                src={user.avatar_url}
              />
            </div>
            <div className="info">
              <span className="name">{user.name}</span>
              <span className="store">{user.store?.name}</span>
            </div>
          </section>
          <section className="navigation">
            <Button
              startIcon={
                <AiOutlineHome style={{ color: themeContext.font_secondary }} />
              }
              variant="text"
              color={themeContext.font_primary}
              className={`MuiButton-fullWidth button-wrapper ${
                activeParent('@dashboard') && 'active'
              }`}
              style={
                activeParent('@dashboard') ? { backgroundColor: '#212121' } : {}
              }
              onClick={() => history.push('/dashboard')}
            >
              {t('@sidebar/HOME', 'Início')}
            </Button>
            <Button
              startIcon={
                <MdOutlineListAlt
                  style={{ color: themeContext.font_secondary }}
                />
              }
              variant="text"
              color={themeContext.font_primary}
              style={
                activeParent('@orders') ? { backgroundColor: '#212121' } : {}
              }
              className={`MuiButton-fullWidth button-wrapper ${
                activeParent('@orders') && 'active'
              }`}
              onClick={() => {
                const params: Record<
                  'startDate' | 'endDate' | 'status' | 'package',
                  string
                > = {
                  startDate: '',
                  endDate: '',
                  status: 'WAIT_ADJUST',
                  package: '',
                };
                history.push({
                  pathname: '/orders',
                  search: `?${new URLSearchParams(params).toString()}`,
                });
              }}
            >
              {t('@sidebar/ORDERS', 'Pedidos')}
            </Button>
            <Button
              startIcon={
                <MdOutlineStoreMallDirectory
                  style={{ color: themeContext.font_secondary }}
                />
              }
              variant="text"
              color={themeContext.font_primary}
              className={`MuiButton-fullWidth button-wrapper ${
                activeParent('@stores') && 'active'
              }`}
              style={
                activeParent('@stores') ? { backgroundColor: '#212121' } : {}
              }
              onClick={() => history.push('/stores')}
            >
              {t('@sidebar/STORES', 'Lojas')}
            </Button>
            <Button
              startIcon={
                <MdOutlineConstruction
                  style={{ color: themeContext.font_secondary }}
                />
              }
              variant="text"
              color={themeContext.font_primary}
              className={`MuiButton-fullWidth button-wrapper ${
                activeParent('@providers') && 'active'
              }`}
              style={
                activeParent('@providers') ? { backgroundColor: '#212121' } : {}
              }
              onClick={() => history.push('/providers')}
            >
              {t('@sidebar/PROVIDERS', 'Provedores')}
            </Button>
            <Button
              startIcon={
                <MdOutlineMemory
                  style={{ color: themeContext.font_secondary }}
                />
              }
              variant="text"
              color={themeContext.font_primary}
              className={`MuiButton-fullWidth button-wrapper ${
                activeParent('@logs') && 'active'
              }`}
              style={
                activeParent('@logs') ? { backgroundColor: '#212121' } : {}
              }
              onClick={() => history.push('/logs')}
            >
              {t('@sidebar/ERROR_LOG', ' Logs e cache')}
            </Button>
            <Button
              startIcon={
                <MdHelpOutline style={{ color: themeContext.font_secondary }} />
              }
              variant="text"
              color={themeContext.font_primary}
              className={`MuiButton-fullWidth button-wrapper ${
                activeParent('@help') && 'active'
              }`}
              style={
                activeParent('@help') ? { backgroundColor: '#212121' } : {}
              }
              onClick={() =>
                openNewWindow(
                  'https://sites.google.com/view/ajudacentralomni/menu',
                )
              }
            >
              {t('@sidebar/HELP_CENTER', ' Central de Ajuda')}
            </Button>
          </section>
          <section className="footer">
            <span className="divisor" />
            <Button
              startIcon={<MdExitToApp />}
              variant="text"
              color={themeContext.font_primary}
              onClick={() => dispatch(signOut())}
            >
              SAIR
            </Button>
          </section>
        </div>
      </ScrollBar>
    </Container>
  );
};

export default Sidebar;
