import styled from 'styled-components';

export const VtexLink = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.background_secondary};
`;

export const InvoiceLinkWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

export const InvoiceLink = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.background_secondary};
  padding: 0.5rem;
  max-width: 200px;
`;

export const Link = styled.a`
  font-weight: 700;
  color: ${({ theme }) => theme.background_secondary};
  text-decoration: underline;
  margin-right: 0.22rem;
`;

export const SectionTitleSecondary = styled.h2`
  display: flex;
  font-weight: 700;
  font-size: 1.125rem;
  color: ${({ theme }) => theme.background_high};

  .value {
    margin-left: 2rem;
  }
`;
