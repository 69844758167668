import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  .titleRow {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      font-weight: 700;
      font-size: 1.25rem;
    }

    .wideField {
      width: 50%;
      margin-right: 0.75rem;
    }

    .tightField {
      width: 25%;
      .lastTightField {
        margin-left: 0.75rem;
      }
    }
  }

  .editionArea {
    padding: 0.625rem;
    display: flex;
    flex-direction: column;

    .closedTab {
      display: flex;
      align-items: flex-start;
      .rightArrow {
        font-size: 1rem;
        width: 10%;
        button {
          color: ${({ theme }) => theme.button_high};
        }
      }
      .closedTitle {
        font-weight: 600;
        font-size: 1rem;
        width: 40%;
      }
      .pill {
        width: 40%;
      }
    }

    .openTab {
      display: flex;
      flex-direction: column;
      margin-bottom: 0.75rem;

      .title {
        display: flex;
        .downArrow {
          font-size: 1rem;
          width: 10%;
          button {
            color: ${({ theme }) => theme.button_high};
          }
        }
        .text {
          font-weight: 600;
          font-size: 1rem;
          width: 40%;
        }
      }
      .content {
        display: flex;
        flex-direction: column;
        .contentNameRow {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-left: 10%;
          font-size: 1rem;
          button {
            color: ${({ theme }) => theme.button_primary};
            font-size: x-large;
          }
          .contentName {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            .toDelete {
              text-decoration: line-through;
            }
          }

          .closedProvider {
            display: flex;
            align-items: flex-start;
            .closedArrowProvider {
              font-size: 1rem;
              /* width: 10%; */
              button {
                color: ${({ theme }) => theme.button_high};
              }
            }
          }

          .openProvider {
            display: flex;
            flex-direction: column;
            width: 100%;
            /* align-items: flex-start; */
            .openProviderRow {
              display: flex;
              justify-content: space-between;
              align-items: center;
              /* width: 100%; */
              .openProviderTitle {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .toDelete {
                  text-decoration: line-through;
                }
              }
            }
          }
        }
      }
      .addProviderArea {
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        /* align-items: center; */
        margin-left: 10%;
        font-size: 1rem;

        .addProviderAreaTitle {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          button {
            color: ${({ theme }) => theme.button_primary};
            font-size: x-large;
          }

          .titleAndSwitch {
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }
        }
      }
      .providerButton {
        display: flex;
        justify-content: center;
        margin-top: 0.75rem;
        margin-bottom: 0.75rem;
        button {
          font-weight: 600;
          font-size: 1rem;
          color: ${({ theme }) => theme.button_primary};
        }
      }

      .addButton {
        display: flex;
        justify-content: center;
        margin-top: 0.75rem;
        margin-bottom: 0.75rem;
        button {
          font-weight: 600;
          font-size: 1rem;
          color: ${({ theme }) => theme.button_primary};
        }
      }
    }
  }

  .firstRow {
    display: flex;
    align-items: center;

    div {
      padding-right: 0.2rem;
    }
  }

  .regularRow {
    margin-bottom: 0.75rem;
  }
`;

export const Pill = styled.p`
  background-color: ${props => props.color};
  padding: 0.25rem 0.625rem;
  border-radius: 1rem;
  margin: 0.25rem;
  width: fit-content;
`;
