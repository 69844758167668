import IStatusPillOptions from 'interfaces/IStatusPillOptions';

export const makeArrayStatus = (statusPill: IStatusPillOptions): string[] => {
  const arrayStatus = [];
  if (statusPill.confirmed) {
    arrayStatus.push('COMPLETED');
  }
  if (statusPill.pending) {
    arrayStatus.push('PENDING');
  }
  if (statusPill.canceled) {
    arrayStatus.push('CANCELED');
  }
  if (statusPill.adjust) {
    arrayStatus.push('WAIT_ADJUST');
  }
  if (statusPill.adjusted) {
    arrayStatus.push('ADJUSTED');
  }
  return arrayStatus;
};
export const CPFformat = (cpf: string | undefined) => {
  cpf = cpf?.replace(/[^\d]/g, '');
  return cpf?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
};
