import {
  IOrder,
  IOrderPaymentsCharges,
  IOrderPaymentsPaymentType,
} from '@hooks/useCurrentOrder/models';

import React, { useState } from 'react';

import EmptyPaymentInfo from '@assets/empty-payment-info.svg';

import findValidCharge from '@util/findValidCharge';
import getStorePaymentProviderPayloadInfos from '@util/getStorePaymentProviderPayloadInfos';
import ChargesOptions from './ChargesOptions';
import ChargeDetails from './ChargesDetails';

interface IChargeInformation {
  charge: IOrderPaymentsCharges;
  chargeIndex: number;
  paymentType: string;
  merchantCode?: string;
  accountCode?: string;
}

const getPaymentType = (paymentType: IOrderPaymentsPaymentType): string => {
  return paymentType.name;
};

const ChargesPanel: React.FC<{ order?: IOrder }> = ({ order }) => {
  const orderWithCharges = findValidCharge(order);

  const [selectedChargeInformation, setSelectedChargeInformation] = useState<
    IChargeInformation | undefined
  >(() => {
    if (!orderWithCharges) return undefined;
    return {
      charge: orderWithCharges.charges
        ? orderWithCharges.charges[0]
        : ([] as unknown as IOrderPaymentsCharges),
      chargeIndex: 1,
      paymentType: getPaymentType(orderWithCharges.payment_type),
      merchantCode:
        getStorePaymentProviderPayloadInfos(orderWithCharges).merchant_code,
      accountCode:
        getStorePaymentProviderPayloadInfos(orderWithCharges).account_code,
    };
  });

  if (!selectedChargeInformation) {
    return (
      <div className="emptyChargeInfo">
        <img
          src={EmptyPaymentInfo}
          alt="Prancheta em Branco"
          className="clipboard"
        />
        <p className="selectedChargeInformation">
          Não há cobranças associadas a esse pedido.
        </p>
        <p>
          Ele pode estar pendente de pagamento ou ter sido cancelado
          <p>sem que o pagamento tenha sido realizado.</p>
        </p>
      </div>
    );
  }
  return (
    <>
      <ChargesOptions
        order={order}
        onSelectedChargeChange={setSelectedChargeInformation}
      />
      <ChargeDetails
        charge={selectedChargeInformation.charge}
        chargeIndex={selectedChargeInformation.chargeIndex}
        paymentType={selectedChargeInformation.paymentType}
        accountCode={selectedChargeInformation.accountCode}
        merchantCode={selectedChargeInformation.merchantCode}
        orderStatus={order?.order?.status}
      />
    </>
  );
};

export default ChargesPanel;
