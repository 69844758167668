import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import React, { useRef, useCallback, useState, useContext } from 'react';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { Form } from '@unform/web';
import { FormHandles, SubmitHandler } from '@unform/core';
import { ThemeContext } from 'styled-components';
import * as Yup from 'yup';

// Service import
import api from '@services/api';

// Action import
import { signFailure, signInSuccess } from '@store/modules/auth/actions';

// Asset impoort
import { ReactComponent as Logo } from '@assets/soma.svg';

// Component import
import Input from '@components/Input';
import Button from '@components/Button';
import Checkbox from '@components/Checkbox';
import Modal, { IModalRef } from '@components/Modal';

// Style import
import { Container, PasswordVisibleButton, ModalContent } from './styles';

const Login: React.FC = () => {
  // Get translation function
  const { t } = useTranslation();

  // Get dispatch hook
  const dispatch = useDispatch();

  // Local refs
  const formRef = useRef<FormHandles>(null);
  const modalPasswordRef = useRef<IModalRef>(null);
  const inputPasswordRef = useRef<HTMLInputElement>(null);

  // Local states
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  // Get theme context
  const themeContext = useContext(ThemeContext);

  // Submit data
  const handleSubmit: SubmitHandler<{
    login: string;
    password: string;
    persist: boolean;
  }> = useCallback(
    async data => {
      // Clear form errors
      if (formRef.current) formRef.current.setErrors({});

      // Setup a schema to be validated
      const schema = Yup.object()
        .shape({
          login: Yup.string()
            .strict()
            .typeError(
              t(
                '@login/INVALID_LOGIN_TYPE',
                'O login deve ser composto por caracteres alfanuméricos.',
              ),
            )
            .required(t('@login/REQUIRED_LOGIN', 'Informe o login.')),
          password: Yup.string()
            .strict()
            .typeError(
              t(
                '@login/INVALID_PASSWORD_TYPE',
                'A senha deve ser composta por caracteres alfanuméricos.',
              ),
            )
            .required(t('@login/REQUIRED_PASSWORD', 'Informe uma senha.')),
          persist: Yup.boolean()
            .strict()
            .typeError(
              t(
                '@login/INVALID_PERSIST_TYPE',
                'A informação sobre manutenção de sessão é inválida.',
              ),
            ),
        })
        .noUnknown(true, t('@login/INVALID_REQUEST', 'Requisição inválida.'));

      try {
        await schema.validate(data, {
          abortEarly: false,
          stripUnknown: false,
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const validationErrors: { [key: string]: string } = {};
          const errors: string[] = [];
          err.inner.forEach(error => {
            errors.push(error.message);
            if (error.path) validationErrors[error.path] = error.message;
          });
          if (formRef.current) formRef.current.setErrors(validationErrors);
          toast.error(errors[0]);
        }
        return;
      }

      // Get data from request
      const { login, password, persist } = data;

      // Loading set
      setLoading(true);

      try {
        // Call API
        const response = await api.post('admin/session', {
          login,
          password,
        });

        // Handle sign success
        dispatch(signInSuccess(response.data, !!persist));

        // Loading set
        setLoading(false);
      } catch (err: any) {
        // Check if server had return a error
        if (err.response?.data?.message) toast.error(err.response.data.message);
        else
          toast.error(
            t(
              '@general/CONNECTION_FAILURE_ERROR',
              'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
            ),
          );

        // Handle sign failure
        dispatch(signFailure());

        // Loading set
        setLoading(false);
      }
    },
    [t, dispatch],
  );

  return (
    <Container>
      <div className="login">
        <div className="logo">
          <Logo fill={themeContext.button_high} />
        </div>
        <Form ref={formRef} onSubmit={handleSubmit} autoComplete="off">
          <Input
            disabled={loading}
            name="login"
            label={t('@login/LOGIN', 'Login')}
            onEnterKey={() => inputPasswordRef.current?.focus()}
          />
          <Input
            ref={inputPasswordRef}
            onEnterKey={() => formRef.current?.submitForm()}
            name="password"
            disabled={loading}
            label={t('@login/PASSWORD', 'Senha')}
            type={passwordVisible ? 'text' : 'password'}
            endAdornment={
              <PasswordVisibleButton
                type="button"
                onClick={() => {
                  if (!loading) setPasswordVisible(!passwordVisible);
                }}
              >
                {passwordVisible ? (
                  <MdVisibilityOff
                    color={themeContext.font_secondary}
                    size={25}
                  />
                ) : (
                  <MdVisibility color={themeContext.font_secondary} size={25} />
                )}
              </PasswordVisibleButton>
            }
          />
          <Checkbox
            disabled={loading}
            name="persist"
            defaultChecked
            label="Mantenha-me conectado"
          />
        </Form>
        <button
          className="forgot"
          type="button"
          disabled={loading}
          onClick={() => modalPasswordRef.current?.show()}
        >
          {t('@login/UNKNOWN_PASSWORD', 'Não sabe sua senha?')}
        </button>
        <Button
          onClick={() => formRef.current?.submitForm()}
          disabled={loading}
        >
          {t('@login/SUBMIT', 'ENTRAR')}
        </Button>
      </div>
      <Modal size="small" ref={modalPasswordRef}>
        <ModalContent>
          <p>
            {t(
              '@login/FORGOT_PASSWORD_DESCRIPTION',
              'Suas credenciais para acesso ao Central Omni são as mesmas para o +Vendas.',
            )}
          </p>
          <Button
            disabled={loading}
            href="https://maisvendas.somalabs.com.br/forgotPassword"
            noFullWidth
            target="_blank"
          >
            {t('@login/FORGOT_PASSWORD_SUBMIT', 'Recuperar senha do + Vendas')}
          </Button>
          <Button
            disabled={loading}
            onClick={() => modalPasswordRef.current?.hide()}
            noFullWidth
            noMargin
            variant="text"
          >
            {t('@login/FORGOT_PASSWORD_RETURN', 'Voltar')}
          </Button>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default Login;
