import React, { useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { Container } from './styles';

interface IOrderButtonNavProps {
  onTabSelected: (page: string) => void;
}

const buttonsNames: { [key: string]: string } = {
  payments: 'Pagamentos',
  identity: 'Identificação',
  packages: 'Pacotes',
};

export const OrderButtonNav: React.FC<IOrderButtonNavProps> = ({
  onTabSelected,
}) => {
  const [activeButton, setActiveButton] = useState<string>('payments');

  const handleTabClick = (page: string) => {
    if (page !== activeButton) {
      onTabSelected(page);
      setActiveButton(page);
    }
  };

  return (
    <>
      <Container>
        <Grid container spacing={6}>
          {Object.keys(buttonsNames).map(tab => (
            <Grid item key={tab} xs={4} md={2}>
              <Button
                className={activeButton === tab ? 'active button' : 'button'}
                variant="text"
                onClick={() => handleTabClick(tab)}
              >
                {buttonsNames[tab]}
              </Button>
            </Grid>
          ))}
        </Grid>
        <hr />
      </Container>
    </>
  );
};
