import React, { useRef, useCallback, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles, SubmitHandler } from '@unform/core';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

// Component import
import Button from '@components/Button';
import Input from '@components/Input';
import Switch from '@components/Switch';

// Service import
import api from '@services/api';

// Hook import
import { useCurrentStore } from '@hooks/useCurrentStore';

// Style import
import { Container } from './styles';

// Interfaces
interface IProps {
  hide: () => any;
}

const StoreCreation: React.FC<IProps> = ({ hide }) => {
  // Get context
  const currentStore = useCurrentStore();

  // Local states
  const [switchEnable, setSwitchEnable] = useState(true);
  const [loading, setLoading] = useState(false);

  // Local refs
  const formProductIdByReferenceAndColorRef = useRef<FormHandles>(null);
  const storeNameInputRef = useRef<HTMLInputElement>(null);
  const storeFilialCodeInputRef = useRef<HTMLInputElement>(null);
  const storeZipCodeInputRef = useRef<HTMLInputElement>(null);

  // Get translation function
  const { t } = useTranslation();

  // Change store settings
  const storeCreationSubmit: SubmitHandler<{
    name: string;
    filialCode: string;
    zipCode: string;
    brands: string[];
    package_types: string[];

    paymentProviderManualPhysicalCardId: string;
    paymentTypeManualPhysicalCardId: string;

    paymentProviderStoneMundipaggId: string;
    paymentTypeStoneMundipaggId: string;
    paymentProviderTokenPhysicalCard: string;
    paymentProviderTerminalsPhysicalCard: string[];
    paymentProviderStoneMundipaggRelationId: string;

    paymentProviderStoneMundipaggLinkId: string;
    paymentTypeStoneMundipaggLinkId: string;
    paymentProviderTokenPaymentLink: string;
    paymentProviderMaxInstallmentQuantityPaymentLink: number;
    paymentProviderMinInstallmentQuantityPaymentLink: number;
    paymentProviderStoneMundipaggLinkRelationId: string;
  }> = useCallback(
    async data => {
      // Get brand, payment and package_type from data
      const { name, filialCode, zipCode } = data;

      // Setup a schema to be valitated
      const schema = Yup.object().shape({
        name: Yup.string()
          .strict()
          .typeError(
            t(
              '@store_controller_post/INVALID_NAME_TYPE',
              'O tipo do nome é inválido, deveria ser alfanumérico.',
            ),
          )
          .required(
            t('@store_controller_post/REQUIRED_NAME', 'O nome é obrigatório.'),
          ),
        filialCode: Yup.string()
          .strict()
          .typeError(
            t(
              '@stores/INVALID_CODE_TYPE',
              'O tipo do código identificador externo é inválido, deveria ser alfanumérico.',
            ),
          )
          .required(
            t(
              '@stores/REQUIRED_CODE',
              'O código identificador externo é obrigatório.',
            ),
          ),
        zipCode: Yup.string()
          .strict()
          .typeError(
            t(
              '@stores/INVALID_ZIPCODE_TYPE',
              'O tipo do CEP é inválido, deveria ser alfanumérico.',
            ),
          )
          .required(t('@stores/REQUIRED_ZIPCODE', 'O CEP é obrigatório.')),
      });

      try {
        await schema.validate(data, {
          abortEarly: false,
          stripUnknown: false,
        });
      } catch (err: any) {
        if (err instanceof Yup.ValidationError) {
          const validationErrors: { [key: string]: string } = {};
          const errors: string[] = [];
          err.inner.forEach(error => {
            errors.push(error.message);
            if (error.path) validationErrors[error.path] = error.message;
          });
          if (formProductIdByReferenceAndColorRef.current)
            formProductIdByReferenceAndColorRef.current.setErrors(
              validationErrors,
            );
          toast.error(errors[0]);
        }

        return;
      }

      const formattedZipCode = zipCode.replace(/[^0-9]/g, '');

      const codename = name.replace(' ', '-').toLowerCase();
      // Loading set
      setLoading(true);
      try {
        const response = await api.post(`admin/store/`, {
          code: filialCode,
          name: name.toUpperCase(),
          codename,
          enabled: switchEnable,
          zipcode: formattedZipCode,
        });

        const newStoreId = response.data.id;

        const newStoreResponse = await api.get(`admin/store/${newStoreId}`);

        currentStore.set_current_store(newStoreResponse.data);
      } catch (err: any) {
        // Check if server had return a error
        if (err.response?.data?.message) toast.error(err.response.data.message);
        else {
          toast.error(
            t(
              '@general/CONNECTION_FAILURE_ERROR',
              'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
            ),
          );
        }
      }
      hide();
    },
    [currentStore, hide, switchEnable, t],
  );

  return (
    <Container>
      <Form
        ref={formProductIdByReferenceAndColorRef}
        onSubmit={storeCreationSubmit}
      >
        <div className="titleRow">
          <p>{t('@stores/STORE_TITLE', 'Loja')}</p>
          <Switch
            checked={switchEnable}
            onChange={() => setSwitchEnable(!switchEnable)}
          />
        </div>
        <div className="titleRow">
          <div className="wideField">
            <Input ref={storeNameInputRef} name="name" label="Nome" />
          </div>
          <div className="tightField">
            <Input
              ref={storeZipCodeInputRef}
              name="zipCode"
              label="CEP"
              noFullWidth
              onKeyUp={(e: any) =>
                String(e.target.value).match(/\d+/g)?.join('')?.length === 8
              }
              mask="99.999-999"
              type="tel"
              inputmode="numeric"
              pattern="[0-9]*"
            />
          </div>
          <div className="tightField">
            <div className="lastTightField">
              <Input
                ref={storeFilialCodeInputRef}
                name="filialCode"
                label="Código Filial"
                onKeyUp={(e: any) =>
                  String(e.target.value).match(/\d+/g)?.join('')?.length === 6
                }
                mask="999999"
                type="tel"
                inputmode="numeric"
                pattern="[0-9]*"
              />
            </div>
          </div>
        </div>
        <div className="regularRow">
          <Button
            disabled={loading}
            onClick={() => {
              formProductIdByReferenceAndColorRef.current?.submitForm();
            }}
          >
            {t('@stores/SAVE_EDITION_BUTTON', 'Salvar')}
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default StoreCreation;
