import styled from 'styled-components';

export const PaginationContainer = styled.div`
  margin-top: 0.5rem;

  .MuiPagination-root {
    .MuiPaginationItem-page.Mui-selected {
      background-color: ${({ theme }) => theme.background_secondary};
      color: ${({ theme }) => theme.button_low};
    }

    .MuiPaginationItem-ellipsis {
      color: unset;
      font-size: 12px;
    }

    .MuiButtonBase-root {
      color: ${({ theme }) => theme.background_high};
    }
  }
`;
