import React from 'react';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { IOrderPackages } from '@hooks/useCurrentOrder/models';
import PackagePill from '@components/PackagePill';
import { RadioStyle } from './styles';

const PackageRadio = ({
  orderPackage,
  onSelectedPackageChange,
}: {
  orderPackage: IOrderPackages;
  onSelectedPackageChange: any;
}) => (
  <FormControl key={orderPackage.code}>
    <RadioStyle>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="show-all"
        name="radio-buttons-group"
        value={orderPackage.code}
        onChange={onSelectedPackageChange}
      >
        <FormControlLabel
          value={orderPackage.code}
          control={<Radio />}
          label={
            <span
              style={{
                fontSize: '0.75rem',
                display: 'inline-block',
                marginRight: '0.25rem',
                marginLeft: '0.25rem',
              }}
            >
              <PackagePill order_package={orderPackage} />
            </span>
          }
        />
      </RadioGroup>
    </RadioStyle>
  </FormControl>
);

export default PackageRadio;
