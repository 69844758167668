import React, { createContext, useContext, useState } from 'react';

// Model import
import {
  IStore,
  IBrand,
  IPaymentType,
  IPaymentProvider,
  IPackageType,
  ICurrentStoreContextData,
} from './models';

// Context
export const CurrentStoreContext = createContext<ICurrentStoreContextData>(
  {} as ICurrentStoreContextData,
);

// Provider
export const CurrentStoreProvider: React.FC = ({ children }) => {
  // Local states
  const [current_store, set_current_store] = useState<IStore>();
  const [possible_brands, set_possible_brands] = useState<IBrand[]>([]);
  const [possible_payment_types, set_possible_payment_types] = useState<
    IPaymentType[]
  >([]);
  const [possible_payment_providers, set_possible_payment_providers] = useState<
    IPaymentProvider[]
  >([]);
  const [possible_package_types, set_possible_package_types] = useState<
    IPackageType[]
  >([]);
  const [selected_brand, set_selected_brand] = useState<string[]>([]);
  const [selected_payment_type, set_selected_payment_type] = useState<string[]>(
    [],
  );
  const [selected_payment_provider, set_selected_payment_provider] = useState<
    string[]
  >([]);
  const [selected_package_type, set_selected_package_type] = useState<string[]>(
    [],
  );

  return (
    <CurrentStoreContext.Provider
      value={{
        current_store,
        set_current_store,

        possible_brands,
        set_possible_brands,

        possible_payment_types,
        set_possible_payment_types,

        possible_payment_providers,
        set_possible_payment_providers,

        possible_package_types,
        set_possible_package_types,

        selected_brand,
        set_selected_brand,

        selected_payment_type,
        set_selected_payment_type,

        selected_payment_provider,
        set_selected_payment_provider,

        selected_package_type,
        set_selected_package_type,
      }}
    >
      {children}
    </CurrentStoreContext.Provider>
  );
};

// Hook
export function useCurrentStore(): ICurrentStoreContextData {
  const context = useContext(CurrentStoreContext);
  return context;
}
