import { ThemeContext } from 'styled-components';
import React, { useContext } from 'react';

// Component import
import LoadingIndicator from '@components/Loading';

// Asset impoort
import { ReactComponent as Logo } from '@assets/soma.svg';

// Style import
import { Container } from './styles';

const Loading: React.FC = () => {
  // Get theme context
  const themeContext = useContext(ThemeContext);
  return (
    <Container>
      <div className="logo">
        <Logo fill={themeContext.button_low} />
      </div>
      <LoadingIndicator color={themeContext.button_low} />
    </Container>
  );
};

export default Loading;
