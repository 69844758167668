import { Reducer } from 'redux';
import { produce } from 'immer';

// Type import
import { AuthActionTypes, IAuthState } from './types';

const INITIAL_STATE: IAuthState = {
  updated: new Date(),
  signed: false,
  loading: true,
  persist: false,

  login: undefined,
  token: undefined,

  name: undefined,
  email: undefined,
  avatar_url: undefined,

  store: undefined,
  sale_codes: undefined,
};

const auth: Reducer<IAuthState> = (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case AuthActionTypes.RESUME_SESSION_REQUEST:
      case AuthActionTypes.SESSION_REFRESH_REQUEST:
        draft.updated = new Date();
        draft.loading = true;
        break;

      case AuthActionTypes.SIGN_IN_SUCCESS:
        draft.updated = new Date();
        draft.signed = true;
        draft.loading = false;
        draft.persist = action.payload.persist;

        draft.login = action.payload.data.login;
        draft.token = action.payload.data.token;

        draft.name = action.payload.data.name;
        draft.email = action.payload.data.email;
        draft.avatar_url = action.payload.data.avatar_url;

        draft.store = action.payload.data.store;
        draft.sale_codes = action.payload.data.sale_codes;
        break;

      case AuthActionTypes.SIGN_FAILURE:
      case AuthActionTypes.SIGN_OUT:
        draft.updated = new Date();
        draft.signed = false;
        draft.loading = false;
        draft.persist = false;

        draft.login = undefined;
        draft.token = undefined;

        draft.name = undefined;
        draft.email = undefined;
        draft.avatar_url = undefined;

        draft.store = undefined;
        draft.sale_codes = undefined;
        break;

      default:
    }
  });
};

export default auth;
