import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MdSearch } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';

// Util import
import setActivePage from '@util/setActivePage';

// Style import
import { Container, InfoCard } from './styles';

const Dashboard: React.FC = () => {
  // Get history hook
  const history = useHistory();

  // Get translation function
  const { t } = useTranslation();

  // Initialized
  useEffect(() => {
    // Set page
    setActivePage('@dashboard');
  }, []);

  // Theme context
  const themeContext = useContext(ThemeContext);

  return (
    <Container>
      <section>
        <h2>Pedidos</h2>
        <div>
          <InfoCard
            type="button"
            onClick={() => {
              const params: Record<
                'startDate' | 'endDate' | 'status' | 'package',
                string
              > = {
                startDate: `${new Date().getTime() / 1000}`,
                endDate: `${new Date().getTime() / 1000}`,
                status: 'PENDING',
                package: '',
              };
              history.push({
                pathname: '/orders',
                search: `?${new URLSearchParams(params).toString()}`,
              });
            }}
          >
            <div>
              <div>
                <p>
                  {t(
                    '@dashboard/ORDERS_CLOSE_TO_DEADLINE',
                    'Pedidos próximos ao prazo final de execução de Pagamento',
                  )}
                </p>
              </div>
              <div>
                <MdSearch color={themeContext.font_secondary} />
              </div>
            </div>
          </InfoCard>
          <InfoCard
            type="button"
            onClick={() => {
              const params: Record<
                'startDate' | 'endDate' | 'status' | 'package',
                string
              > = {
                startDate: `${new Date().getTime() / 1000}`,
                endDate: `${new Date().getTime() / 1000}`,
                status: 'CANCELED',
                package: '',
              };
              history.push({
                pathname: '/orders',
                search: `?${new URLSearchParams(params).toString()}`,
              });
            }}
          >
            <div>
              <div>
                <p>
                  {t('@dashboard/CANCELED_ORDERS', 'Pedidos Cancelados')} <br />{' '}
                  <p>{t('@dashboard/TODAY', 'Hoje')}</p>
                </p>
              </div>
              <div>
                <MdSearch color={themeContext.font_secondary} />
              </div>
            </div>
          </InfoCard>
        </div>
        <div>
          <InfoCard
            type="button"
            onClick={() => {
              const params: Record<
                'startDate' | 'endDate' | 'status' | 'package',
                string
              > = {
                startDate: '',
                endDate: '',
                status: 'PENDING',
                package: '',
              };
              history.push({
                pathname: '/orders',
                search: `?${new URLSearchParams(params).toString()}`,
              });
            }}
          >
            <div>
              <div>
                <p>
                  {t(
                    '@dashboard/ORDERS_AWAITING_PAYMENT',
                    'Pedidos aguardando Pagamento',
                  )}
                </p>
              </div>
              <div>
                <MdSearch color={themeContext.font_secondary} />
              </div>
            </div>
          </InfoCard>
          <InfoCard
            type="button"
            onClick={() => {
              const params: Record<
                'startDate' | 'endDate' | 'status' | 'package',
                string
              > = {
                startDate: `${new Date().getTime() / 1000}`,
                endDate: `${new Date().getTime() / 1000}`,
                status: 'COMPLETED',
                package: '',
              };
              history.push({
                pathname: '/orders',
                search: `?${new URLSearchParams(params).toString()}`,
              });
            }}
          >
            <div>
              <div>
                <p>
                  {t('@dashboard/FINISHED_ORDERS', 'Pedidos Finalizados')}{' '}
                  <br /> <p>{t('@dashboard/TODAY', 'Hoje')}</p>
                </p>
              </div>
              <div>
                <MdSearch color={themeContext.font_secondary} />
              </div>
            </div>
          </InfoCard>
        </div>
      </section>
    </Container>
  );
};

export default Dashboard;
