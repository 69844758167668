import { IOrder } from '@hooks/useCurrentOrder/models';
import SomaStoreApiGateway from 'gateways/SomaStoreApiGateway';
import { useEffect, useState, useCallback, useRef } from 'react';

export const useGetOrder = (uuid: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [order, setOrder] = useState<IOrder | undefined>(undefined);
  const isMounted = useRef(true);

  const getOrder = useCallback(async () => {
    setLoading(true);
    try {
      const data = await SomaStoreApiGateway.getById(uuid);
      if (isMounted.current) {
        setOrder(data);
        setLoading(false);
      }
    } catch (err: any) {
      if (isMounted.current) {
        setLoading(false);
        throw err;
      }
    }
  }, [uuid]);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    getOrder();
  }, [getOrder]);

  return { loading, order };
};
