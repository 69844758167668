// Store import
import { store } from '@store/index';

// Action import
import { setActivePage } from '@store/modules/ui/actions';

export default (page: string) => {
  // Dispatch
  store.dispatch(setActivePage(page));
};
