import { AnyAction } from 'redux';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

// API import
import api from '../../../services/api';

// Action import
import { signInSuccess, signOut } from './actions';

// Type import
import { AuthActionTypes } from './types';

export class UserData {
  public static getInstance(): UserData {
    if (!this.instance) {
      this.instance = new UserData();
    }
    return this.instance;
  }

  static instance: UserData;

  private userScopes: any[] = [];

  public setUserScopes(scopes: any[]) {
    this.userScopes = scopes;
  }

  public getUserScopes(): any[] {
    return this.userScopes;
  }
}

export function signInSuccessEffect({ payload }: AnyAction) {
  // Get token from payload
  const { token, scopes } = payload.data;
  const { persist } = payload;

  // Setup token on axios
  api.defaults.headers.Authorization = `Bearer ${token}`;

  UserData.getInstance().setUserScopes(scopes);

  // Set storage
  if (persist)
    localStorage.setItem(process.env.REACT_APP_SESSION_PERSIST_KEY, token);
}

export function signOutEffect() {
  // Clear axios token
  api.defaults.headers.Authorization = undefined;

  // Clear storage
  localStorage.removeItem(process.env.REACT_APP_SESSION_PERSIST_KEY);
}

export function* refreshSessionEffect() {
  // Get token from store
  const { token, persist } = yield select(state => state.auth);

  // Check if token is defined
  if (token) {
    // Setup token on axios
    api.defaults.headers.Authorization = `Bearer ${token}`;

    try {
      // API call
      const response: AxiosResponse = yield call(api.get, 'admin/session', {
        timeout: 15000,
      });

      // Handle data
      yield put(signInSuccess(response.data, !!persist));
    } catch {
      // Logout
      yield put(signOut());
    }
  } else {
    // Logout
    yield put(signOut());
  }
}

export function* resumeSessionRequestEffect({ payload }: AnyAction) {
  if (payload.token) {
    try {
      // API call
      const response: AxiosResponse = yield call(api.get, 'admin/session', {
        headers: {
          Authorization: `Bearer ${payload.token}`,
        },
        timeout: 15000,
      });

      // Setup axios
      api.defaults.headers.Authorization = `Bearer ${response.data.token}`;

      // Handle success
      yield put(signInSuccess(response.data, true));
    } catch {
      // Logout
      yield put(signOut());
    }
    // Logout
  } else yield put(signOut());
}

export default all([
  takeLatest(
    AuthActionTypes.RESUME_SESSION_REQUEST,
    resumeSessionRequestEffect,
  ),
  takeLatest(AuthActionTypes.SIGN_IN_SUCCESS, signInSuccessEffect),
  takeLatest(AuthActionTypes.SESSION_REFRESH_REQUEST, refreshSessionEffect),
  takeLatest(AuthActionTypes.SIGN_OUT, signOutEffect),
]);
