import styled from 'styled-components';
import Button from '@components/Button';

export const Container = styled.div`
  padding: 1.5rem;

  .labels {
    font-size: 14px;
  }

  .field {
    border-radius: 0.625rem;
    width: 100%;
    background-color: #ffffff;
  }

  .box {
    width: 100%;
    justify-content: space-between;
  }

  .MuiOutlinedInput-root {
    font-size: 14px;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: none;
    border-width: 0px;
  }

  .MuiTypography-body1 {
    font-size: 14px;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-gap: 8px;
  justify-items: left;
  align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`;

export const CloseSection = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Title = styled.div`
  width: 100%;
  font-size: 18px;
  margin-bottom: 1.5rem;
`;

export const RadioStyle = styled.div`
  .MuiRadio-colorSecondary.Mui-checked {
    color: ${({ theme }) => theme.background_secondary};
  }

  .MuiRadio-root {
    color: ${({ theme }) => theme.background_secondary};
  }
`;

export const ButtonGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;

  .MuiButton-label {
    font-size: 14px;
  }
`;

export const ExitButton = styled(Button)`
  button.MuiButton-outlined {
    color: ${({ theme }) => theme.font_danger};
    border-color: ${({ theme }) => theme.background_danger};
  }
`;
