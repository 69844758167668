import { IOrder } from '@hooks/useCurrentOrder/models';
import React, { useState } from 'react';
import {
  HorizontalDivider,
  PackageSectionContent,
  Section,
  SectionColumn,
} from './styles';
import OrderPackages from './OrderPackages';
import History from './History';

const PackageHistory: React.FC<{ order?: IOrder }> = ({ order }) => {
  const [selectedPackage, setSelectedPackage] = useState('show-all');
  return (
    <>
      <PackageSectionContent>
        <Section>
          <OrderPackages
            packages={order?.order.packages}
            onSelectedPackageChange={setSelectedPackage}
          />
        </Section>
      </PackageSectionContent>
      <HorizontalDivider />
      <SectionColumn style={{ width: '100%', height: '50rem' }}>
        <Section
          style={{
            width: '85%',
            height: '48rem',
          }}
        >
          <History
            packages={order?.order.packages}
            selectedPackageCode={selectedPackage}
          />
        </Section>
      </SectionColumn>
    </>
  );
};

export default PackageHistory;
