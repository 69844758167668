import React, { useRef } from 'react';
import Modal, { IModalRef } from '@components/Modal';
import { IOrderData } from '@hooks/useCurrentOrder/models';
import { UserData } from '@store/modules/auth/sagas';

import ChangeStatusModal from './ChangeStatusModal';
import AdjustedStatus from './ShowChangeStatus/AdjustedStatus';
import WaitStatus from './ShowChangeStatus/WaitStatus';

const ChangeStatus: React.FC<{
  order: IOrderData;
}> = ({ order }) => {
  const changeStatusModalRef = useRef<IModalRef>(null);

  const userChangeStatus = UserData.getInstance()
    .getUserScopes()
    .some(scope => scope.scope === 'CHANGE_STATUS');

  return (
    <>
      {order.status === 'WAIT_ADJUST' && userChangeStatus && (
        <WaitStatus changeStatusModalRef={changeStatusModalRef} />
      )}

      {order.status === 'ADJUSTED' && <AdjustedStatus order={order} />}

      <Modal ref={changeStatusModalRef} size="small">
        <ChangeStatusModal
          order={order}
          hide={() => {
            changeStatusModalRef.current?.show();
            changeStatusModalRef.current?.hide();
          }}
        />
      </Modal>
    </>
  );
};

export default ChangeStatus;
