import styled from 'styled-components';

export const FormattedUserName = styled.span`
  display: inline-block;
  text-transform: capitalize;
`;

export const Box = styled.div`
  display: flex;

  .alert {
    color: ${() => '#F2994A'};
    font-size: 1rem;
    font-weight: 700;
    margin-right: 1rem;
  }

  .adjustedMessage {
    display: flex;
    align-items: center;
    color: ${() => '#DCC6B7'};
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
  }

  .adjustedInfo {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    color: ${() => '#1BB55C'};
    font-size: 1rem;
    font-weight: 600;
  }

  .alertMessage {
    color: ${() => '#F2994A'};
    font-size: 0.875rem;
    font-weight: 500;
    margin-top: auto;
    align-items: flex-end;
  }

  .drawer-toggle {
    color: ${({ theme }) => theme.background_high};
    display: inline-flex;
    align-items: center;
    margin-top: 0.5rem;
    white-space: nowrap;
    margin-left: 2em;
    font-weight: normal;
  }

  .drawer-content {
    margin-top: 1rem;
  }
`;
