import React, { useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { parseJSON, format } from 'date-fns';
import ptBR from 'date-fns/esm/locale/pt-BR/index.js';

import { MdKeyboardArrowRight } from 'react-icons/md';
import { IconCalendarEvent } from '@tabler/icons-react';

import { IOrder, IOrderPackages } from '@hooks/useCurrentOrder/models';

import PackagePill from '@components/PackagePill';

import { ThemeContext } from 'styled-components';
import { CPFformat } from '@util/makeArrayStatus';

import { Box, TableCell, Typography } from '@mui/material';
import { PaymentMethods } from '../../constants';
import { StatusLabel, TableRowStyled } from './styles';

interface StatusLabelContent {
  color: string;
  text: string;
}

const QUANTITY_OF_PACKAGES = 2;

const TableRowOrder: React.FC<{
  item: IOrder;
  isDetails: boolean;
}> = ({ item, isDetails }) => {
  const { t } = useTranslation();
  const language = useSelector(state => state.ui.language);
  const themeContext = useContext(ThemeContext);
  const [dateLanguagePack, setDateLanguagePack] = useState<Locale>(ptBR);

  useEffect(() => {
    const controller = new AbortController();
    const languageSet = language;
    import(`date-fns/locale/${languageSet}`)
      .then(res => setDateLanguagePack(res.default as Locale))
      .catch(() => {
        setDateLanguagePack(ptBR);
      });
    return () => {
      controller.abort();
    };
  }, [language]);

  const statusLabel: Record<string, StatusLabelContent> = {
    CANCELED: {
      color: themeContext.font_danger,
      text: t('@orders/CANCELED_STATUS', 'Cancelado'),
    },
    COMPLETED: {
      color: themeContext.font_success,
      text: t('@orders/PAID_STATUS', 'Confirmado'),
    },
    PENDING: {
      color: themeContext.button_secondary,
      text: t('@orders/ERROR_STATUS', 'Pendente'),
    },
    WAIT_ADJUST: {
      color: 'rgba(242, 153, 74, 1)',
      text: t('@orders/WAIT_ADJUST', 'A Ajustar'),
    },
    ADJUSTED: {
      color: themeContext.font_success,
      text: t('@orders/ADJUSTED', 'Ajustado'),
    },
  };

  const getStatusLabel = (status: string | undefined) => {
    if (!status) {
      return (
        <StatusLabel color={themeContext.button_warning}>
          {t('@orders/WAITING_STATUS', 'A Processar')}
        </StatusLabel>
      );
    }

    const label = statusLabel[status];

    return (
      <div className="pill">
        <StatusLabel color={label.color}>{label.text}</StatusLabel>
      </div>
    );
  };

  const getProductQuantity = (packages: IOrderPackages[] | undefined) => {
    let productQuantity = 0;
    packages?.forEach(productPackage =>
      productPackage.items.forEach(myitem => {
        productQuantity += myitem.quantity;
      }),
    );

    return `${productQuantity} produto(s)`;
  };

  const sumAmount = (itemOrder: IOrder): string => {
    const totalAmount = itemOrder.order.payments.reduce(
      (acc, val) => acc + val.amount,
      0,
    );

    return (totalAmount / 100).toFixed(2);
  };

  return (
    <TableRowStyled key={item.order.id}>
      <TableCell>
        <Box className="borderContent" sx={{ minWidth: '7.5rem' }}>
          {getStatusLabel(item.order.status)}
        </Box>
      </TableCell>
      <TableCell>
        <Box className="borderContent">
          <Box className="hourSection">
            <Box>
              <IconCalendarEvent color="#DCC6B7" size={20} />
            </Box>
            <Box className="hourSectionInfo">
              <b>
                {format(parseJSON(item.order.created_at), 'dd', {
                  locale: dateLanguagePack,
                })}
              </b>{' '}
              {format(parseJSON(item.order.created_at), 'MMM yyyy', {
                locale: dateLanguagePack,
              })}
              <Typography className="hour">
                {format(parseJSON(item.order.created_at), 'p', {
                  locale: dateLanguagePack,
                })}
                {' min'}
              </Typography>
            </Box>
          </Box>
        </Box>
      </TableCell>
      <TableCell>
        <Box className="borderContent">
          <Box className="hourSection">
            <Box>
              <IconCalendarEvent color="#DCC6B7" size={20} />
            </Box>
            <Box className="hourSectionInfo">
              <b>
                {format(parseJSON(item.order.updated_at), 'dd', {
                  locale: dateLanguagePack,
                })}
              </b>{' '}
              {format(parseJSON(item.order.updated_at), 'MMM yyyy', {
                locale: dateLanguagePack,
              })}
              <Typography className="hour">
                {format(parseJSON(item.order.updated_at), 'p', {
                  locale: dateLanguagePack,
                })}
                {' min'}
              </Typography>
            </Box>
          </Box>
        </Box>
      </TableCell>
      <TableCell className="fitColumn">
        <Box className="borderContent">
          <Box className="fitContent" sx={{ minWidth: '8rem' }}>
            <Box>{item.order.store.name}</Box>
            <Box className="productQuantity">{item.order.store.code}</Box>
          </Box>
        </Box>
      </TableCell>
      <TableCell className="fitColumn">
        <Box className="borderContent">
          <Box className="fitContent" sx={{ minWidth: '8rem' }}>
            <Box className="price">R$ {sumAmount(item)}</Box>
            <Box className="productQuantity">
              {getProductQuantity(item.order.packages)}
            </Box>
          </Box>
        </Box>
      </TableCell>
      <TableCell className="paymentColumn">
        <Box className="borderContent">
          {item.order.payments.map(payment => (
            <Box key={payment.id} className="payment-container fitContent">
              <Box className="payment-type">
                {PaymentMethods[payment.payment_type.code]}
              </Box>
              <Box className="amount-container">
                R$ {(payment.amount / 100).toFixed(2)}
              </Box>
            </Box>
          ))}
        </Box>
      </TableCell>
      <TableCell key={item.order.id} className="fitColumn">
        <Box className="borderContent">
          <Box className="fitContent">
            <Box className="document">
              {CPFformat(item.order.customer_document)}
            </Box>
          </Box>
        </Box>
      </TableCell>
      <TableCell className="packageColumn fitColumn">
        <Box className="borderContent">
          <Box className="fitContent">
            {item.order.packages.map((order_package, index) =>
              index < QUANTITY_OF_PACKAGES ? (
                <Box className="packages" key={order_package.id}>
                  <PackagePill order_package={order_package} isDetails />
                </Box>
              ) : (
                <Box className="additionalPackages" key={order_package.id}>
                  <Typography>
                    +{item.order.packages.length - QUANTITY_OF_PACKAGES}
                  </Typography>
                </Box>
              ),
            )}
          </Box>
        </Box>
      </TableCell>
      {isDetails ? (
        ''
      ) : (
        <TableCell className="fitColumn">
          <NavLink to={`/orders/${item.order.id}/details`}>
            <Box className="detailsButton">
              <MdKeyboardArrowRight color={themeContext.font_secondary} />
            </Box>
          </NavLink>
        </TableCell>
      )}
    </TableRowStyled>
  );
};
export default TableRowOrder;
