import styled from 'styled-components';

export const Container = styled.div`
  padding: 1.5rem;
  height: 100%;
`;

export const LogsPanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const LogsContent = styled.div`
display:flex;
flex-direction: column;
justify:content: space-between;

  .titleRow {
    p {
      font-size: 1.25rem;
      font-weight: 600
      color: ${({ theme }) => theme.font_primary};
    }
  }
`;

export const CategoriesSection = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-direction: column;

  .titleAndActionsRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .title {
      p {
        font-size: 1.5rem;
        font-weight: 600;
        color: ${({ theme }) => theme.font_secondary};
      }
    }
    .actionSection {
      font-weight: 500;

      .actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        .filter {
          border: 0.125rem solid ${({ theme }) => theme.button_primary};
          border-radius: 0.5rem;
          height: 2.125rem;
          width: 10.25rem;
          margin-right: 1rem;

          button {
            height: 100%;
            width: 100%;

            div {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              color: ${({ theme }) => theme.font_secondary};
              width: 100%;
              padding: 0.25rem 0.5rem;

              div {
                font-size: 1.125rem;
              }

              p {
                font-weight: 700;
                font-size: 0.875rem;
              }
            }
          }
        }

        .filterClean {
          border: 0.125rem solid ${({ theme }) => theme.button_primary};
          border-radius: 0.5rem;
          height: 2.125rem;
          width: 10.25rem;
          button {
            height: 100%;
            width: 100%;
            div {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              color: ${({ theme }) => theme.font_secondary};

              div {
                font-size: 1.125rem;
                margin-right: 0.375rem;
              }

              p {
                font-weight: 700;
                font-size: 0.875rem;
              }
            }
          }
        }
      }
      .filterMenu {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        position: absolute;

        .options {
          background-color: ${({ theme }) => theme.background_primary};
          border-radius: 0.25rem;
          box-shadow: 0.625rem 0.313rem 0.313rem
            ${({ theme }) => theme.background_low};
          padding: 0.5rem 0.25rem;

          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 9rem;

          button {
            p {
              padding-left: 0.5rem;
              color: ${({ theme }) => theme.font_secondary};
              font-size: 0.875rem;
              font-weight: 700;
            }
          }

          .divider {
            border-bottom: solid 1px ${({ theme }) => theme.font_primary};
            width: 90%;
            align-self: center;
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
          }
        }
      }

      .space {
        width: 9.875rem;
      }
    }
  }

  .carouselSection {
    margin-top: 0.75rem;
    display: flex;
    flex-direction: row;
    overflow: auto;

    height: 10rem;

    overflow: hidden;
    overflow-x: scroll;

    /* width */
    ::-webkit-scrollbar {
      height: 0.5rem;
    }
    /* Track */
    ::-webkit-scrollbar-track {
      background: none;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.background_secondary};
      border-radius: 0.25rem;
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
`;

export const CategoryCard = styled.div`
  background-color: ${props => props.color};
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  min-width: 17.25rem;
  max-width: 17.25rem;
  height: 8.75rem;
  margin-right: 0.375rem;

  button {
    height: 100%;

    .categoryContent {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .title {
        p {
          overflow-wrap: break-word;
          color: ${({ theme }) => theme.font_high};
          font-weight: 400;
          font-size: 0.75rem;
          text-align: initial;
        }
      }

      .content {
        display: flex;
        flex-direction: column;

        .numbers {
          display: flex;
          flex-direction: row;
          font-size: 1.5rem;

          .total {
            color: ${({ theme }) => theme.font_high};
            font-weight: 400;
          }
        }
        .status {
          display: flex;
          flex-direction: row;
          align-items: center;
          color: ${({ theme }) => theme.font_high};

          p {
            font-weight: 400;
            font-size: 0.75rem;
            margin-right: 0.5rem;
          }

          .errorIcon {
            display: flex;
            color: ${({ theme }) => theme.font_danger};
          }

          .checkIcon {
            display: flex;
            color: ${({ theme }) => theme.font_success};
          }
        }
      }
    }
  }
`;

export const NotCompletedNumber = styled.p`
  color: ${props => props.color};
  font-weight: 700;
`;

export const LogsList = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;

  .titleAndSearchRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .listTitle {
      width: 50%;
      display: flex;
      justify-content: flex-start;

      p {
        font-size: 1.5rem;
        font-weight: 600;
        color: ${({ theme }) => theme.font_secondary};
      }
    }

    .listSearch {
      width: 50%;
      display: flex;
      justify-content: flex-end;
    }
  }
`;

export const SearchBar = styled.div`
  background-color: ${({ theme }) => theme.background_high};
  border: 0.063rem solid ${({ theme }) => theme.font_secondary};
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.font_low};
  margin-left: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  div {
    padding: 0 0.75rem 0 0.75rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.font_low};
    font-size: 1.5rem;
  }

  .inputInput {
    width: 100%;
    color: ${({ theme }) => theme.font_low};
    font-size: 0.875rem;
  }
`;

export const LogsListComponent = styled.div`
  margin-top: 1.5rem;
  width: 100%;

  .statusFilter {
    width: 100%;
    display: grid;
    grid-template-columns: 1.5fr 2px 2.5fr 2px 4fr 2px 3fr 2px 3fr 2px 3.5fr 2px 3.5fr 2px 1fr;
    padding: 0 1rem 0 1rem;
    position: absolute;

    .options {
      background-color: ${({ theme }) => theme.background_primary};
      border-radius: 0.25rem;
      box-shadow: 0.625rem 0.313rem 0.313rem
        ${({ theme }) => theme.background_low};

      .option {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .showAll {
          font-weight: 700;
          font-size: 0.75rem;
          margin-left: 0.25rem;
        }

        .MuiCheckbox-colorPrimary {
          color: ${({ theme }) => theme.button_high};
        }

        .MuiCheckbox-root {
          padding-right: 0.25rem;
        }
      }
    }
  }

  .filterMenu {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: absolute;

    .options {
      background-color: ${({ theme }) => theme.background_primary};
      border-radius: 0.25rem;
      z-index: 1;
      box-shadow: 0.625rem 0.313rem 0.313rem
        ${({ theme }) => theme.background_low};
      padding: 0.5rem 0.25rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 12rem;

      button {
        p {
          padding-left: 0.5rem;
          color: ${({ theme }) => theme.font_secondary};
          font-size: 0.875rem;
          font-weight: 700;
        }
      }

      .divider {
        border-bottom: solid 1px ${({ theme }) => theme.font_primary};
        width: 90%;
        align-self: center;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }
    }

    .space {
      width: 9.875rem;
    }
  }

  .header {
    background-color: ${({ theme }) => theme.background_secondary};
    height: 2.375rem;
    display: grid;
    grid-template-columns: 1.5fr 2px 2.5fr 2px 4fr 2px 3fr 2px 3fr 2px 3.5fr 2px 3.5fr 2px 1fr;

    div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .checkBoxHeader {
        color: ${({ theme }) => theme.font_low};

        .icon {
          svg {
            width: 1.5rem;
            height: 1.5rem;
          }
        }
      }

      .divider {
        border-left: solid 2px ${({ theme }) => theme.font_secondary};
      }

      p {
        color: ${({ theme }) => theme.background_low};
        border: none;
        padding: 0;
        font-weight: 700;
        margin-right: 0.5rem;
      }

      button {
        font-size: 1rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }
  }

  .rowTable:nth-child(odd) {
    background-color: ${({ theme }) => theme.background_primary};
  }
  .rowTable:nth-child(even) {
    background-color: ${({ theme }) => theme.background_low};
  }

  .rowTable {
    margin-top: 0.625rem;
    height: 4.688rem;
    display: grid;
    grid-template-columns: 1.5fr 2px 2.5fr 2px 4fr 2px 3fr 2px 3fr 2px 3.5fr 2px 3.5fr 2px 1fr;

    div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .MuiCheckbox-colorPrimary {
        color: ${({ theme }) => theme.button_primary};
      }

      .MuiCheckbox-colorSecondary {
        color: ${({ theme }) => theme.button_secondary};
      }

      .divider {
        border-left: solid 2px ${({ theme }) => theme.font_secondary};
        height: 80%;
      }

      .category {
        padding: 0 1rem 0 1rem;

        P {
          font-weight: 400;
          font-size: 0.75rem;
          text-align: center;
          word-break: break-all;
        }
      }

      .date {
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        .calendar {
          margin-right: 0.5rem;
        }

        .hourSection {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          .hour {
            font-size: 0.625rem;
            color: ${({ theme }) => theme.font_secondary};
          }
        }
      }

      .userOrBranch {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 1rem 0 1rem;

        .name {
          p {
            font-weight: 500;
            font-size: 0.875rem;
          }
        }

        .code {
          margin-top: 0.125rem;

          p {
            font-weight: 500;
            font-size: 0.875rem;
            color: ${({ theme }) => theme.font_secondary};
          }
        }
      }
    }

    .detailsButton {
      font-size: 2rem;
    }
  }
`;

export const StatusLabel = styled.p`
  color: ${props => props.color};
  border: 0.063rem solid;
  padding: 0.25rem 0.625rem;
  border-radius: 1rem;
  margin: 0.25rem;
  width: fit-content;
`;

export const LogsFooter = styled.div`
  .pageMenu {
    width: 100%;
    height: 5rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    .pageNumber {
      margin: 0 1rem;
      border-radius: 100%;
      background-color: ${({ theme }) => theme.background_secondary};
      width: 1.25rem;
      height: 1.25rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      p {
        font-size: 0.75rem;
        color: ${({ theme }) => theme.font_primary};
      }
    }
  }
`;
