import React, { useRef, useCallback, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles, SubmitHandler } from '@unform/core';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

// Component import
import Button from '@components/Button';
import Input from '@components/Input';
import Switch from '@components/Switch';

// Service import
import api from '@services/api';

// Style import
import { Container } from './styles';

// Interfaces
interface IProps {
  hide: () => any;
}

const BrandCreation: React.FC<IProps> = ({ hide }) => {
  // Local states
  const [brandSwitchEnable, setBrandSwitchEnable] = useState(true);
  const [providerSwitchEnable, setProviderSwitchEnable] = useState(true);
  const [loading, setLoading] = useState(false);

  // Local refs
  const formBrandCreationRef = useRef<FormHandles>(null);

  const brandNameInputRef = useRef<HTMLInputElement>(null);
  const brandCodeInputRef = useRef<HTMLInputElement>(null);
  const imageURLInputRef = useRef<HTMLInputElement>(null);
  const referencePatternInputRef = useRef<HTMLInputElement>(null);
  const colorPatternInputRef = useRef<HTMLInputElement>(null);
  const providerSubdomainInputRef = useRef<HTMLInputElement>(null);
  const providerComercialPoliticsInputRef = useRef<HTMLInputElement>(null);
  const providerPaymentIdInputRef = useRef<HTMLInputElement>(null);
  const providerSalespersonPatternInputRef = useRef<HTMLInputElement>(null);
  const providerKeyInputRef = useRef<HTMLInputElement>(null);
  const providerTokenInputRef = useRef<HTMLInputElement>(null);

  // Get translation function
  const { t } = useTranslation();

  // Change store settings
  const brandCreationSubmit: SubmitHandler<{
    brandName: string;
    brandCode: number;
    imageURL: string;
    referencePattern: string;
    colorPattern: string;
    providerSubdomain: string;
    providerComercialPolitics: number;
    providerPaymentId: number;
    providerSalespersonPattern: string;
    providerKey: string;
    providerToken: string;
  }> = useCallback(
    async data => {
      const {
        brandName,
        brandCode,
        imageURL,
        referencePattern,
        colorPattern,
        providerSubdomain,
        providerComercialPolitics,
        providerPaymentId,
        providerSalespersonPattern,
        providerKey,
        providerToken,
      } = data;

      // Setup a schema to be valitated
      const schema = Yup.object()
        .shape({
          brandName: Yup.string()
            .strict()
            .typeError(
              t(
                '@providers/INVALID_NAME_TYPE',
                'O tipo do nome é inválido, deveria ser alfanumérico.',
              ),
            )
            .required(t('@providers/REQUIRED_NAME', 'O nome é obrigatório.')),
          brandCode: Yup.number()
            .typeError(
              t(
                '@providers/INVALID_CODE_TYPE',
                'O tipo do código identificador externo é inválido, deveria ser numérico.',
              ),
            )
            .required(
              t(
                '@providers/REQUIRED_CODE',
                'O código identificador externo é obrigatório.',
              ),
            ),
          imageURL: Yup.string()
            .strict()
            .typeError(
              t(
                '@providers/INVALID_IMAGE_URL_TYPE',
                'O tipo do logotipo é inválido, deveria ser alfanumérico.',
              ),
            )
            .url(
              t('@providers/INVALID_IMAGE_URL', 'A url da imagem é inválida.'),
            )
            .required(
              t(
                '@providers/REQUIRED_IMAGE_URL',
                'A url do logotipo da marca é obrigatória.',
              ),
            ),
          referencePattern: Yup.string()
            .strict()
            .typeError(
              t(
                '@providers/INVALID_PRODUCT_CODE_PATTERN_TYPE',
                'O tipo da máscara do código do produto é inválido, deveria ser alfanumérico.',
              ),
            )
            .required(
              t(
                '@providers/REQUIRED_PRODUCT_CODE_PATTERN',
                'A máscara do código dos produtos desta marca é obrigatório.',
              ),
            ),

          colorPattern: Yup.string()
            .strict()
            .typeError(
              t(
                '@providers/INVALID_PRODUCT_COLOR_PATTERN_TYPE',
                'O tipo da máscara da cor do produto é inválido, deveria ser alfanumérico.',
              ),
            )
            .required(
              t(
                '@providers/REQUIRED_PRODUCT_COLOR_PATTERN',
                'A máscara da cor dos produtos desta marca é obrigatório.',
              ),
            ),

          providerSubdomain: Yup.string()
            .strict()
            .typeError(
              t(
                '@brand_provider_controller_post/INVALID_BASE_SUBDOMAIN_TYPE',
                'O tipo do subdomínio base deste provedor de marca é inválido, deveria ser alfanumérico.',
              ),
            )
            .required(
              t(
                '@brand_provider_controller_post/REQUIRED_BASE_SUBDOMAIN',
                'O subdomínio base deste provedor de marca é obrigatório.',
              ),
            ),
          providerComercialPolitics: Yup.number()
            .typeError(
              t(
                '@brand_provider_controller_post/INVALID_SALE_CHANNEL_CODE_TYPE',
                'O tipo do código do canal de venda da API deste provedor de marca é inválido, deveria ser numérico.',
              ),
            )
            .required(
              t(
                '@brand_provider_controller_post/REQUIRED_SALE_CHANNEL_CODE',
                'O código do canal de venda da API deste provedor de marca é obrigatório.',
              ),
            ),

          providerPaymentId: Yup.number()
            .typeError(
              t(
                '@brand_provider_controller_post/INVALID_PAYMENT_SYSTEM_CODE_TYPE',
                'O tipo do código do canal de pagamento da API deste provedor de marca é inválido, deveria ser numérico.',
              ),
            )
            .required(
              t(
                '@brand_provider_controller_post/REQUIRED_PAYMENT_SYSTEM_CODE',
                'O código do canal de pagamento da API deste provedor de marca é obrigatório.',
              ),
            ),

          providerSalespersonPattern: Yup.string()
            .strict()
            .typeError(
              t(
                '@brand_provider_controller_post/INVALID_SALECODE_PATTERN_TYPE',
                'O tipo da máscara de código de vendedor deste provedor de marca é inválido, deveria ser alfanumérico.',
              ),
            )
            .required(
              t(
                '@brand_provider_controller_post/REQUIRED_SALECODE_PATTERN',
                'A máscara de código de vendedor deste provedor de marca é obrigatória.',
              ),
            ),

          providerKey: Yup.string()
            .strict()
            .typeError(
              t(
                '@brand_provider_controller_post/INVALID_KEY_TYPE',
                'O tipo da chave de API deste provedor de marca é inválido, deveria ser alfanumérico.',
              ),
            )
            .required(
              t(
                '@brand_provider_controller_post/REQUIRED_KEY',
                'A chave de API deste provedor de marca é obrigatória.',
              ),
            ),

          providerToken: Yup.string()
            .strict()
            .typeError(
              t(
                '@brand_provider_controller_post/INVALID_TOKEN_TYPE',
                'O tipo do token de API deste provedor de marca é inválido, deveria ser alfanumérico.',
              ),
            )
            .required(
              t(
                '@brand_provider_controller_post/REQUIRED_TOKEN',
                'O token de API deste provedor de marca é obrigatório.',
              ),
            ),
        })
        .noUnknown(true, t('@stores/INVALID_REQUEST', 'Requisição inválida.'));

      try {
        await schema.validate(data, {
          abortEarly: false,
          stripUnknown: false,
        });
      } catch (err: any) {
        if (err instanceof Yup.ValidationError) {
          const validationErrors: { [key: string]: string } = {};
          const errors: string[] = [];
          err.inner.forEach(error => {
            errors.push(error.message);
            if (error.path) validationErrors[error.path] = error.message;
          });
          if (formBrandCreationRef.current)
            formBrandCreationRef.current.setErrors(validationErrors);
          toast.error(errors[0]);
        }

        return;
      }

      // Loading set
      setLoading(true);
      try {
        const response = await api.post(`admin/brand/`, {
          code: Number(brandCode),
          name: brandName,
          enabled: brandSwitchEnable,
          image_url: imageURL,
          payload: {
            product_code_pattern: referencePattern,
            product_color_pattern: colorPattern,
          },
        });

        try {
          await api.post(`admin/brand/provider/`, {
            brand_id: response.data.id,
            name: 'Vtex',
            code: 'vtex',
            enabled: providerSwitchEnable,
            payload: {
              key: providerKey,
              token: providerToken,
              base_subdomain: providerSubdomain,
              salecode_pattern: providerSalespersonPattern,
              sale_channel_code: Number(providerComercialPolitics),
              payment_system_code: Number(providerPaymentId),
            },
          });
        } catch (err: any) {
          // Check if server had return a error
          if (err.response?.data?.message)
            toast.error(err.response.data.message);
          else {
            toast.error(
              t(
                '@general/CONNECTION_FAILURE_ERROR',
                'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
              ),
            );
          }
        }

        hide();
        setLoading(false);
      } catch (err: any) {
        // Check if server had return a error
        if (err.response?.data?.message) toast.error(err.response.data.message);
        else {
          toast.error(
            t(
              '@general/CONNECTION_FAILURE_ERROR',
              'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
            ),
          );
        }
        setLoading(false);
      }
    },
    [t, brandSwitchEnable, hide, providerSwitchEnable],
  );

  return (
    <Container>
      <Form ref={formBrandCreationRef} onSubmit={brandCreationSubmit}>
        <div className="title">
          <h2>{t('@providers/BRAND_TITLE', 'Marca')}</h2>
          <Switch
            checked={brandSwitchEnable}
            onChange={() => setBrandSwitchEnable(!brandSwitchEnable)}
          />
        </div>
        <div className="formBody">
          <div className="row">
            <div className="brandNameInput">
              <Input ref={brandNameInputRef} name="brandName" label="Nome" />
            </div>
            <div className="brandCodeInput">
              <Input
                ref={brandCodeInputRef}
                name="brandCode"
                label="Código"
                type="tel"
                inputmode="numeric"
                pattern="[0-9]*"
              />
            </div>
          </div>
          <Input ref={imageURLInputRef} name="imageURL" label="URL da Imagem" />
          <div className="row">
            <div className="referencePatternInput">
              <Input
                ref={referencePatternInputRef}
                name="referencePattern"
                label="Padrão de Referência"
              />
            </div>
            <div className="colorPatternInput">
              <Input
                ref={colorPatternInputRef}
                name="colorPattern"
                label="Padrão de Cor"
              />
            </div>
          </div>
          <h3>{t('@providers/PROVIDERS_TITLE', 'Provedores:')}</h3>
          <div className="providerForm">
            <div className="providerTitle">
              <h4>{t('@providers/VTEX', 'Vtex')}</h4>
              <Switch
                checked={providerSwitchEnable}
                onChange={() => setProviderSwitchEnable(!providerSwitchEnable)}
              />
            </div>
            <Input
              ref={providerSubdomainInputRef}
              name="providerSubdomain"
              label="Subdomínio"
            />
            <div className="providerRow">
              <div className="providerComercialPoliticsInput">
                <Input
                  ref={providerComercialPoliticsInputRef}
                  name="providerComercialPolitics"
                  label="Política Comercial"
                />
              </div>

              <div className="providerPaymentIdInput">
                <Input
                  ref={providerPaymentIdInputRef}
                  name="providerPaymentId"
                  label="Id do Pagamento"
                />
              </div>
            </div>
            <Input
              ref={providerSalespersonPatternInputRef}
              name="providerSalespersonPattern"
              label="Padrão do Vendedor"
            />
            <Input ref={providerKeyInputRef} name="providerKey" label="Key" />
            <Input
              ref={providerTokenInputRef}
              name="providerToken"
              label="Token"
            />
          </div>
        </div>
        <div className="saveButton">
          <Button
            disabled={loading}
            onClick={() => {
              formBrandCreationRef.current?.submitForm();
            }}
          >
            {t('@providers/SAVE_EDITION_BUTTON', 'Salvar')}
          </Button>
        </div>
        <div>
          <Button variant="outlined" onClick={() => hide()}>
            {t('@providers/CANCEL_EDITION_BUTTON', 'Cancelar')}
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default BrandCreation;
