import React from 'react';
import Pagination from '@material-ui/lab/Pagination';
import { Grid } from '@material-ui/core';
import { IMetadata } from 'interfaces/IPaginatedResponse';
import { PaginationContainer } from './styles';

const PaginationMenu: React.FC<{
  metadata: IMetadata;
  pageNumber: number;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
}> = ({ metadata, pageNumber, setPageNumber }) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="flex-end"
    >
      <PaginationContainer>
        <Pagination
          count={metadata.total_pages}
          page={pageNumber}
          size="small"
          siblingCount={1}
          onChange={(_, pageNumberSelected) => {
            setPageNumber(pageNumberSelected);
          }}
        />
      </PaginationContainer>
    </Grid>
  );
};

export default PaginationMenu;
