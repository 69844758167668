import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';
import ptBR from 'date-fns/esm/locale/pt-BR/index.js';

import { format, getDate, parseJSON } from 'date-fns';
import { IOrderPackagesItems } from '@hooks/useCurrentOrder/models';
import { useSelector } from 'react-redux';
import Loading from '@components/Loading';
import {
  IntegrationStepsContent,
  IntegrationStepsDates,
  IntegrationStepsTopics,
  ListDateStyle,
  ListTopicStyle,
} from './styles';

const PackageIntegrationSteps: React.FC<{
  created_at?: Date | string;
  completed_at?: Date | string;
  invoiced_at?: Date | string;
  canceled_at?: Date | string;
  package_items?: IOrderPackagesItems[];
}> = ({
  created_at,
  completed_at,
  invoiced_at,
  canceled_at,
  package_items,
}) => {
  const language = useSelector(state => state.ui.language);

  const [dateLanguagePack, setDateLanguagePack] = useState<Locale>(ptBR);

  useEffect(() => {
    const languageSet = language;
    import(`date-fns/locale/${languageSet}`)
      .then(res => setDateLanguagePack(res.default as Locale))
      .catch(() => {
        setDateLanguagePack(ptBR);
      });
  }, [language]);

  const themeContext = useContext(ThemeContext);

  const mountDateAndTime = (date: Date | string | undefined) => {
    if (!date) {
      return <p> - </p>;
    }
    return (
      <p>
        {getDate(parseJSON(date))}{' '}
        {format(parseJSON(date), 'MMM', {
          locale: dateLanguagePack,
        })}{' '}
        {format(parseJSON(date), 'yyyy', {
          locale: dateLanguagePack,
        })}{' '}
        às{' '}
        {format(parseJSON(date), 'p', {
          locale: dateLanguagePack,
        })}
        {' min'}
      </p>
    );
  };

  const mountIntegrationPackageList = () => {
    if (!created_at) {
      return <Loading />;
    }

    // Package is pending
    if (!canceled_at && !completed_at) {
      return (
        <IntegrationStepsContent>
          <IntegrationStepsTopics>
            <ListTopicStyle>Pacote criado em</ListTopicStyle>
          </IntegrationStepsTopics>
          <IntegrationStepsDates>
            <ListDateStyle>{mountDateAndTime(created_at)}</ListDateStyle>
          </IntegrationStepsDates>
        </IntegrationStepsContent>
      );
    }

    // Canceled Package before it was Approved
    if (canceled_at && !completed_at) {
      return (
        <IntegrationStepsContent>
          <IntegrationStepsTopics>
            <ListTopicStyle>Pacote criado em</ListTopicStyle>
            <ListTopicStyle color={themeContext.button_danger}>
              Pacote cancelado em
            </ListTopicStyle>
          </IntegrationStepsTopics>
          <IntegrationStepsDates>
            <ListDateStyle>{mountDateAndTime(created_at)}</ListDateStyle>
            <ListDateStyle color={themeContext.button_danger}>
              {mountDateAndTime(canceled_at)}
            </ListDateStyle>
          </IntegrationStepsDates>
        </IntegrationStepsContent>
      );
    }

    // Canceled Package aftter it was Approved
    if (canceled_at && completed_at) {
      return (
        <IntegrationStepsContent>
          <IntegrationStepsTopics>
            <ListTopicStyle>Pacote criado em</ListTopicStyle>
            <ListTopicStyle color={themeContext.button_success}>
              Pacote aprovado em
            </ListTopicStyle>
            <ListTopicStyle color={themeContext.button_danger}>
              Pacote cancelado em
            </ListTopicStyle>
          </IntegrationStepsTopics>
          <IntegrationStepsDates>
            <ListDateStyle>{mountDateAndTime(created_at)}</ListDateStyle>
            <ListDateStyle color={themeContext.button_success}>
              {mountDateAndTime(completed_at)}
            </ListDateStyle>
            <ListDateStyle color={themeContext.button_danger}>
              {mountDateAndTime(canceled_at)}
            </ListDateStyle>
          </IntegrationStepsDates>
        </IntegrationStepsContent>
      );
    }

    // Partial Canceled Package
    const canceled_items = package_items?.filter(
      items => items.removed_at !== null,
    );
    if (canceled_items?.length !== 0) {
      return (
        <IntegrationStepsContent>
          <IntegrationStepsTopics>
            <ListTopicStyle>Pacote criado em</ListTopicStyle>
            <ListTopicStyle color={themeContext.button_success}>
              Pacote aprovado em
            </ListTopicStyle>
            {canceled_items?.map(() => (
              <ListTopicStyle color={themeContext.button_danger}>
                Item removido em
              </ListTopicStyle>
            ))}
            <ListTopicStyle>Pacote faturado em</ListTopicStyle>
          </IntegrationStepsTopics>
          <IntegrationStepsDates>
            <ListDateStyle>{mountDateAndTime(created_at)}</ListDateStyle>
            <ListDateStyle color={themeContext.button_success}>
              {mountDateAndTime(completed_at)}
            </ListDateStyle>
            {canceled_items?.map(items => (
              <ListDateStyle color={themeContext.button_danger}>
                {mountDateAndTime(items.removed_at)}
              </ListDateStyle>
            ))}
            <ListDateStyle>{mountDateAndTime(invoiced_at)}</ListDateStyle>
          </IntegrationStepsDates>
        </IntegrationStepsContent>
      );
    }

    return (
      <IntegrationStepsContent>
        <IntegrationStepsTopics>
          <ListTopicStyle>Pacote criado em</ListTopicStyle>
          <ListTopicStyle color={themeContext.button_success}>
            Pacote aprovado em
          </ListTopicStyle>
          <ListTopicStyle>Pacote faturado em</ListTopicStyle>
        </IntegrationStepsTopics>
        <IntegrationStepsDates>
          <ListDateStyle>{mountDateAndTime(created_at)}</ListDateStyle>
          <ListDateStyle color={themeContext.button_success}>
            {mountDateAndTime(completed_at)}
          </ListDateStyle>
          <ListDateStyle>{mountDateAndTime(invoiced_at)}</ListDateStyle>
        </IntegrationStepsDates>
      </IntegrationStepsContent>
    );
  };

  return <>{mountIntegrationPackageList()}</>;
};

export default PackageIntegrationSteps;
