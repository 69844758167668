import { IOrderPackages } from '@hooks/useCurrentOrder/models';
import React from 'react';
import { Scroll, Section, SectionContent } from './styles';
import PackageDetails from './PackageDetails';

const History: React.FC<{
  packages?: IOrderPackages[];
  selectedPackageCode: string;
}> = ({ packages, selectedPackageCode }) => {
  return (
    <Section>
      <Scroll>
        <SectionContent>
          {selectedPackageCode === 'show-all' ? (
            packages?.map(orderPackage => (
              <PackageDetails
                key={orderPackage.code}
                currentPackage={orderPackage}
              />
            ))
          ) : (
            <PackageDetails
              currentPackage={packages?.find(
                orderPackage => orderPackage.code === selectedPackageCode,
              )}
            />
          )}
        </SectionContent>
      </Scroll>
    </Section>
  );
};

export default History;
