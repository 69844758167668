import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;

  .statusContainer {
    display: flex;
    gap: 0.25rem;
    align-items: flex-start;

    b {
      color: white;
      font-size: 1.125rem;
    }

    .infoContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 1.5rem;
      height: 1.5rem;

      button.infoIcon {
        display: flex;
        font-size: 1.5rem;
        color: ${({ theme }) => theme.font_secondary};
        border: none;
      }

      .infoDiv {
        position: relative;
        z-index: 10;
        top: 0.5rem;
        opacity: 0;
        transition: 500ms;
        display: flex;
        flex-direction: column;
        align-items: center;

        &.open {
          opacity: 1;
        }

        .arrowUp {
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;

          border-bottom: 5px solid ${({ theme }) => theme.font_secondary};
        }

        .containerBody {
          width: 14rem;
          padding: 1rem;
          background-color: ${({ theme }) => theme.font_secondary};
          color: ${({ theme }) => theme.font_low};
          font-weight: 400;
          font-size: 0.875rem;
          border-radius: 0.5rem;
        }
      }
    }
  }
`;
