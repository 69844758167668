import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 1.5rem;

  h2 {
    margin-bottom: 1rem;
  }

  div {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    gap: 1rem;
  }
`;

export const InfoCard = styled.button`
  width: 100%;
  background-color: ${({ theme }) => theme.background_primary};
  border-radius: 0.25rem;
  padding: 1.5rem;
  align-items: center;
  cursor: pointer;

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0;

    div:first-child {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      p {
        font-size: 1.25rem;
        color: ${({ theme }) => theme.font_primary};
        text-align: left;

        p {
          font-size: 0.875rem;
        }
      }
    }
    div:last-child {
      font-size: 2rem;
    }
  }
`;

export const OrderQuantity = styled.div`
  font-weight: 700;
  color: ${props => props.color};
  font-size: 2rem;
`;

export const StatusCard = styled.button`
  width: 100%;
  background-color: ${({ theme }) => theme.background_high};
  border-radius: 0.25rem;
  padding: 1.5rem;
  align-items: center;
  cursor: pointer;

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0;

    div:first-child {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      p {
        font-size: 1.25rem;
        text-align: left;

        p {
          font-size: 0.875rem;
        }
      }
    }
    div:last-child {
      font-size: 2rem;
      transform: rotateX(180deg);
    }
  }
`;

export const StatusProviderText = styled.p`
  font-size: 1.25rem;
  color: ${props => `${props.color}`};

  p {
    color: ${({ theme }) => theme.font_low};
  }
`;
