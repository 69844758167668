/**
 * DEV DOCS
 *
 * This component create a header for the application, which reacts to redux and
 * dispatch actions to be reacted by both sidebars.
 */

/* eslint-disable jsx-a11y/anchor-is-valid */
import { ThemeContext } from 'styled-components';
import React, { useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Tooltip from 'react-tooltip';
import { MdExitToApp } from 'react-icons/md';

// Action import
import { setLeftMenu } from '@store/modules/ui/actions';
import { signOut } from '@store/modules/auth/actions';

// Asset impoort
import { ReactComponent as Logo } from '@assets/soma.svg';

// Style import
import { Container } from './styles';

const Header: React.FC = () => {
  // Set dispatch
  const dispatch = useDispatch();

  // Get theme context
  const themeContext = useContext(ThemeContext);

  // Tooltip re-render fix
  useEffect(() => {
    Tooltip.hide();
    Tooltip.rebuild();
  });

  // Global states
  const left_menu = useSelector(state => state.ui.left_menu);

  // Left menu toggle
  const handleLeftMenuToggle = () => {
    // Toggle state
    dispatch(setLeftMenu(!left_menu));

    // Get viewport
    const vw = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0,
    );

    // Handle window overflow
    const element = document.querySelector('html');
    if (element) {
      if (vw <= 768) {
        if (!left_menu) element.style.overflow = 'hidden';
        else element.style.overflow = 'auto';
      } else element.style.overflow = 'auto';
    }
  };

  useEffect(() => {
    // Clear menu open when reside
    const handleResize = () => {
      // Get viewport
      const vw = Math.max(
        document.documentElement.clientWidth,
        window.innerWidth || 0,
      );

      // On mobile, default state is closed menu, but in desktop, defaults to opened
      if (vw <= 768) dispatch(setLeftMenu(false));
      else dispatch(setLeftMenu(true));

      // Handle window overflow
      const element = document.querySelector('html');
      if (element) {
        if (vw <= 768) {
          if (!left_menu) element.style.overflow = 'hidden';
          else element.style.overflow = 'auto';
        } else element.style.overflow = 'auto';
      }
    };

    // Resize event
    window.addEventListener('resize', handleResize);

    return () => {
      // Clear resize function
      window.removeEventListener('resize', handleResize);
    };
  });

  return (
    <Container>
      <section className="left">
        <button
          type="button"
          id="menu-toggle"
          onClick={handleLeftMenuToggle}
          className={left_menu ? 'toggle open' : 'toggle'}
        >
          <span />
          <span />
          <span />
          <span />
        </button>
      </section>
      <section className="center">
        <Logo stroke={themeContext.font_low} fill={themeContext.font_low} />
      </section>
      <section className="right">
        <button type="button" onClick={() => dispatch(signOut())}>
          <MdExitToApp color={themeContext.font_low} size={33} />
        </button>
      </section>
    </Container>
  );
};

export default Header;
