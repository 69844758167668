import React, { useCallback, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';
import { IOrderData } from '@hooks/useCurrentOrder/models';
import { TextField, Typography } from '@material-ui/core';

import Button from '@components/Button';
import SomaStoreApiGateway from 'gateways/SomaStoreApiGateway';
import { Container, ButtonGrid, CloseSection, ExitButton } from './styles';

interface IProps {
  order: IOrderData;
  hide: () => any;
}

const ChangeStatusModal: React.FC<IProps> = ({ order, hide }) => {
  const [messageValue, setMessageValue] = useState<string>('');
  const [isApiCalling, setIsApiCalling] = useState(false);

  const changeStatusConfirmed = useCallback(async () => {
    try {
      await SomaStoreApiGateway.changeStatus(order.id, messageValue);
      handleRefresh();
    } finally {
      setIsApiCalling(false);
    }
  }, [messageValue, order.id]);

  const handleMessageValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessageValue(event.target.value);
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleConfirm = async () => {
    if (!messageValue) {
      toast.error('É necessário escrever uma justificativa');
      return;
    }

    setIsApiCalling(true);
    toast.info('Aguarde enquanto estamos executando a alteração', {
      autoClose: false,
    });

    try {
      await changeStatusConfirmed();
      toast.success('Alteração concluída com sucesso!', { autoClose: 5000 });
      hide();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error:', error);
    } finally {
      setIsApiCalling(false);
    }
  };

  return (
    <Container>
      <CloseSection style={{ marginBottom: '2rem' }}>
        <button type="button" onClick={hide}>
          <MdClose size={25} style={{ color: '#DCC6B7' }} />
        </button>
      </CloseSection>
      <Typography
        align="center"
        style={{
          color: '#DCC6B7',
          fontSize: '18px',
          marginBottom: '2rem',
        }}
      >
        Para alterar o status do pedido para Ajustado, é necessário incluir uma
        justificativa:
      </Typography>
      <TextField
        required
        id="required"
        variant="outlined"
        placeholder="Escreva aqui"
        value={messageValue}
        onChange={handleMessageValue}
        multiline
        minRows={4}
        maxRows={10}
        style={{
          width: '100%',
          marginBottom: '2rem',
          background: '#FFFFFF',
          color: '#9E9E9E',
          borderRadius: '0.625rem',
        }}
      />
      <Typography
        align="center"
        style={{
          fontSize: '16px',
          color: '#DCC6B7',
          marginBottom: '2rem',
        }}
      >
        Esta informação ficará armazenada em nosso banco de dados.
      </Typography>
      <ButtonGrid>
        <ExitButton variant="outlined" onClick={hide}>
          CANCELAR
        </ExitButton>
        <Button
          onClick={handleConfirm}
          disabled={!messageValue || isApiCalling}
        >
          ALTERAR
        </Button>
      </ButtonGrid>
    </Container>
  );
};

export default ChangeStatusModal;
