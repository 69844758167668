import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

// Action import
import { resumeSessionRequest, signOut } from '@store/modules/auth/actions';

// Layout import
import Layout from '../layouts/default';

// Page import
import Loading from '../pages/Loading';

// Theme import
import lightTheme from '../styles/themes/light';

// Interfaces
interface IChildren extends RouteProps {
  component: any;
  isPrivate?: boolean;
}

const RouteWrapper = ({
  component: Component,
  isPrivate,
  ...rest
}: IChildren) => {
  // Get dispatch hook
  const dispatch = useDispatch();

  // Initial login
  useEffect(() => {
    // Try to get token
    const token = localStorage.getItem(
      process.env.REACT_APP_SESSION_PERSIST_KEY,
    );

    // Session
    if (token) dispatch(resumeSessionRequest(token));
    else dispatch(signOut());
  }, [dispatch]);

  // Global states
  const signed = useSelector(state => state.auth.signed);
  const authLoading = useSelector(state => state.auth.loading);

  // Loading screen
  if (authLoading || signed === undefined) {
    return <Loading />;
  }

  // Private pages with unauthorized user
  if (!signed && isPrivate) {
    return <Redirect to="/" />;
  }

  // Public page with authorized user
  if (signed && !isPrivate) {
    return <Redirect to="/dashboard" />;
  }

  // Private route
  if (signed && isPrivate) {
    return (
      <Route
        {...rest}
        render={routeProps => (
          <ThemeProvider theme={lightTheme}>
            <Layout>
              <Component {...routeProps} />
            </Layout>
          </ThemeProvider>
        )}
      />
    );
  }

  // Public route
  // !signed && !isPrivate
  return (
    <ThemeProvider theme={lightTheme}>
      <Route component={Component} {...isPrivate} {...rest} />
    </ThemeProvider>
  );
};

RouteWrapper.defaultProps = {
  isPrivate: false,
};

export default RouteWrapper;
