import { TableRow } from '@mui/material';
import styled from 'styled-components';

export const TableRowStyled = styled(TableRow)`
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  &.MuiTableRow-root:nth-child(even) {
    background-color: ${({ theme }) => theme.background_primary};
  }

  &.MuiTableRow-root:nth-child(odd) {
    background-color: ${({ theme }) => theme.background_low};
  }

  .MuiTableCell-root {
    padding: 0.5rem 0.7rem;
    justify-content: left;
    margin-top: 50px;
    border-bottom: none;
    font-size: 0.875rem;
    color: unset;

    .borderContent,
    .borderContentDiffer {
      border-right: ${({ theme }) => theme.background_secondary} solid 1px;
      height: 3rem;

      display: flex;
      flex-direction: column;
      justify-content: center;

      .fitContent {
        padding-right: 1rem;
      }
    }

    .payment-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .amount-container {
      color: ${({ theme }) => theme.font_secondary};
    }

    .hourSection {
      display: flex;
      flex-direction: row;
    }

    .hourSectionInfo {
      margin-left: 0.5rem;
    }

    .pill {
      padding: 0 0.5rem 0 0;
    }

    .hour {
      font-size: 0.625rem;
      color: ${({ theme }) => theme.font_secondary};
    }

    .numberCode {
      color: ${({ theme }) => theme.font_secondary};
    }

    .price {
      color: ${({ theme }) => theme.font_primary};
      font-weight: 700;
    }

    .productQuantity {
      color: ${({ theme }) => theme.font_secondary};
      font-size: 0.75rem;
    }

    .packages,
    .document {
      display: flex;
      align-items: center;
    }

    .detailsButton {
      display: flex;
      height: 100%;
      align-items: center;
      font-size: 2rem;
    }
  }

  .packageColumn {
    position: relative;
    height: 5rem;
  }

  .additionalPackages {
    position: absolute;
    right: 8%;
    bottom: 35%;
  }

  .paymentColumn {
    width: 10.5rem;
  }

  .fitColumn {
    width: 0;
    .fitContent {
      width: max-content;
    }
  }
`;

export const StatusLabel = styled.p`
  color: ${props => props.color};
  border: 0.15rem solid;
  padding: 0.25rem 0.625rem;
  border-radius: 1rem;
  margin: 0.25rem;
  width: fit-content;
`;
