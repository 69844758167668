import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5rem;
  align-items: center;
  width: 100%;

  .headerPosition {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .packageNameCodeStyle {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const SectionTextSecondary = styled.p`
  font-weight: 700;
  font-size: 1rem;
  color: ${({ theme }) => theme.background_high};
`;

export const SectionColumn = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  min-height: -webkit-fill-available;

  .listPackageItems {
    width: 37rem;
  }
`;

export const SubSectionColumn = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;

  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding-bottom: 0.6rem;

  .integrationStepsColumn {
    width: 35rem;
  }
`;

export const VerticalDivider = styled.div`
  margin-right: 2rem;
  height: 100%;
  border: solid 0.1rem ${({ theme }) => theme.background_secondary};
`;
