import styled from 'styled-components';

export const Container = styled.div`
  padding: 1.5rem;
  height: 100%;
`;

export const LogsPanel = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .detailsTitleRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .backIcon {
      color: ${({ theme }) => theme.button_high};
      font-size: 1.5rem;
    }

    p {
      margin-left: 0.75rem;
      font-weight: 600;
      font-size: 1.25rem;
    }
  }
`;

export const LogsListComponent = styled.div`
  margin-top: 1.5rem;
  width: 100%;

  .filterMenu {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: absolute;

    .options {
      background-color: ${({ theme }) => theme.background_primary};
      border-radius: 0.25rem;
      z-index: 1;
      box-shadow: 0.625rem 0.313rem 0.313rem
        ${({ theme }) => theme.background_low};
      padding: 0.5rem 0.25rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 12rem;

      button {
        p {
          padding-left: 0.5rem;
          color: ${({ theme }) => theme.font_secondary};
          font-size: 0.875rem;
          font-weight: 700;
        }
      }

      .divider {
        border-bottom: solid 1px ${({ theme }) => theme.font_primary};
        width: 90%;
        align-self: center;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }
    }

    .space {
      width: 9.875rem;
    }
  }

  .header {
    background-color: ${({ theme }) => theme.background_secondary};
    height: 2.375rem;
    display: grid;
    grid-template-columns: 1.5fr 2px 2.5fr 2px 4fr 2px 3fr 2px 3fr 2px 3.5fr 2px 3.5fr 2px 1fr;

    div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .checkBoxHeader {
        color: ${({ theme }) => theme.font_low};

        .icon {
          svg {
            width: 1.5rem;
            height: 1.5rem;
          }
        }
      }

      .divider {
        border-left: solid 2px ${({ theme }) => theme.font_secondary};
      }

      p {
        color: ${({ theme }) => theme.background_low};
        border: none;
        padding: 0;
        font-weight: 700;
        margin-right: 0.5rem;
      }

      button {
        font-size: 1rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }
  }

  .rowTable:nth-child(odd) {
    background-color: ${({ theme }) => theme.background_primary};
  }
  .rowTable:nth-child(even) {
    background-color: ${({ theme }) => theme.background_low};
  }

  .rowTable {
    margin-top: 0.625rem;
    height: 4.688rem;
    display: grid;
    grid-template-columns: 1.5fr 2px 2.5fr 2px 4fr 2px 3fr 2px 3fr 2px 3.5fr 2px 3.5fr 2px 1fr;

    div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .MuiCheckbox-colorPrimary {
        color: ${({ theme }) => theme.button_primary};
      }

      .MuiCheckbox-colorSecondary {
        color: ${({ theme }) => theme.button_secondary};
      }

      svg {
        width: 1.5rem;
        height: 1.5rem;
      }

      .divider {
        border-left: solid 2px ${({ theme }) => theme.font_secondary};
        height: 80%;
      }

      .category {
        padding: 0 1rem 0 1rem;
        P {
          font-weight: 400;
          font-size: 0.75rem;
          text-align: center;
          word-break: break-all;
        }
      }

      .date {
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        .calendar {
          margin-right: 0.5rem;
        }

        .hourSection {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          .hour {
            font-size: 0.625rem;
            color: ${({ theme }) => theme.font_secondary};
          }
        }
      }

      .userOrBranch {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 1rem 0 1rem;
        .name {
          p {
            font-weight: 500;
            font-size: 0.875rem;
          }
        }
        .code {
          margin-top: 0.125rem;
          p {
            font-weight: 500;
            font-size: 0.875rem;
            color: ${({ theme }) => theme.font_secondary};
          }
        }
      }
    }

    .detailsButton {
      font-size: 2rem;
    }
  }
`;

export const LogDetails = styled.div`
  width: 100%;
  height: 100%;
  padding: 2.5rem;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.background_primary};
  display: flex;
  flex-direction: column;
  margin-top: 0.75rem;

  .detailsTitle {
    margin-bottom: 1.25rem;

    p {
      color: ${({ theme }) => theme.font_secondary};
      font-weight: 700;
      font-size: 1.125rem;
    }
  }

  .infoSection {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;

    .generalInfoSection {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-right: 2.5rem;
      width: 50%;

      .generalInfo {
        display: flex;
        flex-direction: column;

        .generalInfoTitle {
          p {
            font-weight: 700;
            font-size: 0.875rem;
          }
        }

        .horizontalDivider {
          margin: 0.625rem 0 0.625rem;
          border-bottom: solid 0.063rem ${({ theme }) => theme.font_secondary};
        }

        .generalInfoContent {
          p {
            margin-bottom: 0.625rem;
            font-weight: 500;
            font-size: 0.875rem;

            span {
              font-weight: 400;
              font-style: italic;
              color: {({ theme }) => theme.font_secondary};
            }
          }

          .errorMessage {
            height: 8rem;
            overflow: hidden;
            overflow-y: scroll;

            /* width */
            ::-webkit-scrollbar {
              width: 0.25rem;
            }
            /* Track */
            ::-webkit-scrollbar-track {
              background: none;
            }
            /* Handle */
            ::-webkit-scrollbar-thumb {
              background: ${({ theme }) => theme.background_secondary};
              border-radius: 0.25rem;
            }
            /* Handle on hover */
            ::-webkit-scrollbar-thumb:hover {
              background: #555;
            }

            p {
              font-weight: 400;
            }
          }
        }
      }
    }

    .divider {
      border-right: solid 0.125rem ${({ theme }) => theme.font_secondary};
      height: 100%;
    }

    .jsonInfoSection {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 50%;
      padding-left: 2.5rem;

      .jsonInfo {
        display: flex;
        flex-direction: column;

        .jsonInfoTitle {
          p {
            font-weight: 700;
            font-size: 0.875rem;
          }
        }

        .horizontalDivider {
          margin: 0.625rem 0 0.625rem;
          border-bottom: solid 0.063rem ${({ theme }) => theme.font_secondary};
        }

        .jsonInfoContent {
          p {
            /* margin-top: 0.625rem; */
            font-weight: 500;
            font-size: 0.875rem;
          }

          .json {
            height: 11rem;
            overflow: hidden;
            overflow-y: scroll;
            overflow-x: scroll;
            background-color: ${({ theme }) => theme.background_low};
            margin-bottom: 0.625rem;

            /* width */
            ::-webkit-scrollbar {
              width: 0.25rem;
              height: 0.25rem;
            }
            /* Track */
            ::-webkit-scrollbar-track {
              background: none;
            }
            /* Handle */
            ::-webkit-scrollbar-thumb {
              background: ${({ theme }) => theme.background_secondary};
              border-radius: 0.25rem;
            }
            /* Handle on hover */
            ::-webkit-scrollbar-thumb:hover {
              background: #555;
            }

            p {
              font-weight: 400;
            }
          }
        }
      }
    }
  }
`;
