import React, { createContext, useContext, useState } from 'react';

// Model import
import { IError, ICurrentErrorContextData } from './models';

// Context
export const CurrentErrorContext = createContext<ICurrentErrorContextData>(
  {} as ICurrentErrorContextData,
);

// Provider
export const CurrentErrorProvider: React.FC = ({ children }) => {
  // Local states
  const [current_error, set_current_error] = useState<IError>();

  return (
    <CurrentErrorContext.Provider
      value={{
        current_error,
        set_current_error,
      }}
    >
      {children}
    </CurrentErrorContext.Provider>
  );
};

// Hook
export function useCurrentError(): ICurrentErrorContextData {
  const context = useContext(CurrentErrorContext);
  return context;
}
