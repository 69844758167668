// Action types
export const AuthActionTypes = {
  RESUME_SESSION_REQUEST: '@auth/RESUME_SESSION_REQUEST',
  SESSION_REFRESH_REQUEST: '@auth/SESSION_REFRESH_REQUEST',
  SIGN_IN_SUCCESS: '@auth/SIGN_IN_SUCCESS',
  SIGN_OUT: '@auth/SIGN_OUT',
  SIGN_FAILURE: '@auth/SIGN_FAILURE',
};

export interface IOrderBrandProviderOrderPackageDeliveryItem {
  id: string;
  order_brand_provider_order_package_id: string;
  code: string;
  quantity: number;
  ean: string;
  reference: string;
  color: string;
  size: string;
  name: string;
  image_url?: string;
  shipping_selected_id?: string;
  shipping_selected_name?: string;
  shipping_original_price?: number;
  shipping_current_price?: number;
  shipping_business_days_to_delivery?: number;
  unit_original_price: number;
  unit_current_price: number;
  total_original_price: number;
  total_current_price: number;
  payload: any;
  created_at: Date;
  updated_at: Date;
}

export interface IOrderBrandProviderOrderPackage {
  id: string;
  order_brand_provider_order_id: string;
  items: IOrderBrandProviderOrderPackageDeliveryItem[];
  code: string;
  total_original_price: number;
  total_current_price: number;
  shipping_original_price: number;
  shipping_current_price: number;
  total: number;
  payload: any;
  canceled_at?: Date;
  completed_at?: Date;
  created_at: Date;
  updated_at: Date;
}

export interface IOrderBrandProviderOrder {
  id: string;
  order_id: string;
  brand_id: string;
  brand: IBrand;
  packages: IOrderBrandProviderOrderPackage[];
  code: string;
  sale_user_result: string;
  total_original_price: number;
  total_current_price: number;
  shipping_original_price: number;
  shipping_current_price: number;
  total: number;
  payload: any;
  created_at: Date;
  updated_at: Date;
}

export interface IOrderBrandProviderOwnStockItem {
  id: string;
  order_id: string;
  brand_id: string;
  brand: IBrand;
  code: string;
  quantity: number;
  ean: string;
  reference: string;
  color: string;
  size: string;
  name: string;
  image_url?: string;
  unit_original_price: number;
  unit_current_price: number;
  total_original_price: number;
  total_current_price: number;
  payload: any;
  canceled_at?: Date;
  completed_at?: Date;
  created_at: Date;
  updated_at: Date;
}

export interface IOrderPaymentCharge {
  id: string;
  order_payment_id: string;
  code: string;
  amount: number;
  paid_at: Date;
  payload: any;
  canceled_at?: Date;
  created_at: Date;
  updated_at: Date;
}

export interface IOrderPayment {
  id: string;
  order_id: string;
  payment_type_id: string;
  payment_type: {
    id: string;
    code: string;
    name: string;
    created_at: Date;
    updated_at: Date;
  };
  charges: IOrderPaymentCharge[];
  amount: number;
  code: string;
  transaction_id: string;
  payload: any;
  try_until: Date;
  canceled_at?: Date;
  completed_at?: Date;
  created_at: Date;
  updated_at: Date;
}

export interface IOrder {
  order: {
    id: string;
    coupon?: string;
    store_id: string;
    store: IStore;
    payments: IOrderPayment[];
    suborders: IOrderBrandProviderOrder[];
    own_stock_items: IOrderBrandProviderOwnStockItem[];
    sale_user_name: string;
    sale_user_code: string;
    customer_document: string;
    customer_name: string;
    customer_surname: string;
    customer_email: string;
    customer_phone: string;
    shipping_country: string;
    shipping_state: string;
    shipping_city: string;
    shipping_street: string;
    shipping_receiver: string;
    shipping_neighborhood: string;
    shipping_zipcode: string;
    shipping_number?: string;
    shipping_complement?: string;
    total_original_price: number;
    total_current_price: number;
    shipping_original_price: number;
    shipping_current_price: number;
    total: number;
    created_at: Date;
    updated_at: Date;
  };
  status: {
    is_paid: boolean;
    is_canceled: boolean;
    paid_amount: number;
    status: string;
  };
}

export interface IPaymentType {
  id: string;
  code: string;
  name: string;
}

export interface IPackageType {
  id: string;
  code: string;
  name: string;
}

export interface IBrand {
  id: string;
  code: string;
  brand: string;
  name: string;
  payload: any;
  image_url?: string;
}

export interface IStore {
  id: string;
  code: string;
  name: string;
  codename: string;
  zipcode: string;
  image_url?: string;
  brands: IBrand[];
  package_types: IPackageType[];
  payment_types: IPaymentType[];
}

export interface IUserSaleCodes {
  code: string;
  name?: string;
}

export interface ISessionData {
  login?: string;
  token?: string;
  persist?: boolean;

  name?: string;
  email?: string;
  avatar_url?: string;

  store?: IStore;
  sale_codes?: IUserSaleCodes[];
}

export interface IAuthState extends ISessionData {
  updated: Date;
  signed: boolean;
  loading: boolean;
}
