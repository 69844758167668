import styled from 'styled-components';

export const RadioStyle = styled.div`
  .MuiRadio-colorSecondary.Mui-checked {
    color: ${({ theme }) => theme.background_secondary};
  }

  .MuiRadio-root {
    color: ${({ theme }) => theme.background_secondary};
  }

  .MuiFormGroup-root {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .MuiFormControlLabel-root {
    margin-right: 60px;
    margin-top: 20px;
    transform: translateY(-25%);
  }
`;
