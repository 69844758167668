import React, { useCallback } from 'react';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { t } from '@i18n/index';
import { IOrderPackages } from '@hooks/useCurrentOrder/models';
import PackageRadio from './PackageRadio';
import {
  PackageSection,
  PackageSectionContent,
  RadioStyle,
  Row,
  SectionText,
  SectionTitle,
} from './styles';

const OrderPackages: React.FC<{
  packages?: IOrderPackages[];
  onSelectedPackageChange: any;
}> = ({ packages, onSelectedPackageChange }) => {
  const handleRadioChange = useCallback(
    event => {
      onSelectedPackageChange(event.target.value);
    },
    [onSelectedPackageChange],
  );

  return (
    <PackageSection>
      <div className="listOrderPackages">
        <Row>
          <SectionTitle>
            {t('@order-detail-packages/PACKAGES_OPTIONS', 'Pacotes do Pedido:')}
          </SectionTitle>
          <PackageSectionContent
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {packages?.map(orderPackage => (
              <PackageRadio
                key={orderPackage.code}
                orderPackage={orderPackage}
                onSelectedPackageChange={handleRadioChange}
              />
            ))}
            <FormControl>
              <RadioStyle>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="show-all"
                  name="radio-buttons-group"
                  value="show-all"
                  onChange={handleRadioChange}
                >
                  <FormControlLabel
                    value="show-all"
                    control={<Radio />}
                    label={<SectionText>Mostrar Todos</SectionText>}
                  />
                </RadioGroup>
              </RadioStyle>
            </FormControl>
          </PackageSectionContent>
        </Row>
      </div>
    </PackageSection>
  );
};

export default OrderPackages;
