export const StatusArrayObject = [
  'COMPLETED',
  'CANCELED',
  'WAIT_ADJUST',
  'PENDING',
  'ADJUSTED',
];

export const PaymentMethods: { [key: string]: string } = {
  payment_link: 'Link',
  store_credit: 'Saldo',
  physical_card: 'POS',
  pix: 'PIX',
};
