import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 19rem;

  .closeSection {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    button {
      color: ${({ theme }) => theme.button_high};
    }
  }

  .iconSection {
    svg {
      width: 2rem;
      height: 2rem;
    }
  }

  .message {
    p {
      font-weight: 600;
      font-size: 1.125rem;
      color: ${({ theme }) => theme.font_high};
      text-align: center;
    }
  }

  .buttonsSection {
    display: flex;
    flex-direction: column;
    width: 100%;

    .confirmationButton {
      margin-bottom: 0.938rem;
    }

    .buttonText {
      color: ${({ theme }) => theme.font_high};
    }
  }
`;
