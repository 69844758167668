import React, { useState, useContext } from 'react';
import {
  IOrderPackageItemChange,
  IOrderPackagesItems,
} from '@hooks/useCurrentOrder/models';
import { t } from '@i18n/index';
import { ThemeContext } from 'styled-components';
import { Button, Drawer } from '@material-ui/core';
import { MdClose, MdKeyboardArrowRight, MdOpenInNew } from 'react-icons/md';
import {
  Pill,
  ProductItemDesktop,
  DrawerSection,
  DrawerTitle,
  CloseButton,
  DrawerDivider,
  DrawerTopicStyle,
  YamiLink,
  Link,
} from './styles';

const OrderItemCard: React.FC<{ item: IOrderPackagesItems; brand: string }> = ({
  item,
  brand,
}) => {
  const themeContext = useContext(ThemeContext);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const formatDate = (date: string | Date) => {
    const drawerdate = new Date(date);

    const day = String(drawerdate.getUTCDate()).padStart(2, '0');
    const month = String(drawerdate.getUTCMonth() + 1).padStart(2, '0');
    const year = drawerdate.getUTCFullYear();

    const hours = String(drawerdate.getUTCHours()).padStart(2, '0');
    const minutes = String(drawerdate.getUTCMinutes()).padStart(2, '0');

    const FormatedDate = `${day}/${month}/${year} ${hours}:${minutes}`;

    return FormatedDate;
  };

  const drawerItemStatus = (changes: IOrderPackageItemChange[]): any => {
    return changes.map(type => {
      if (type.type === 'estorno') {
        return (
          <>
            <p>• Item devolvido (tipo estorno)</p>
            {`${formatDate(type.completed_date)}`}
          </>
        );
      }
      if (type.type === 'trocapp') {
        return (
          <>
            <p>• Item devolvido (tipo troca produto por produto)</p>
            {`${formatDate(type.completed_date)}`}
          </>
        );
      }
      if (type.type === 'removed') {
        return (
          <>
            <p>• Item removido por pendência</p>
            {`${formatDate(type.completed_date)}`}
          </>
        );
      }
      if (type.type === 'troca') {
        return (
          <>
            <p>• Item devolvido (tipo troca)</p>
            {`${formatDate(type.completed_date)}`}
          </>
        );
      }
      return <></>;
    });
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const productItemContent = (
    <>
      {!!item.image_url && (
        <div className="image">
          <img src={item.image_url} alt={item.name} />
        </div>
      )}
      <div className="info">
        <div className="name">
          <p>{item.name}</p>
        </div>
        <div className="infoRow">
          <p>
            {t('BRAND', 'Marca:')} {brand}
          </p>
          <p>
            {t('REFERENCE', 'Ref.:')} {item.reference}
          </p>
        </div>
        <div className="colorAndSizeRow">
          <p>
            {t('COLOR', 'Cor:')} {item.color}
          </p>
          <p>
            {t('SIZE', 'Tamanho:')} {item.size}
          </p>
        </div>

        <div className="price">
          {item.total_original_price !== item.total_current_price && (
            <span className="original_price">
              R$ {(item.total_original_price / 100).toFixed(2)}
            </span>
          )}
          <span className="current_price">
            R$ {(item.total_current_price / 100).toFixed(2)}
          </span>
        </div>
        <div className="infoRow">
          <p>
            {item.current_quantity} {t('QUANTITY', 'unidade(s)')}
          </p>
        </div>
        <div className="infoRow2">
          {item.removed_at !== null ? (
            <Pill color={themeContext.button_danger} className="pill">
              Removido
            </Pill>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
  const yamiUrl = () => {
    return item.changes.map(change => {
      if (change.origin === 'YAMI' && change.type !== 'removed') {
        const yamiLink = `https://gruposoma.ymi.io/returnmanager/consult#detail/${change.code}`;

        return (
          <YamiLink key={change.code}>
            <Link href={yamiLink} target="_blank">
              {' '}
              Ver protocolo na Yamí{' '}
            </Link>
            <MdOpenInNew
              style={{
                width: '1.25rem',
                height: '1.25rem',
                marginLeft: '0.5rem',
              }}
            />
          </YamiLink>
        );
      }
      return null;
    });
  };

  return (
    <>
      <ProductItemDesktop key={item.code}>
        {productItemContent}
        {item.changes.length ? (
          <Button onClick={handleDrawerOpen} className="detailsButton">
            <MdKeyboardArrowRight
              color={themeContext.font_secondary}
              size="25px"
            />
          </Button>
        ) : null}
      </ProductItemDesktop>

      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
        <DrawerSection>
          <CloseButton onClick={handleDrawerClose}>
            <MdClose />
          </CloseButton>
          <DrawerTitle>
            <p>Etapas da integração de item</p>
          </DrawerTitle>
          <ProductItemDesktop key={item.code}>
            {productItemContent}
          </ProductItemDesktop>
          <DrawerDivider />
          <DrawerTopicStyle>{drawerItemStatus(item.changes)}</DrawerTopicStyle>
          <YamiLink>{item.changes.length > 0 && yamiUrl()}</YamiLink>
        </DrawerSection>
      </Drawer>
    </>
  );
};

export default OrderItemCard;
