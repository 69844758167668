import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5rem;
  align-items: center;
  width: 100%;

  .headerPosition {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .packageNameCodeStyle {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const SectionTitle = styled.h2`
  display: flex;
  font-weight: 700;
  font-size: 1.125rem;
  color: ${({ theme }) => theme.background_secondary};
`;

export const SectionTitleSecondary = styled.h2`
  display: flex;
  font-weight: 700;
  font-size: 1.125rem;
  color: ${({ theme }) => theme.background_high};

  .value {
    margin-left: 2rem;
  }
`;

export const SectionText = styled.p`
  font-weight: 700;
  color: ${({ theme }) => theme.background_secondary};

  .couponLabel {
    margin-left: 2rem !important;
  }
`;

export const CurrentShipping = styled.h2`
  display: flex;
  font-weight: 700;
  font-size: 0.9rem;
  margin-left: 3.5rem;
  align-self: bottom;
  color: ${({ theme }) => theme.background_secondary};
`;

export const ShippingValue = styled.h2`
  display: flex;
  font-weight: 700;
  font-size: 0.9rem;
  margin-left: 0.5rem;
  align-self: bottom;
  color: ${({ theme }) => theme.background_high};
`;
