import React, { useCallback, useState, useEffect } from 'react';
import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';

import { IOrderPaymentsCharges } from '@hooks/useCurrentOrder/models';
import api from '@services/api';
import Button from '@components/Button';

import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@material-ui/core';

import {
  Container,
  Title,
  ButtonGrid,
  CloseSection,
  RadioStyle,
  ExitButton,
  Grid,
} from './styles';

interface IProps {
  charge: IOrderPaymentsCharges;
  hide: () => any;
}
const CancelChargeModal = ({ charge, hide }: IProps) => {
  const [selectedOption, setSelectedOption] = useState('CancelamentoTotal');
  const [inputValue, setInputValue] = useState(charge.amount);
  const [reason, setReason] = useState('Pendência (ruptura de estoque)');
  const [isApiCalling, setIsApiCalling] = useState(false);

  const cancelChargeConfirmed = useCallback(async () => {
    try {
      setIsApiCalling(true);

      await api.post(`admin/order/charge/${charge.id}/refund`, {
        amount: inputValue,
        description: reason,
      });

      handleRefresh();
    } finally {
      setIsApiCalling(false);
    }
  }, [charge.id, reason, inputValue]);

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    setReason(e.target.value as string);
  };

  const filteredReasons = [
    'Pendência (ruptura de estoque)',
    'Cancelamento de pedido solicitado pelo cliente',
    'Devolução de cliente',
    'Devolução/extravio de transportadora',
    'Cancelamento do Vale/Saldo de Crédito',
    'Falha na utilização de promoção',
    'Estorno de frete - atraso na entrega',
    'Outros',
  ].filter(option => option !== reason);

  const handleConfirm = async () => {
    try {
      if (inputValue > 0 && inputValue <= charge.amount) {
        toast.info('Aguarde enquanto estamos executando o seu estorno', {
          autoClose: false,
        });
        await cancelChargeConfirmed();
        toast.success('Estorno concluído com sucesso!', { autoClose: 5000 });
        hide();
      } else if (inputValue === 0) {
        toast.error(
          `Favor digitar um número acima de 0 e menor que o valor total R$${charge.amount}`,
        );
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    if (inputValue === charge.amount) {
      setSelectedOption('CancelamentoTotal');
    }
  }, [inputValue, charge.amount]);

  const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(e.target.value);

    if (e.target.value === 'CancelamentoParcial') {
      setInputValue(0);
    }

    if (e.target.value === 'CancelamentoTotal') {
      setInputValue(charge.amount);
    }
  };

  const inputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      const input = e.target.value.replace(/[^0-9]/g, '');
      setInputValue(
        Number(input) <= charge.amount ? Number(input) : inputValue,
      );
    }
  };

  return (
    <Container>
      <CloseSection>
        <button type="button" onClick={hide}>
          <MdClose size={25} style={{ color: '#DCC6B7' }} />
        </button>
      </CloseSection>
      <Title>Selecione como gostaria de cancelar a cobrança: </Title>
      <RadioStyle>
        <RadioGroup
          className="box"
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          value={selectedOption}
          onChange={handleOptionChange}
          name="row-radio-buttons-group"
        >
          <FormControlLabel
            control={<Radio />}
            value="CancelamentoTotal"
            label="Cancelamento Total"
          />
          <FormControlLabel
            control={<Radio />}
            value="CancelamentoParcial"
            label="Cancelamento Parcial"
          />
        </RadioGroup>
      </RadioStyle>
      <Grid>
        <p className="labels">Valor:</p>
        <TextField
          variant="outlined"
          size="small"
          disabled={selectedOption === 'CancelamentoTotal'}
          value={`R$${(inputValue / 100).toFixed(2)}`}
          onChange={inputHandler}
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          className="field"
        />
        <p className="labels">Motivo:</p>
        <FormControl variant="outlined" size="small" className="field">
          <Select native value={reason} onChange={handleChange}>
            <option value={reason} key={reason}>
              {reason}
            </option>
            {filteredReasons.map(option => (
              <option value={option} key={option}>
                {option}
              </option>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <ButtonGrid>
        <ExitButton variant="outlined" onClick={hide}>
          SAIR
        </ExitButton>
        <Button onClick={handleConfirm} disabled={isApiCalling}>
          CONFIRMAR
        </Button>
      </ButtonGrid>
    </Container>
  );
};

export default CancelChargeModal;
