import React, { useEffect, useState, useCallback, useRef } from 'react';
import Loading from '@components/Loading';
import ISortStatus from 'interfaces/ISortStatus';
import IPillsOptions from 'interfaces/IPillsOptions';
import SomaStoreApiGateway from 'gateways/SomaStoreApiGateway';
import TableRowOrder from '@components/TableRowOrder';
import FilterOrderTable from '@components/TableFilterOrder';
import TableHeaderOrder from '@components/TableHeaderOrder';
import PaginationMenu from '@components/PaginationMenu';
import SelectStatusComponent from '@components/SelectStatusComponent';
import setActivePage from '@util/setActivePage';
import { Table, TableBody } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import { CurrentOrderProvider } from '@hooks/useCurrentOrder';
import { IOrder } from '@hooks/useCurrentOrder/models';
import { DateObject } from 'react-multi-date-picker';
import { IPaginatedResponse } from 'interfaces/IPaginatedResponse';
import { StatusArrayObject } from '../../constants';
import { Container, StoresTable, StoresPanel } from './styles';
import 'react-multi-date-picker/styles/backgrounds/bg-dark.css';
import './color.css';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function getDefaultPage(query: URLSearchParams) {
  const pageQuery = query.get('page');
  let pageDefault = 1;
  if (pageQuery !== '' && Number(pageQuery) >= 1) {
    pageDefault = Number(pageQuery);
  }
  return pageDefault;
}

const PAGE_SIZE = 6;

const Content: React.FC = () => {
  const history = useHistory();
  const query = useQuery();

  const timeoutRefPillOptions = useRef<number>(0);
  const timeoutRefStatusArray = useRef<number>(0);

  const [urlParams, setUrlParams] = useState({
    startDateFilter: query.get('startDate'),
    endDateFilter: query.get('endDate'),
    packageFilter: query.get('package') ?? '',
    customerDocumentFilter: query.get('customer_document') ?? '',
    customerDocument: query.get('customer_document') ?? '',
    statusSplitArray: query.get('status')?.split(',') ?? [],
  });
  const [orders, setOrders] = useState<IPaginatedResponse<IOrder> | undefined>(
    undefined,
  );
  const [sortStatus, setSortStatus] = useState<ISortStatus>({
    created_at: '-1',
    store: '-1',
    customerDocument: '-1',
    price: '-1',
  });
  const [pageNumber, setPageNumber] = useState<number>(getDefaultPage(query));
  const [pillsOptions, setPillsOptions] = useState<IPillsOptions>({
    status: false,
  });
  const [loading, setLoading] = useState(false);
  const [statusFilter, setStatusFilter] = useState<string[]>(
    urlParams.statusSplitArray?.filter((element: string) =>
      StatusArrayObject.includes(element),
    ) ?? [],
  );
  const [statusFilterSelected, setStatusFilterSelected] =
    useState<string[]>(statusFilter);

  let dateStart: DateObject[] = [];
  if (urlParams.startDateFilter && urlParams.endDateFilter) {
    dateStart = [
      new DateObject(new Date(Number(urlParams.startDateFilter) * 1000)),
      new DateObject(new Date(Number(urlParams.endDateFilter) * 1000)),
    ];
  }

  const fetchOrders = useCallback(async () => {
    setLoading(true);
    try {
      const existDateToFilter =
        urlParams.startDateFilter?.trim() !== '' &&
        urlParams.endDateFilter?.trim() !== '';
      const startDate = String(urlParams.startDateFilter);
      const endDate = String(urlParams.endDateFilter);

      const filter = {
        page: pageNumber,
        sort_created_at: sortStatus.created_at,
        sort_updated_at: sortStatus.updated_at,
        sort_customer_document: sortStatus.customerDocument,
        sort_total: sortStatus.price,
        filter_start_date: existDateToFilter ? startDate : undefined,
        filter_end_date: existDateToFilter ? endDate : undefined,
        filter_package_code: urlParams.packageFilter ?? null,
        filter_customer_document: urlParams.customerDocumentFilter ?? null,
        pageSize: PAGE_SIZE,
        filter_order_status: statusFilterSelected.length
          ? statusFilterSelected.join(',')
          : undefined,
      };

      const data = await SomaStoreApiGateway.getAllOrders(filter);

      setOrders(data);
      setPageNumber(data.metadata.page);
      setLoading(false);
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.error(err);
      setLoading(false);
      throw err;
    }
  }, [
    urlParams.startDateFilter,
    urlParams.endDateFilter,
    pageNumber,
    sortStatus.created_at,
    sortStatus.updated_at,
    sortStatus.customerDocument,
    sortStatus.price,
    urlParams.packageFilter,
    urlParams.customerDocumentFilter,
    statusFilterSelected,
  ]);

  useEffect(() => {
    if (loading) return;
    const params: Record<
      | 'startDate'
      | 'endDate'
      | 'status'
      | 'package'
      | 'customer_document'
      | 'page',
      string
    > = {
      startDate: urlParams.startDateFilter ?? '',
      endDate: urlParams.endDateFilter ?? '',
      status: statusFilter.join(',') ?? '',
      package: urlParams.packageFilter ?? '',
      customer_document: urlParams.customerDocumentFilter ?? '',
      page: `${pageNumber}`,
    };
    history.push({
      pathname: '/orders',
      search: `?${new URLSearchParams(params).toString()}`,
    });
  }, [
    urlParams.startDateFilter,
    urlParams.endDateFilter,
    statusFilter,
    urlParams.packageFilter,
    urlParams.customerDocumentFilter,
    pageNumber,
    loading,
    history,
  ]);

  useEffect(() => {
    window.clearTimeout(timeoutRefPillOptions.current);
    timeoutRefPillOptions.current = window.setTimeout(() => {
      if (!pillsOptions.status) return;
      setPillsOptions({
        ...pillsOptions,
        status: false,
      });
    }, 3000);
  }, [pillsOptions, setPillsOptions, statusFilter]);

  useEffect(() => {
    window.clearTimeout(timeoutRefStatusArray.current);
    timeoutRefStatusArray.current = window.setTimeout(() => {
      setStatusFilterSelected(statusFilter);
    }, 3000);
  }, [setStatusFilterSelected, statusFilter]);

  useEffect(() => {
    setActivePage('@orders');
  }, []);

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  return (
    <Container>
      <StoresPanel>
        <FilterOrderTable dateStart={dateStart} setUrlParams={setUrlParams} />
        <StoresTable>
          <Table>
            <TableHeaderOrder
              setPillsOptions={setPillsOptions}
              isDetails={false}
              pillsOptions={pillsOptions}
              statusPillChecked={statusFilter}
              sortStatus={sortStatus}
              setSortStatus={setSortStatus}
            />
            <SelectStatusComponent
              hidden={!pillsOptions.status}
              statusPillChecked={statusFilter}
              setStatusFilter={setStatusFilter}
              statusFilter={statusFilter}
            />
            {loading ? (
              <div className="loading">
                <Loading size={3} />
              </div>
            ) : (
              <TableBody>
                {orders?.data.map(item => (
                  <TableRowOrder
                    key={item.order.id}
                    item={item}
                    isDetails={false}
                  />
                ))}
              </TableBody>
            )}
          </Table>
        </StoresTable>
        {orders ? (
          <PaginationMenu
            metadata={orders.metadata}
            pageNumber={orders.metadata.page}
            setPageNumber={setPageNumber}
          />
        ) : (
          <></>
        )}
      </StoresPanel>
    </Container>
  );
};

const Orders: React.FC = () => {
  return (
    <CurrentOrderProvider>
      <Content />
    </CurrentOrderProvider>
  );
};

export default Orders;
