import { combineReducers } from 'redux';

// Reducer import
import auth from './auth/reducer';
import ui from './ui/reducer';
import stores from './stores/reducer';

const rootReducer = combineReducers({
  auth,
  ui,
  stores,
});

export default rootReducer;
