import React, { useState } from 'react';
import { useParams } from 'react-router';

import 'react-multi-date-picker/styles/backgrounds/bg-dark.css';

import Loading from '@components/Loading';
import { PageHeader } from '@components/OrderPageHeader';
import { OrderButtonNav } from '@components/OrderButtonNav';
import { CurrentOrderProvider } from '@hooks/useCurrentOrder';
import { IOrder } from '@hooks/useCurrentOrder/models';
import OrderDetailPackages from '@components/OrderDetailPackages';
import OrderDetailsPayment from '@components/OrderDetailPayment';
import OrderDetailIdentify from '@components/OrderDetailIdentify';
import TableRowOrder from '@components/TableRowOrder';
import TableHeaderOrder from '@components/TableHeaderOrder';

import { Table, TableBody } from '@mui/material';
import { useGetOrder } from '@hooks/useGetOrder';
import { Container, StoresPanel, OrdersHeader, DetailsPanel } from './styles';

export interface IModalProps {
  order: IOrder;
  tabSelected?: Function;
}

const tabComponents: { [key: string]: React.FC<IModalProps> } = {
  payments: OrderDetailsPayment,
  identity: OrderDetailIdentify,
  packages: OrderDetailPackages,
};

const OrderDetail: React.FC = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const [checkPage, setCheckPage] = useState<string>('payments');
  const { loading, order } = useGetOrder(uuid);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const emptyPillEfect = () => {};

  const onTabSelected = (page: string) => setCheckPage(page);

  return (
    <CurrentOrderProvider>
      {loading ? (
        <div className="loading">
          <Loading size={3} />
        </div>
      ) : (
        <Container>
          <StoresPanel>
            <PageHeader />
            {!order ? (
              <div className="loading">
                <Loading size={3} />
              </div>
            ) : (
              <OrdersHeader>
                <Table>
                  <TableHeaderOrder
                    setPillsOptions={emptyPillEfect}
                    isDetails
                  />
                  <TableBody>
                    <TableRowOrder item={order} isDetails />
                  </TableBody>
                </Table>
                <DetailsPanel checkPage={checkPage}>
                  <div className="buttonsNav">
                    <OrderButtonNav onTabSelected={onTabSelected} />
                    {order &&
                      React.createElement(tabComponents[checkPage], { order })}
                  </div>
                </DetailsPanel>
              </OrdersHeader>
            )}
          </StoresPanel>
        </Container>
      )}
    </CurrentOrderProvider>
  );
};

export default OrderDetail;
