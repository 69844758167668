import React from 'react';
import { useTranslation } from 'react-i18next';
import { CurrentOrderProvider } from '@hooks/useCurrentOrder';
import { IOrder } from '@hooks/useCurrentOrder/models';
import 'react-multi-date-picker/styles/backgrounds/bg-dark.css';
import { CPFformat } from '@util/makeArrayStatus';
import { DetailsPanel } from './styles';

interface IModalProps {
  order: IOrder;
}

const Content: React.FC<IModalProps> = ({ order }) => {
  const { t } = useTranslation();

  const telephoneFormat = (phoneNumber: string | undefined) => {
    const ddd = phoneNumber?.slice(0, 2);
    const firstPart = phoneNumber?.slice(2, 5);
    const secondPart = phoneNumber?.slice(5, 8);
    const thirdPart = phoneNumber?.slice(8, 11);

    return `(${ddd}) ${firstPart} ${secondPart} ${thirdPart}`;
  };
  const cepFormat = (cep: string | undefined) => {
    return `${cep?.slice(0, 5)}-${cep?.slice(5, 8)}`;
  };

  return (
    <>
      <DetailsPanel>
        <div className="buttonsNav">
          <div className="panel">
            <div className="sectionPanel">
              <div className="sectionPanelTitle">
                <p>{t('@orders/CLIENT', 'Cliente')}</p>
              </div>
              <div className="sectionContent">
                <div className="sectionSubContentVertical">
                  <div className="sectionSubContentTitle">
                    <p>{t('@orders/IDENTIFICATION', 'Identificação')}</p>
                  </div>
                  <div className="sectionSubContentInfo">
                    <p>{`${order?.order.customer_name} ${order?.order.customer_surname}`}</p>
                    <p>{CPFformat(order?.order.customer_document)}</p>
                    <p>{order?.order.customer_email}</p>
                    <p>{telephoneFormat(order?.order.customer_phone)}</p>
                  </div>
                </div>
                <div className="sectionSubContentVertical">
                  <div className="sectionSubContentTitle">
                    <p>{t('@orders/DELIVERY_ADDRES', 'Endereço de Entrega')}</p>
                  </div>
                  <div className="sectionSubContentInfo">
                    <p>
                      {order?.order.shipping_street},{' '}
                      {order?.order.shipping_number} -{' '}
                      {order?.order.shipping_complement}
                    </p>
                    <p>
                      {order?.order.shipping_city}/{order?.order.shipping_state}
                    </p>
                    <p>{cepFormat(order?.order.shipping_zipcode)}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="divider" />
            <div className="sectionPanel">
              <div className="sectionPanelTitle">
                <p>Vendedor</p>
              </div>
              <div className="sectionContent">
                <div className="sectionSubContentVertical">
                  <div className="sectionSubContentTitle">
                    <p>Dados do Vendedor</p>
                  </div>
                  <div className="sectionSubContentInfo">
                    <p>Usuário Logado: {' - '}</p>
                    <p>Código do Vendedor: {order?.order.sale_user_code}</p>
                    <p>Nome de Vendedor: {order?.order.sale_user_name}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DetailsPanel>
    </>
  );
};

const OrderDetailIdentify: React.FC<IModalProps> = ({ order }) => {
  return (
    <CurrentOrderProvider>
      <Content order={order} />
    </CurrentOrderProvider>
  );
};

export default OrderDetailIdentify;
