import {
  IOrderPaymentsCharges,
  IOrderPaymentsChargesPayload,
  IOrderPaymentsPayloadInstallments,
} from '@hooks/useCurrentOrder/models';
import formatCardBrand from '@util/formatCardBrand';
import React from 'react';
import MastercardLogo from '@assets/mastercard-logo.svg';
import VisaLogo from '@assets/visa-logo.svg';
import AmexLogo from '@assets/amex-logo.svg';
import HipercardLogo from '@assets/hipercard-logo.svg';
import EloLogo from '@assets/elo-logo.svg';
import { MdPayment } from 'react-icons/md';
import { t } from '@i18n/index';

const getCardInformation = (
  chargeInformation: IOrderPaymentsChargesPayload,
): JSX.Element | string => {
  if (chargeInformation.card_type && chargeInformation.card_brand) {
    const lowerCardType = chargeInformation.card_type.toLowerCase();
    const cardType = lowerCardType.includes('debit')
      ? t('@orders/CARD_DEBIT', 'Cartão de Débito')
      : lowerCardType.includes('credit')
      ? t('@orders/CARD_CREDIT', 'Cartão de Crédito')
      : chargeInformation.card_type;

    const formattedCardBrand = formatCardBrand(chargeInformation.card_brand);
    const cardIcon = (() => {
      switch (formattedCardBrand.toLowerCase()) {
        case 'elo':
          return <img src={EloLogo} alt="Ícone Elo" className="elo-logo" />;
        case 'hipercard':
          return (
            <img
              src={HipercardLogo}
              alt="Ícone Hipercard"
              className="hipercard-logo"
            />
          );
        case 'amex':
          return <img src={AmexLogo} alt="Ícone Amex" className="amex-logo" />;
        case 'visa':
          return <img src={VisaLogo} alt="Ícone Visa" className="visa-logo" />;
        case 'mastercard':
          return (
            <img
              src={MastercardLogo}
              alt="Ícone MasterCard"
              className="mastercard-logo"
            />
          );
        default:
          return null;
      }
    })();

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <MdPayment style={{ marginRight: '0.5rem' }} />
        {`${cardType} - ${formattedCardBrand}`}
        {cardIcon && <span style={{ marginLeft: '0.5rem' }}>{cardIcon}</span>}
      </div>
    );
  }

  return '-';
};

const installmentNumber = (
  installment_quantity?:
    | IOrderPaymentsPayloadInstallments[]
    | IOrderPaymentsChargesPayload,
): JSX.Element | string => {
  if (!installment_quantity) {
    return '-';
  }

  if (Array.isArray(installment_quantity)) {
    const totalValue = installment_quantity.reduce(
      (acc, installment) => acc + installment.total,
      0,
    );
    return installment_quantity.length > 0
      ? `${installment_quantity[0]?.number}x de ${totalValue.toFixed(2)}`
      : 'Informação não disponível';
  }

  const payload = installment_quantity as IOrderPaymentsChargesPayload;
  return payload?.installment_quantity
    ? `Parcelamento: ${payload.installment_quantity}x de ${(
        Math.floor(payload.amount / payload.installment_quantity) / 100
      ).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })}`
    : '-';
};

const PaymentMethod: React.FC<{ charge: IOrderPaymentsCharges }> = ({
  charge,
}) => {
  const cardInfo = getCardInformation(charge.payload);
  const installmentsInfo = installmentNumber(charge.payload);
  return (
    <>
      <div className="sectionSubContentBottomTitle">
        <p>Forma de pagamento:</p>
      </div>
      <div className="sectionSubContentBottom">
        <div className="sectionSubContentBottomCardInfo">
          <div>{cardInfo}</div>
        </div>
        <div className="sectionSubContentBottomInfo">
          <div>{installmentsInfo}</div>
        </div>
      </div>
    </>
  );
};

export default PaymentMethod;
