import produce from 'immer';
import { Reducer } from 'redux';

// Type import
import { IStoresState, StoresActionTypes } from './types';

const INITIAL_STATE: IStoresState = {
  stores_loaded: false,
  stores_data: [],
};

const stores: Reducer<IStoresState> = (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case StoresActionTypes.STORES_SET:
        draft.stores_loaded = action.payload.open;
        draft.stores_data = action.payload.set;
        break;

      default:
    }
  });
};

export default stores;
