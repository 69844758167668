import React from 'react';
import { t } from '@i18n/index';
import { IOrder } from '@hooks/useCurrentOrder/models';

const UpdatedPayment: React.FC<{ order?: IOrder }> = ({ order }) => {
  return (
    <div className="sectionPanelTitle">
      <p>{t('@orders/PAYMENTS', 'Total Atualizado: ')}</p>
      <p className="sectionPanelTitleSecondary">
        R$ {(Number(order?.status.paid_amount) / 100).toFixed(2)}
      </p>
    </div>
  );
};

export default UpdatedPayment;
