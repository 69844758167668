import React, {
  useEffect,
  useContext,
  useState,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import { MdAdd } from 'react-icons/md';
import { BiPencil } from 'react-icons/bi';
import { toast } from 'react-toastify';

// Util import
import setActivePage from '@util/setActivePage';

// Component import
import Modal, { IModalRef } from '@components/Modal';
import Button from '@components/Button';
import Switch from '@components/Switch';
import Loading from '@components/Loading';

// Hook import
import {
  useBrandAndPaymentSettings,
  BrandAndPaymentSettingsProvider,
} from '@hooks/useBrandAndPaymentSettings';

// Service Import
import api from '@services/api';

// Page import
import BrandsStatusChangeConfirmation from './BrandsStatusChangeConfirmation';
import PaymentsStatusChangeConfirmation from './PaymentsStatusChangeConfirmation';
import BrandCreation from './BrandCreation';
import BrandEdition from './BrandEdition';
import PaymentCreation from './PaymentCreation';
import PaymentEdition from './PaymentEdition';

// Style import
import {
  Container,
  PaymentsPanel,
  BrandsPanel,
  PaymentsTable,
  BrandsTable,
  Pill,
} from './styles';

// interfaces
interface IBrand {
  id: string;
  code: number;
  name: string;
  enabled: boolean;
  image_url: string;
  payload: ISimplePayload;
  created_at: Date;
  updated_at: Date;
  providers: IProvider[];
  stores: IBrandStore[];
}

interface ISimplePayload {
  product_code_pattern: string;
  product_color_pattern?: string;
}

interface IProvider {
  id: string;
  brand_id: string;
  name: string;
  code: string;
  enabled: boolean;
  payload: IPayload;
  created_at: Date;
  updated_at: Date;
}

interface IBrandStore {
  id: string;

  store_id: string;
  brand_id: string;
  enabled: true;
  created_at: Date;
  updated_at: Date;
  store: IStore;
}

interface IPayload {
  key: string;
  token: string;
  base_subdomain: string;
  salecode_pattern: string;
  sale_channel_code: number;
  payment_system_code: number;
  bustoken?: string;
}

interface IStore {
  id: string;
  code: string;
  name: string;
  enabled: boolean;
  codename: string;
  zipcode: string;
  latitude: any;
  longitude: any;
  street: any;
  number: any;
  complement: any;
  neighborhood: any;
  city: any;
  state: any;
  country: any;
  image_url: any;
  created_at: Date;
  updated_at: Date;
}

interface IPaymentType {
  id: string;
  code: string;
  name: string;
  enabled: boolean;
  created_at: Date;
  updated_at: Date;
  providerData: IPaymentProvider;
}

interface IPaymentProvider {
  id: string;
  payment_type_id: string;
  name: string;
  code: string;
  enabled: boolean;
  payload: any;
  created_at: Date;
  updated_at: Date;
}

const Content: React.FC = () => {
  // Local states
  const [loading, setLoading] = useState(false);
  const [brands, setBrands] = useState<IBrand[]>();
  const [paymentProviders, setPaymentProviders] = useState<IPaymentProvider[]>(
    [],
  );
  const [paymentTypes, setPaymentTypes] = useState<IPaymentType[]>([]);
  const [paymentsDataToTable, setPaymentsDataToTable] = useState<
    IPaymentType[]
  >([]);

  // Local refs
  const brandsStatusChangeConfirmationModalRef = useRef<IModalRef>(null);
  const paymentsStatusChangeConfirmationModalRef = useRef<IModalRef>(null);
  const brandCreationModalRef = useRef<IModalRef>(null);
  const brandEditionModalRef = useRef<IModalRef>(null);
  const paymentCreationModalRef = useRef<IModalRef>(null);
  const paymentEditionModalRef = useRef<IModalRef>(null);

  // Get context
  const currentBrandAndPayment = useBrandAndPaymentSettings();

  // Get translation function
  const { t } = useTranslation();

  // Theme context
  const themeContext = useContext(ThemeContext);

  // Initialized
  useEffect(() => {
    // Set page
    setActivePage('@providers');
  }, []);

  // Get brands
  const getBrands = useCallback(async () => {
    // Loading set
    setLoading(true);

    try {
      // API call
      const response = await api.get('admin/brand', {});
      setBrands(response.data);

      setLoading(false);
    } catch (err: any) {
      // Check if server had return a error
      if (err.response?.data?.message) toast.error(err.response.data.message);
      else {
        toast.error(
          t(
            '@general/CONNECTION_FAILURE_ERROR',
            'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
          ),
        );
      }

      // Loading set
      setLoading(false);

      throw err;
    }
  }, [t]);

  // Get Payment Types
  const getPaymentTypes = useCallback(async () => {
    // Loading set
    setLoading(true);

    try {
      // API call
      const response = await api.get('admin/payment-type', {});
      setPaymentTypes(response.data);

      setLoading(false);
    } catch (err: any) {
      // Check if server had return a error
      if (err.response?.data?.message) toast.error(err.response.data.message);
      else {
        toast.error(
          t(
            '@general/CONNECTION_FAILURE_ERROR',
            'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
          ),
        );
      }

      // Loading set
      setLoading(false);

      throw err;
    }
  }, [t]);

  // Get Payment Providers
  const getPaymentProviders = useCallback(async () => {
    // Loading set
    setLoading(true);

    try {
      // API call
      const response = await api.get('admin/payment-provider', {});
      setPaymentProviders(response.data);

      setLoading(false);
    } catch (err: any) {
      // Check if server had return a error
      if (err.response?.data?.message) toast.error(err.response.data.message);
      else {
        toast.error(
          t(
            '@general/CONNECTION_FAILURE_ERROR',
            'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
          ),
        );
      }

      // Loading set
      setLoading(false);

      throw err;
    }
  }, [t]);

  // Initial load
  useEffect(() => {
    getPaymentTypes();
    getPaymentProviders();
    getBrands();
  }, [getBrands, getPaymentProviders, getPaymentTypes]);

  useEffect(() => {
    if (paymentTypes && paymentProviders) {
      const paymentTypesWithProviders: any = [];

      paymentTypes.forEach(paymentType => {
        const providerData = paymentProviders.find(
          paymentProvider => paymentProvider.payment_type_id === paymentType.id,
        );

        if (providerData) {
          paymentTypesWithProviders.push({ ...paymentType, providerData });
        } else {
          paymentTypesWithProviders.push({ ...paymentType });
        }
      });

      setPaymentsDataToTable(paymentTypesWithProviders);
    }
  }, [paymentProviders, paymentTypes]);

  // Modals
  const modals = useMemo(
    () => (
      <>
        <Modal ref={brandCreationModalRef} size="small">
          <BrandCreation
            hide={() => {
              brandCreationModalRef.current?.hide();
              getBrands();
            }}
          />
        </Modal>
        <Modal ref={brandEditionModalRef} size="small">
          <BrandEdition
            hide={() => {
              brandEditionModalRef.current?.hide();
              getBrands();
            }}
          />
        </Modal>
        <Modal ref={paymentCreationModalRef} size="small">
          <PaymentCreation
            hide={() => {
              paymentCreationModalRef.current?.hide();
              getPaymentTypes();
              getPaymentProviders();
            }}
          />
        </Modal>
        <Modal ref={paymentEditionModalRef} size="small">
          <PaymentEdition
            hide={() => {
              paymentEditionModalRef.current?.hide();
              getPaymentTypes();
              getPaymentProviders();
            }}
          />
        </Modal>
        <Modal ref={paymentsStatusChangeConfirmationModalRef} size="small">
          <PaymentsStatusChangeConfirmation
            hide={() => {
              paymentsStatusChangeConfirmationModalRef.current?.hide();
              getPaymentTypes();
              getPaymentProviders();
            }}
          />
        </Modal>
        <Modal ref={brandsStatusChangeConfirmationModalRef} size="small">
          <BrandsStatusChangeConfirmation
            hide={() => {
              brandsStatusChangeConfirmationModalRef.current?.hide();
              getBrands();
            }}
          />
        </Modal>
      </>
    ),
    [getBrands, getPaymentProviders, getPaymentTypes],
  );

  return (
    <Container>
      <PaymentsPanel>
        {loading ? (
          <div className="loading">
            <Loading size={3} />
          </div>
        ) : (
          <>
            <div className="tableActions">
              <h2>{t('@providers/PAYMENTS_PANEL_TITLE', 'Pagamentos')}</h2>
              <Button
                noFullWidth
                startIcon={<MdAdd color={themeContext.font_low} />}
                onClick={() => paymentCreationModalRef.current?.show()}
              >
                {t('@providers/REGISTER_PAYMENT', 'Pagamento')}
              </Button>
            </div>
            <PaymentsTable>
              <div className="header">
                <div />
                <p>{t('@providers/NAME_COLUMN', 'Nome')}</p>
                <p>{t('@providers/PROVIDER_COLUMN', 'Provedor')}</p>
              </div>
              {paymentsDataToTable.map(paymentType => (
                <div className="rowTable">
                  <div>
                    <Switch
                      checked={paymentType.enabled}
                      onChange={() => {
                        currentBrandAndPayment.set_current_payment(paymentType);
                        paymentsStatusChangeConfirmationModalRef.current?.show();
                      }}
                    />
                  </div>
                  <div>{paymentType.name}</div>

                  {paymentType.providerData ? (
                    <div>
                      <Pill
                        color={
                          paymentType.providerData.enabled
                            ? themeContext.button_success
                            : themeContext.button_secondary
                        }
                      >
                        {paymentType.providerData?.name}
                      </Pill>
                    </div>
                  ) : (
                    <div />
                  )}

                  <button
                    type="button"
                    onClick={() => {
                      currentBrandAndPayment.set_current_payment(paymentType);
                      paymentEditionModalRef.current?.show();
                    }}
                  >
                    <BiPencil color={themeContext.font_secondary} />
                  </button>
                </div>
              ))}
            </PaymentsTable>
          </>
        )}
      </PaymentsPanel>

      <BrandsPanel>
        {loading ? (
          <div className="loading">
            <Loading size={3} />
          </div>
        ) : (
          <>
            <div className="tableActions">
              <h2>{t('@providers/BRANDS_PANEL_TITLE', 'Marcas')}</h2>
              <Button
                noFullWidth
                startIcon={<MdAdd color={themeContext.font_low} />}
                onClick={() => brandCreationModalRef.current?.show()}
              >
                {t('@providers/REGISTER_BRAND', 'Marca')}
              </Button>
            </div>
            <BrandsTable>
              <div className="header">
                <div />
                <p>{t('@providers/IMAGE_COLUMN', 'Imagem')}</p>
                <p>{t('@providers/NAME_COLUMN', 'Nome')}</p>
                <p>{t('@providers/CODE_COLUMN', 'Código')}</p>
                <p>{t('@providers/PROVIDER_COLUMN', 'Provedor')}</p>
              </div>
              {brands?.map(brand => (
                <div className="rowTable">
                  <div>
                    <Switch
                      checked={brand.enabled}
                      onChange={() => {
                        currentBrandAndPayment.set_current_brand(brand);
                        brandsStatusChangeConfirmationModalRef.current?.show();
                      }}
                    />
                  </div>
                  <div>
                    <img
                      key={brand.image_url}
                      src={brand.image_url}
                      alt={brand.image_url}
                    />
                  </div>
                  <div>{brand.name}</div>
                  <div>{brand.code}</div>
                  <div>
                    {brand.providers.map(provider => (
                      <Pill
                        color={
                          provider.enabled
                            ? themeContext.button_success
                            : themeContext.button_secondary
                        }
                      >
                        {provider.name}
                      </Pill>
                    ))}
                  </div>
                  <button
                    type="button"
                    onClick={() => {
                      currentBrandAndPayment.set_current_brand(brand);
                      brandEditionModalRef.current?.show();
                    }}
                  >
                    <BiPencil color={themeContext.font_secondary} />
                  </button>
                </div>
              ))}
            </BrandsTable>
          </>
        )}
      </BrandsPanel>
      {modals}
    </Container>
  );
};

// Consumer
const Providers: React.FC = () => {
  return (
    <BrandAndPaymentSettingsProvider>
      <Content />
    </BrandAndPaymentSettingsProvider>
  );
};

export default Providers;
