import styled from 'styled-components';

export const Box = styled.div`
  display: flex;

  .alert {
    color: ${() => '#F2994A'};
    font-size: 1rem;
    font-weight: 700;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
  }

  .adjustedMessage {
    display: flex;
    margin-bottom: 2rem;
    align-items: center;
    color: ${() => '#DCC6B7'};
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
  }

  .adjustedInfo {
    margin-top: 0.5rem;
    color: ${() => '#1BB55C'};
    font-size: 1rem;
    font-weight: 600;
  }

  .alertMessage {
    height: 100%;
    color: ${() => '#F2994A'};
    font-size: 0.875rem;
    font-weight: 500;
    margin-top: 0.063;
    align-items: flex-end;
  }

  .drawer-toggle {
    color: ${({ theme }) => theme.background_high};
    display: inline-flex;
    align-items: center;
    margin-top: 0.5rem;
    white-space: nowrap;
    margin-left: 2em;
    font-weight: normal;
  }

  .drawer-content {
    margin-top: 1rem;
  }
`;

export const ChangeStatusLink = styled.a`
  color: ${() => '#F2994A'};
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 0.5rem;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 0.875rem;
  letter-spacing: 0.006rem;
`;
