import styled from 'styled-components';
import { rgba, darken } from 'polished';
import CustomScrollBar from 'react-custom-scrollbars';

const HEADER_SIZE = 3.75;
const SIDEBAR_SIZE = 17.75;

interface IContainerProps {
  open: boolean;
  mobileSize: boolean;
}

export const Container = styled.div<IContainerProps>`
  position: fixed;
  left: ${props =>
    props.open ? 0 : props.mobileSize ? '-110%' : `-${SIDEBAR_SIZE + 1}rem`};
  top: ${HEADER_SIZE}rem;
  z-index: 99;

  display: flex;
  flex-direction: column;

  background: ${({ theme }) => theme.background_primary};
  width: ${props => (props.mobileSize ? '100%' : `${SIDEBAR_SIZE}rem`)};
  height: calc(100vh - ${HEADER_SIZE}rem);
  transition: 0.2s ease-out;

  box-shadow: 0rem 0rem 0.313rem 0rem
    ${({ theme }) => rgba(theme.background_primary, 0.95)};

  .content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100%;
    width: 100%;

    section.head {
      background: ${() => '#DCC6B7'};
      display: flex;
      flex-direction: row;
      gap: 0.75rem;
      padding: 1rem;

      .info {
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        color: ${() => '#212121'};

        .name {
          font-weight: 600;
          font-size: 1rem;
          line-height: 1.21rem;
        }
      }
    }

    section.navigation {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-top: 0.5rem;

      .MuiButton-fullWidth {
        height: 3.438rem;
        position: relative;

        &.button-wrapper {
          &.active::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 0.375rem;
            background-color: #dcc6b7;
          }
        }
      }

      span.MuiButton-label {
        text-transform: none;
        margin-left: 1.5rem;
        gap: 1.5rem;
        font-weight: 400;
        font-size: 1rem;
        justify-content: start;
        align-items: center;
      }
    }

    section.footer {
      display: flex;
      flex-direction: column;
      justify-content: start;

      button.MuiButtonBase-root {
        display: flex;
        align-items: space-between;
        justify-content: start;
        margin-top: 1rem;
        margin-bottom: 1rem;
      }

      span.divisor {
        margin-left: 1.5rem;
        width: 80%;
        background: ${({ theme }) => darken(0.15, theme.background_secondary)};
        border-radius: 0.125rem;
        height: 0.1875rem;
      }
    }
  }

  @media screen and (max-width: 48rem) {
    display: flex;
    width: 100%;
    position: fixed;
  }
`;

export const ScrollBar = styled(CustomScrollBar)``;
