import styled from 'styled-components';
import Button from '@components/Button';

export const Container = styled.div`
  padding: 1.5rem;
  height: 100%;
`;

export const CancelButton = styled(Button)`
  button.MuiButtonBase-root {
    display: flex;
    margin-left: 20px;
    width: 18rem;
    height: 3rem;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
  }
`;

export const StoresPanel = styled.div`
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .pageTitle {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;

    .backIcon {
      color: ${({ theme }) => theme.button_high};
      font-size: 1.5rem;
    }

    h2 {
      margin-left: 0.5rem;
    }
  }
`;

export const OrdersHeader = styled.div`
  margin-top: 0.5rem;
  width: 100%;

  .header {
    background-color: ${({ theme }) => theme.background_secondary};
    border-top: 0.063rem solid ${({ theme }) => theme.background_secondary};
    border-bottom: 0.063rem solid ${({ theme }) => theme.background_secondary};
    padding: 0.5rem 1rem;
    display: grid;
    grid-template-columns: 1fr 1.2fr 1.2fr repeat(5, 1fr) 0.2fr;
    align-items: center;
    justify-items: center;

    div {
      display: flex;
      align-items: center;

      p {
        color: ${({ theme }) => theme.background_low};
        border: none;
        padding: 0;
        font-weight: 700;
        margin-right: 0.5rem;
        display: flex;
        align-items: center;
      }

      &:nth-child(2),
      &:nth-child(3) {
        justify-content: center;
        align-items: center;
        display: flex;
        width: 100%;
      }

      button {
        font-size: 1rem;
        display: flex;
        align-items: center;
      }
    }
  }

  .rowTable {
    padding: 0.5rem 1rem;
    display: grid;
    grid-template-columns: 1fr 1.2fr 1.2fr repeat(5, 1fr) 0.2fr;
    box-shadow: 0px 0.25rem 0.25rem rgba(0, 0, 0, 0.25);

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-right: ${({ theme }) => theme.background_secondary} solid 1px;
      border-width: 1px;

      .hourSection {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        .hourSectionInfo {
          margin-left: 0.5rem;

          .hour {
            font-size: 0.625rem;
            color: ${({ theme }) => theme.font_secondary};
          }
        }
      }

      .price {
        font-weight: 700;
      }

      .numberCode {
        color: ${({ theme }) => theme.font_secondary};
      }

      .productQuantity {
        color: ${({ theme }) => theme.font_secondary};
        font-size: 0.75rem;
      }
    }

    div:last-child {
      border-right: none;
    }
  }
`;

export const ListDateStyle = styled.p`
  margin-bottom: 0.5rem;
  color: ${props => props.color};
  font-weight: 600;
`;

export const DetailsPanel = styled.div`
  width: 100%;

  .chargeTitle {
    display: flex;
    width: 100%;
    font-weight: 700;
    font-size: 1rem;
    color: ${({ theme }) => theme.background_secondary};

    .chargeType {
      margin-left: 0.8rem;
      color: ${({ theme }) => theme.background_high};
    }
  }

  .emptyChargeInfo {
    text-align: center;
    position: relative;
    font-size: 0.875rem;
  }

  .selectedChargeInformation {
    text-align: center;
    font-weight: 700;
    font-size: 1.5rem;
    color: ${({ theme }) => theme.background_secondary};
  }

  .clipboard {
    padding: 5rem;
    align-self: center;
  }

  .subChargeTitle {
    display: flex-start;
    font-weight: 700;
    font-size: 1.2rem;
    margin-bottom: 1.8rem;
    color: ${({ theme }) => theme.background_secondary};

    .subChargeType {
      margin-left: 0.8rem;
      color: ${({ theme }) => theme.background_high};
    }
  }
  .chargeStatus {
    padding: 0.01rem;
    font-size: 1rem;
    color: ${({ theme }) => theme.background_high};
  }
  .divider {
    margin-top: 0.063rem;
    border: ${({ theme }) => theme.background_secondary} solid 1px;
    justify-self: center;
    align-self: center;
  }

  .buttonsNav {
    width: 100%;
    height: 0%;
    justify-self: center;
    align-self: center;
  }

  .sectionPanelTitle {
    display: flex;
    font-weight: 700;
    font-size: 1.5rem;
    margin-top: 1.7rem;
    color: ${({ theme }) => theme.background_secondary};

    .sectionPanelTitleSecondary {
      margin-left: 2rem;
      font-size: 1.3rem;
      margin-top: 0.2rem;
      color: ${({ theme }) => theme.background_high};
    }

    .sectionSubContentStatus {
      font-weight: 700;
      font-size: 0.975rem;
      color: ${({ theme }) => theme.background_secondary};
      height: 100%;
      padding-bottom: 0.5rem;
      padding-left: 0%;
      border-bottom: ${({ theme }) => theme.background_secondary} 0.1rem;
    }
  }
  .sectionPanel {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 1rem;
    min-height: -webkit-fill-available;

    .sectionContent {
      display: flex;
      width: 100%;
      padding: 1rem;
      padding-left: 0%;
      padding-right: 0%;

      .sectionSubContentBottomTitle {
        font-weight: 700;
        font-size: 0.975rem;
        border-bottom: ${({ theme }) => theme.background_secondary} solid 1px;
        padding-bottom: 10px;
      }

      .sectionSubContentBottom {
        display: flex;
        flex-direction: column;
        font-size: 0.975rem;
      }
      .sectionSubContentBottomInfo {
        display: flex-start;
        margin-top: 1rem;
      }

      .sectionSubContentBottomCardInfo {
        display: flex;
        height: 1.8rem;
        justify-content: space-between;
        margin-top: 0.5rem;
        padding: 0rem;
      }

      .sectionSubContentStatus {
        justify-content: space-between;
        font-weight: 700;
        font-size: 0.975rem;
        padding-bottom: 0%;
        margin-left: 1rem;
        margin-top: 1.2rem;
        color: ${({ theme }) => theme.background_secondary};
        border-bottom: ${({ theme }) => theme.background_secondary} solid
          0.063rem;
      }
      .subContentStatusType {
        width: 100%;
      }
      .subContentValue {
        display: flex;
        justify-content: space-between;
      }
      .detailDate {
        font-weight: 300;
        font-size: 0.8rem;
        padding: 0.01rem;
        margin-top: 0.8rem;
        margin-bottom: 0.2rem;
        margin-left: 0%;
        text-align-last: left;
        color: ${({ theme }) => theme.background_secondary};
      }
      .detailValueAmount {
        font-weight: 700;
        font-size: 1.2rem;
        margin-top: 0.5rem;
        margin-bottom: 0.2rem;
        text-align-last: right;
        color: ${({ theme }) => theme.button_success};
      }
      .detailValueCanceled {
        font-weight: 700;
        font-size: 1.2rem;
        margin-top: 0.5rem;
        margin-bottom: 0.2rem;
        text-align-last: right;
        color: ${({ theme }) => theme.button_danger};
      }
      .detailValueFinal {
        font-weight: 700;
        font-size: 1.2rem;
        margin-top: 0.5rem;
        margin-bottom: 0.2rem;
        text-align-last: right;
        color: ${({ theme }) => theme.background_high};
      }
    }

    .subContentVertical {
      width: 48%;
      margin-top: 1.25rem;
    }

    .subContentVerticalRight {
      width: 50%;
    }

    .border {
      border-left: 1px solid ${({ theme }) => theme.background_secondary};
      margin-left: 4.5rem;
      margin-right: 3rem;
      margin-top: 1.5rem;
      margin-bottom: 5rem;
      padding: 0.5rem;
    }

    .sectionSubContentHorizontal {
      display: flex;
      padding-bottom: 0.5rem;
      .valueText {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 0.75rem;
        margin-bottom: 0.5rem;

        p {
          margin: 0 0.25rem;
        }
      }
    }
  }

  .rolagem {
    margin-top: 0.125rem;
    height: 65%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: scroll;
    padding-right: 0.25rem;
    ::-webkit-scrollbar {
      width: 0.25rem;
    }
    ::-webkit-scrollbar-track {
      background: none;
    }
    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.background_secondary};
      border-radius: 0.25rem;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  .cancelButton {
    width: 40%;
    margin-top: 10rem;
    margin-left: 24.5rem;
  }
`;
