import { IOrder } from '@hooks/useCurrentOrder/models';
import { t } from '@i18n/index';
import React from 'react';
import {
  CurrentShipping,
  Row,
  SectionText,
  SectionTitle,
  SectionTitleSecondary,
  ShippingValue,
} from './styles';

const PackageInfo: React.FC<{ order?: IOrder }> = ({ order }) => {
  return (
    <>
      <Row>
        <SectionTitle>
          {t('@order-detail-packages/VALUE_ITEMS', 'Valor dos Itens:')}
        </SectionTitle>
        <SectionTitleSecondary>
          <span className="value">
            R$ {(Number(order?.order.total_current_price) / 100).toFixed(2)}
          </span>
        </SectionTitleSecondary>
        <CurrentShipping>{'Frete: '}</CurrentShipping>
        <ShippingValue>
          <span className="value">
            R$ {(Number(order?.order.shipping_current_price) / 100).toFixed(2)}
          </span>
        </ShippingValue>
      </Row>

      <Row>
        <SectionTitle>
          {t('@order-detail-packages/VALUE_DISCOUNT', 'Valor dos Descontos:')}
        </SectionTitle>
        <SectionTitleSecondary>
          <span className="value">
            R${' '}
            {(
              (Number(order?.order.total_current_price) -
                Number(order?.order.total_original_price)) /
              100
            ).toFixed(2)}
          </span>
        </SectionTitleSecondary>
        <SectionText>
          <span className="couponLabel">
            {t('order-detail-packages/VALUE_COUPON', 'Cupom Aplicado:')}
          </span>
        </SectionText>
        <SectionTitleSecondary>
          <span className="value">
            {order?.order.coupon ? order.order.coupon : '-'}
          </span>
        </SectionTitleSecondary>
      </Row>
    </>
  );
};

export default PackageInfo;
