import React from 'react';
import { IOrderPackages } from '@hooks/useCurrentOrder/models';
import { t } from '@i18n/index';
import OrderItemCard from '@components/OrderItemCard';
import PackageIntegrationSteps from './PackageIntegrationStep';
import {
  Row,
  Section,
  SectionTextSecondary,
  SubSectionColumn,
  VerticalDivider,
} from './styles';
import PackageDetailsHeader from './PackageDetailHeader';

const PackageDetails: React.FC<{ currentPackage?: IOrderPackages }> = ({
  currentPackage,
}) => {
  return (
    <div className="packageItems">
      <Row>
        <PackageDetailsHeader currentPackage={currentPackage} />
      </Row>
      <SubSectionColumn>
        <Section className="integrationStepsColumn">
          <SectionTextSecondary>
            {t(
              '@order-detail-packages/PACKAGE_INTEGRATION_STEPS_TITLE',
              'Etapas de Integração:',
            )}
          </SectionTextSecondary>
          <PackageIntegrationSteps
            created_at={currentPackage?.created_at}
            completed_at={currentPackage?.completed_at}
            invoiced_at={currentPackage?.invoiced_at}
            canceled_at={currentPackage?.canceled_at}
            package_items={currentPackage?.items}
          />
        </Section>
        <Section>
          <VerticalDivider />
        </Section>
        <Section className="listPackageItems">
          <SectionTextSecondary>
            {t('@order-detail-packages/PACKAGE_ITEMS_LIST', 'Itens do Pacote:')}
          </SectionTextSecondary>
          {currentPackage?.items.map(item => (
            <OrderItemCard item={item} brand={currentPackage.brand.name} />
          ))}
        </Section>
      </SubSectionColumn>
    </div>
  );
};

export default PackageDetails;
