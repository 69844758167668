// Action types
export const UiActionTypes = {
  LEFT_MENU_SET: '@ui/LEFT_MENU_SET',
  LANGUAGE_SET: '@ui/LANGUAGE_SET',
  ACTIVE_PAGE_SET: '@ui/ACTIVE_PAGE_SET',
  HELP_MODE_SET: '@ui/HELP_MODE_SET',
};

// State
export interface IUiState {
  left_menu: boolean;
  active_page: string;
  help_mode: boolean;
  language?: string;
}
