import React, { useState } from 'react';
import PackagePill from '@components/PackagePill';
import { IOrderPackages } from '@hooks/useCurrentOrder/models';
import { t } from '@i18n/index';
import { MdOpenInNew } from 'react-icons/md';
import { toast } from 'react-toastify';
import { CircularProgress } from '@material-ui/core';
import {
  InvoiceLink,
  InvoiceLinkWrapper,
  Link,
  SectionTitleSecondary,
  VtexLink,
} from './styles';

const mais_atacado_jwt = process.env.REACT_APP_API_MAIS_ATACADO_JWT;
const mais_atacado_url = process.env.REACT_APP_API_MAIS_ATACADO_URL;

const vtexUrl = (currentPackage: IOrderPackages) => {
  const packageBrandProviderBaseSubdomain =
    currentPackage?.brand_provider?.payload.base_subdomain;
  const packageTypeCode = currentPackage?.package_type.code;

  if (packageTypeCode !== 'own_stock') {
    const vtex_url = `https://${packageBrandProviderBaseSubdomain}.myvtex.com/admin/orders/${currentPackage?.code}`;
    return (
      <VtexLink>
        <Link href={vtex_url} target="_blank">
          {' '}
          Ver pacote na VTEX
        </Link>
        <MdOpenInNew style={{ width: '1.25rem', height: '1.25rem' }} />
      </VtexLink>
    );
  }
  return '';
};

const PackageDetailsHeader: React.FC<{ currentPackage?: IOrderPackages }> = ({
  currentPackage,
}) => {
  const [downloadingInvoice, setDownloadingInvoice] = useState<number | null>(
    null,
  );

  const handleDownloadInvoice = async (key: string, index: number) => {
    if (downloadingInvoice === index) return;

    try {
      setDownloadingInvoice(index);
      toast.info('Aguarde o download da nota fiscal.', { autoClose: 6000 });

      const response = await fetch(
        `${mais_atacado_url}/v1/NotaFiscal/pdfNota/${key}`,
        {
          headers: {
            Authorization: mais_atacado_jwt || '',
          },
        },
      );

      if (!response.ok) {
        throw new Error('Erro ao obter a URL da nota fiscal');
      }

      const blob = await response.blob();
      const blobLink = URL.createObjectURL(blob);
      window.open(blobLink, '_blank');
      toast.success('Nota fiscal baixada com sucesso!', { autoClose: 5000 });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      toast.error('Nota fiscal com erro ou inexistente.', {
        autoClose: 5000,
      });
    } finally {
      setDownloadingInvoice(null);
    }
  };

  return (
    <div className="headerPosition">
      <div className="packageNameCodeStyle">
        <SectionTitleSecondary>
          {t('@order-detail-packages/PACKAGE_TITLE', 'Detalhes do Pacote: ')}
        </SectionTitleSecondary>
        <span style={{ marginLeft: '0.75rem' }}>
          <PackagePill order_package={currentPackage} />
        </span>
      </div>
      {currentPackage?.invoices && currentPackage.invoices.length > 0 && (
        <InvoiceLinkWrapper>
          {currentPackage.invoices.map((invoice, index) => (
            <InvoiceLink key={invoice.key}>
              <button
                type="button"
                onClick={() => handleDownloadInvoice(invoice.key, index)}
                disabled={downloadingInvoice === index}
              >
                {' '}
                <Link href="/#" onClick={e => e.preventDefault()}>
                  {downloadingInvoice === index ? (
                    <CircularProgress
                      size={20}
                      style={{
                        color: '#DCC6B7',
                      }}
                    />
                  ) : (
                    'Nota Fiscal'
                  )}
                  {currentPackage.invoices.length > 1 ? ` ${index + 1}` : ''}
                </Link>
              </button>
              <MdOpenInNew style={{ width: '1.25rem', height: '1.25rem' }} />
            </InvoiceLink>
          ))}
        </InvoiceLinkWrapper>
      )}
      {currentPackage && vtexUrl(currentPackage)}
    </div>
  );
};

export default PackageDetailsHeader;
