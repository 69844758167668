import React from 'react';
import { MdWarning } from 'react-icons/md';
import { Container } from './styles';

export const PageMessage: React.FC<{
  alertMessage: string;
}> = ({ alertMessage, children }) => {
  return (
    <Container>
      <MdWarning size="1.3rem" />
      <p className="alertMessage">
        <b>{alertMessage}</b>
      </p>
      <p className="subAlertMessage">{children}</p>
    </Container>
  );
};
