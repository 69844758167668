import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  input {
    height: 2.0625rem;
    width: 16.4375rem;
    margin-top: 0px;
    padding: 0 0.5rem 0 1rem;
    border-color: ${({ theme }) => theme.background_secondary};
  }
  .icon-tabler-calendar-event {
    height: 1.875rem;
    top: 50% !important;
    left: 95% !important;
    transform: translate(-16px, -54%) !important;
  }
`;

export const SearchBar = styled.div`
  background-color: ${({ theme }) => theme.background_high};
  border: 0.063rem solid ${({ theme }) => theme.font_secondary};
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.font_low};
  margin-left: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 20rem;

  button {
    padding: 0 0.75rem 0 0.75rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.font_low};
    font-size: 1.5rem;
  }
  input {
    height: 2.0625rem;
  }
  .inputInput {
    width: 100%;
    color: ${({ theme }) => theme.font_low};
    font-size: 0.875rem;
    height: 2.0625rem;
  }

  .MuiInputBase-root {
    width: 100%;
    height: 2.0625rem;
    padding-left: 1rem;
  }
`;
