import styled from 'styled-components';
import Button from '@components/Button';

export const Container = styled.div`
  padding: 1.5rem;

  .MuiTextField-root {
    & .MuiOutlinedInput-root {
      & fieldset {
        border-color: transparent;
      }
    }
  }

  .MuiInputBase-input {
    line-height: 1.5rem;
    font-family: 'Inter', semi-bold;
  }

  .MuiTypography-alignCenter {
    font-family: 'Inter', semi-bold;
  }
`;

export const CloseSection = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ButtonGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
`;

export const ExitButton = styled(Button)`
  button.MuiButton-outlined {
    color: ${({ theme }) => theme.font_danger};
    border-color: ${({ theme }) => theme.background_danger};
  }
`;
