import React, { useContext } from 'react';

import { IOrderPackages } from '@hooks/useCurrentOrder/models';

import { ThemeContext } from 'styled-components';

import { Pill } from './styles';

const PackagePill: React.FC<{
  order_package?: IOrderPackages;
  isDetails?: boolean;
}> = ({ order_package, isDetails }) => {
  const themeContext = useContext(ThemeContext);

  const displayPackageCode =
    order_package?.code && order_package?.code.length > 14
      ? `${order_package.code.substring(0, 14)}...`
      : order_package?.code;

  return (
    <>
      <Pill
        color={
          order_package?.canceled_at !== null
            ? themeContext.button_danger
            : order_package?.completed_at !== null
            ? themeContext.button_success
            : themeContext.button_warning
        }
      >
        {!isDetails ? order_package?.code : displayPackageCode}
      </Pill>
    </>
  );
};

export default PackagePill;
