import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { MdOutlineClose } from 'react-icons/md';
import { RiErrorWarningFill } from 'react-icons/ri';
import { toast } from 'react-toastify';

// Component import
import Button from '@components/Button';

// Type import
import { IError } from '@hooks/useCurrentError/models';

// Service Import
import api from '@services/api';

// Style import
import { Container } from './styles';

// Interfaces
interface IProps {
  hide: () => any;
  formattedErrors: IError[];
  markAction: 'none' | 'mark_as_not_completed' | 'mark_as_completed';
  setMarkAction: React.Dispatch<
    React.SetStateAction<'none' | 'mark_as_not_completed' | 'mark_as_completed'>
  >;
}

const StatusChangeConfirmationModal: React.FC<IProps> = ({
  hide,
  formattedErrors,
  markAction,
  setMarkAction,
}) => {
  // Get translation function
  const { t } = useTranslation();

  const markErrors = useCallback(async () => {
    const errorsToMark = formattedErrors.filter(error => {
      return error.marked === true;
    });

    if (markAction === 'mark_as_completed') {
      const markErrorsPromises = errorsToMark.map(async item => {
        try {
          // eslint-disable-next-line no-underscore-dangle
          await api.patch(`admin/errors/${item._id}/`, {
            mark: true,
          });
        } catch (err: any) {
          // Check if server had return a error
          if (err.response?.data?.message)
            toast.error(err.response.data.message);
          else {
            toast.error(
              t(
                '@general/CONNECTION_FAILURE_ERROR',
                'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
              ),
            );
          }
        }
      });

      await Promise.all(markErrorsPromises);
    }

    if (markAction === 'mark_as_not_completed') {
      const markErrorsPromises = errorsToMark.map(async item => {
        try {
          // eslint-disable-next-line no-underscore-dangle
          await api.patch(`admin/errors/${item._id}/`, {
            mark: false,
          });
        } catch (err: any) {
          // Check if server had return a error
          if (err.response?.data?.message)
            toast.error(err.response.data.message);
          else {
            toast.error(
              t(
                '@general/CONNECTION_FAILURE_ERROR',
                'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
              ),
            );
          }
        }
      });

      await Promise.all(markErrorsPromises);
    }

    setMarkAction('none');
    hide();
  }, [formattedErrors, hide, markAction, setMarkAction, t]);

  return (
    <Container>
      <div className="closeSection">
        <button type="button" onClick={() => hide()}>
          <MdOutlineClose />
        </button>
      </div>
      <div className="iconSection">
        <RiErrorWarningFill />
      </div>
      <div className="message">
        <p>
          {t(
            '@errorLogModal/CONFIRMATION_TEXT',
            'Você está alterando o status dos erros selecionados.',
          )}
          <br />
          {t(
            '@errorLogModal/CONFIRMATION_TEXT_QUESTION',
            'Confirma esta ação?',
          )}
        </p>
      </div>
      <div className="buttonsSection">
        <div className="confirmationButton">
          <Button onClick={() => markErrors()}>
            {t('@errorLogModal/CONFIRM', 'CONFIRMAR')}
          </Button>
        </div>
        <div>
          <Button color="danger" onClick={() => hide()}>
            <p className="buttonText">
              {t('@errorLogModal/CANCEL', 'CANCELAR')}
            </p>
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default StatusChangeConfirmationModal;
