import styled from 'styled-components';

export const Pill = styled.p`
  background-color: ${props => props.color};
  padding: 0.25rem 0.625rem;
  border-radius: 1rem;
  margin: 0.25rem;
  width: fit-content;
  color: white;
`;

export const ProductItemDesktop = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  background: ${({ theme }) => theme.background_primary};
  border-radius: 0.5rem;
  margin-top: 1.5rem;
  min-height: 11.688rem;
  margin-right: 0.5rem;
  box-shadow: 0.3125rem 0.125rem 0.75rem #231f20;

  &.unavailable {
    box-shadow: 0 0 0 0.188rem ${({ theme }) => theme.font_danger};
  }

  .image {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    align-self: stretch;
    max-width: 8.625rem;
    min-width: 8rem;

    img {
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
      object-fit: cover;
      flex: 1;
      align-self: stretch;
      max-width: 8.625rem;
      min-width: 8rem;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    height: 100%;
    width: 100%;
    padding: 1rem;
    overflow: hidden;

    .name {
      font-size: 1rem;
      font-weight: 400;
      color: ${({ theme }) => theme.font_primary};
      width: -webkit-fill-available;
      margin-bottom: 0.25rem;
      P {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .infoRow {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 80%;
      margin-bottom: 0.25rem;

      color: ${({ theme }) => theme.font_secondary};
      font-size: 0.75rem;
      font-weight: 400;

      p:first-child {
        margin-right: 1rem;
      }
    }
    .infoRow2 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 80%;
      margin-bottom: 0.25rem;

      color: ${({ theme }) => theme.font_secondary};
      font-size: 0.75rem;
      font-weight: 400;

      p:first-child {
        margin-right: 1rem;
      }
    }
    .colorAndSizeRow {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 80%;

      color: ${({ theme }) => theme.font_secondary};
      font-size: 0.75rem;
      font-weight: 400;

      button {
        display: flex;
        flex-direction: row;

        .changeButtonText {
          font-size: 0.75rem;
          color: ${({ theme }) => theme.font_primary};
          text-decoration: underline;
          margin-right: 0.125rem;
        }
      }
    }

    .price {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 1rem;

      font-size: 1rem;
      color: ${({ theme }) => theme.font_primary};

      .original_price {
        text-decoration: line-through;
        color: ${({ theme }) => theme.button_secondary};
      }
    }

    .editDelivery {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      button {
        p {
          font-size: 0.75rem;
          font-weight: 400;
          text-decoration: underline;
          color: ${({ theme }) => theme.font_secondary};
        }
      }
    }

    .quantityRow {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      align-self: stretch;
      gap: 1rem;

      p {
        font-size: 0.75rem;
        color: ${({ theme }) => theme.font_secondary};
        margin-right: 1rem;
      }

      .quantity {
        display: flex;
        align-items: center;
        justify-content: center;
        background: ${({ theme }) => theme.background_high};
        border-radius: 0.25rem;

        button {
          min-width: auto;
          width: 1.2rem;
          height: 1.2rem;
        }

        .number {
          color: ${({ theme }) => theme.font_low};
          padding: 0 0.5rem;
        }
      }
    }
  }
`;

export const DrawerSection = styled.li`
  display: flex;
  height: 100%;
  width: 100%;
  padding: 5rem;
  padding-top: 5rem;
  padding-left: 2rem;
  flex-direction: column;
  background-color: ${({ theme }) => theme.background_low};
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  background: transparent;
  border: none;
  color: ${({ theme }) => theme.background_high};
  font-size: 1.5rem;
`;

export const DrawerTitle = styled.h2`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.background_secondary};
  text-align: left;
  margin-bottom: 2rem;
`;

export const DrawerDivider = styled.div`
  border: ${({ theme }) => theme.background_secondary} solid 0.063rem;
  width: 100%;
  margin-top: 3rem;
  justify-self: center;
  align-self: center;
`;

export const DrawerTopicStyle = styled.li`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.background_high};
`;

export const DrawerItemStatus = styled.p`
  margin-right: 0.625rem;
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.background_secondary};
  text-decoration: underline;
  margin-right: 0.22rem;
`;

export const YamiLink = styled.div`
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.background_secondary};
`;
