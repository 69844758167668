import React, { useRef, useCallback, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles, SubmitHandler } from '@unform/core';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

// Component import
import Button from '@components/Button';
import Input from '@components/Input';
import Switch from '@components/Switch';

// Service import
import api from '@services/api';

// Style import
import { Container } from './styles';

// Interfaces
interface IProps {
  hide: () => any;
}

const PaymentCreation: React.FC<IProps> = ({ hide }) => {
  // Local states
  const [paymentSwitchEnable, setPaymentSwitchEnable] = useState(true);
  const [loading, setLoading] = useState(false);

  // Local refs
  const formPaymentCreationRef = useRef<FormHandles>(null);

  const paymentNameInputRef = useRef<HTMLInputElement>(null);

  // Get translation function
  const { t } = useTranslation();

  // Change store settings
  const paymentCreationSubmit: SubmitHandler<{
    paymentName: string;
  }> = useCallback(
    async data => {
      const { paymentName } = data;

      // Setup a schema to be valitated
      const schema = Yup.object()
        .shape({
          paymentName: Yup.string()
            .strict()
            .typeError(
              t(
                '@providers/INVALID_NAME_TYPE',
                'O tipo do nome é inválido, deveria ser alfanumérico.',
              ),
            )
            .required(t('@providers/REQUIRED_NAME', 'O nome é obrigatório.')),
        })
        .noUnknown(true, t('@stores/INVALID_REQUEST', 'Requisição inválida.'));

      try {
        await schema.validate(data, {
          abortEarly: false,
          stripUnknown: false,
        });
      } catch (err: any) {
        if (err instanceof Yup.ValidationError) {
          const validationErrors: { [key: string]: string } = {};
          const errors: string[] = [];
          err.inner.forEach(error => {
            errors.push(error.message);
            if (error.path) validationErrors[error.path] = error.message;
          });
          if (formPaymentCreationRef.current)
            formPaymentCreationRef.current.setErrors(validationErrors);
          toast.error(errors[0]);
        }

        return;
      }

      // Loading set
      setLoading(true);
      try {
        await api.post(`admin/payment-type/`, {
          name: paymentName,
          code: paymentName.toLowerCase().replace(' ', '_'),
          enabled: paymentSwitchEnable,
        });

        hide();
        setLoading(false);
      } catch (err: any) {
        // Check if server had return a error
        if (err.response?.data?.message) toast.error(err.response.data.message);
        else {
          toast.error(
            t(
              '@general/CONNECTION_FAILURE_ERROR',
              'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
            ),
          );
        }
        setLoading(false);
      }
    },
    [t, paymentSwitchEnable, hide],
  );

  return (
    <Container>
      <Form ref={formPaymentCreationRef} onSubmit={paymentCreationSubmit}>
        <div className="title">
          <h2>{t('@providers/PAYMENT_TITLE', 'Pagamento')}</h2>
          <Switch
            checked={paymentSwitchEnable}
            onChange={() => setPaymentSwitchEnable(!paymentSwitchEnable)}
          />
        </div>
        <div className="paymentName">
          <Input ref={paymentNameInputRef} name="paymentName" label="Nome" />
        </div>
        <div className="saveButton">
          <Button
            disabled={loading}
            onClick={() => {
              formPaymentCreationRef.current?.submitForm();
            }}
          >
            {t('@providers/SAVE_EDITION_BUTTON', 'Salvar')}
          </Button>
        </div>
        <div>
          <Button variant="outlined" onClick={() => hide()}>
            {t('@providers/CANCEL_EDITION_BUTTON', 'Cancelar')}
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default PaymentCreation;
