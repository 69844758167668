import React, { useContext, useCallback, useState } from 'react';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ThemeContext } from 'styled-components';

// Component import
import Button from '@components/Button';

// Service Import
import api from '@services/api';

// Hook import
import { useBrandAndPaymentSettings } from '@hooks/useBrandAndPaymentSettings';

// Style import
import { Container } from './styles';

// Interfaces
interface IProps {
  hide: () => any;
}

const StatusChangeConfirmation: React.FC<IProps> = ({ hide }) => {
  // Get context
  const currentBrandAndPayment = useBrandAndPaymentSettings();

  // Local states
  const [loading, setLoading] = useState(false);

  // Get translation function
  const { t } = useTranslation();

  // Theme context
  const themeContext = useContext(ThemeContext);

  // Enable or disable a store
  const enableDisableStore = useCallback(async () => {
    // Loading set
    setLoading(true);

    try {
      // API call
      await api.patch(
        `admin/payment-type/${currentBrandAndPayment.current_payment?.id}`,
        {
          enabled: !currentBrandAndPayment.current_payment?.enabled,
        },
      );
      setLoading(false);
    } catch (err: any) {
      // Check if server had return a error
      if (err.response?.data?.message) toast.error(err.response.data.message);
      else {
        toast.error(
          t(
            '@general/CONNECTION_FAILURE_ERROR',
            'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
          ),
        );
      }
      setLoading(false);
      throw err;
    }
    hide();
  }, [
    currentBrandAndPayment.current_payment?.enabled,
    currentBrandAndPayment.current_payment?.id,
    hide,
    t,
  ]);

  return (
    <Container>
      <div>
        <AiOutlineExclamationCircle color={themeContext.font_secondary} />
      </div>
      <p>
        {t(
          '@providers/CHANGE_PAYMENT_STATUS_QUESTION',
          'Tem certeza que deseja alterar o status de pagamento?',
        )}
      </p>
      <Button
        disabled={loading}
        onClick={() => {
          enableDisableStore();
        }}
      >
        {t('@providers/CHANGE_PAYMENT_STATUS_CONFIRMATION', 'Sim')}
      </Button>
      <span>
        <Button variant="outlined" onClick={() => hide()}>
          {t(
            '@providers/CHANGE_PAYMENT_STATUS_CANCELLATION',
            'Cancelar Alteração',
          )}
        </Button>
      </span>
    </Container>
  );
};

export default StatusChangeConfirmation;
