import React from 'react';

import { IOrder } from '@hooks/useCurrentOrder/models';

import PackageInfo from './PackagesInfo';
import PackageHistory from './PackageHistory';
import { HorizontalDivider } from './styles';

interface IModalProps {
  order: IOrder;
}

const OrderDetailPackages: React.FC<IModalProps> = ({ order }) => {
  return (
    <>
      <PackageInfo order={order} />
      <HorizontalDivider />
      <PackageHistory order={order} />
    </>
  );
};

export default OrderDetailPackages;
