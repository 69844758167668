import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .formBody {
    display: flex;
    flex-direction: column;

    .row {
      display: flex;

      .brandNameInput {
        width: 70%;
        margin-right: 0.5rem;
      }
      .brandCodeInput {
        width: 30%;
      }
      .referencePatternInput {
        width: 50%;
        margin-right: 0.5rem;
      }
      .colorPatternInput {
        width: 50%;
      }
    }
    .providerForm {
      padding: 0.5rem;

      .providerRow {
        display: flex;

        .providerComercialPoliticsInput {
          width: 50%;
          margin-right: 0.5rem;
        }
        .providerPaymentIdInput {
          width: 50%;
        }
      }

      .providerTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .saveButton {
    margin-bottom: 0.5rem;
  }
`;
