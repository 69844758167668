import React, { useContext } from 'react';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';

// Component import
import Button from '@components/Button';

// Style import
import { Container } from './styles';

// Interfaces
interface IProps {
  hide: () => any;
}

const NoMorePaymentProvidersNotice: React.FC<IProps> = ({ hide }) => {
  // Get translation function
  const { t } = useTranslation();

  // Theme context
  const themeContext = useContext(ThemeContext);

  return (
    <Container>
      <div>
        <AiOutlineExclamationCircle color={themeContext.font_secondary} />
      </div>
      <p>
        {t(
          '@stores/CHANGE_STORE_STATUS_QUESTION',
          'Não existem mais modalidades de pagamentos a serem configuradas para esta loja.',
        )}
      </p>
      <Button
        onClick={() => {
          hide();
        }}
      >
        {t('@stores/BACK_TO_STORE_EDITION_OR_CREATION', 'Voltar')}
      </Button>
    </Container>
  );
};

export default NoMorePaymentProvidersNotice;
