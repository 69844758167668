import styled from 'styled-components';

export const PackageSection = styled.div`
  display: flex;
  min-height: -webkit-fill-available;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5rem;
  align-items: center;
  width: 100%;

  .headerPosition {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .packageNameCodeStyle {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const SectionTitle = styled.h2`
  display: flex;
  font-weight: 700;
  font-size: 1.125rem;
  color: ${({ theme }) => theme.background_secondary};
`;

export const PackageSectionContent = styled.div`
  display: flex;
  font-weight: 700;
  font-size: 1.125rem;

  .packageItems {
    margin-top: 1rem;
  }

  .listOrderPackages {
    margin-top: 1rem;
    align-items: center;
    flex-direction: row;
  }

  .listOrderPackages span {
  }
`;

export const RadioStyle = styled.div`
  .MuiRadio-colorSecondary.Mui-checked {
    margin-left: 3rem;
    margin-right: 1rem;
    display: inline-block;

    color: ${({ theme }) => theme.background_secondary};
  }

  .MuiRadio-root {
    color: ${({ theme }) => theme.background_secondary};
  }
`;

export const SectionText = styled.p`
  font-weight: 700;
  color: ${({ theme }) => theme.background_secondary};

  .couponLabel {
    margin-left: 2rem !important;
  }
`;
