import styled from 'styled-components';
// #DCC6B7
export const Container = styled.div`
  margin: 0.5rem 0 0.625rem;
  display: grid;
  width: 100%;
  hr {
    margin: 1.25rem 0 0rem;
    border-bottom: ${({ theme }) => theme.background_secondary} solid 0.1rem;
  }

  .button.active {
    border: 0.125rem solid #dcc6b7;
    background-color: #dcc6b7;
    color: #212121;
  }
  .button:hover {
    border: 0.125rem solid #dcc6b7;
    background-color: #373435;
    color: #dcc6b7;
  }
  .button {
    text-transform: none;
    border: 0.125rem solid #dcc6b7;
    border-radius: 1.5625rem;
    color: #dcc6b7;
    margin: 0 0.9375rem;
    width: 100%;
    font-size: 0.875rem;
  }
`;
