import styled from 'styled-components';

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  min-height: -webkit-fill-available;

  .listPackageItems {
    width: 37rem;
  }
`;

export const Scroll = styled.div`
  margin-top: 0.125rem;
  height: 77%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: scroll;
  padding-right: 0.25rem;

  /* width */
  ::-webkit-scrollbar {
    width: 0.25rem;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: none;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.background_secondary};
    border-radius: 0.25rem;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const SectionContent = styled.div`
  height: 100%;
  padding-right: 1rem;
`;
