import { AnyAction } from 'redux';

// Type import
import { UiActionTypes } from './types';

export function setLeftMenu(open: boolean): AnyAction {
  return {
    type: UiActionTypes.LEFT_MENU_SET,
    payload: {
      open,
    },
  };
}

export function setLanguage(language: string): AnyAction {
  return {
    type: UiActionTypes.LANGUAGE_SET,
    payload: {
      language,
    },
  };
}

export function setActivePage(page: string): AnyAction {
  return {
    type: UiActionTypes.ACTIVE_PAGE_SET,
    payload: {
      page,
    },
  };
}

export function setHelpMode(mode: boolean): AnyAction {
  return {
    type: UiActionTypes.HELP_MODE_SET,
    payload: {
      mode,
    },
  };
}
