import React, { forwardRef, useLayoutEffect, useRef } from 'react';
import MaterialSwitch from '@material-ui/core/Switch';

// Style import
import { Container } from './styles';

// Interfaces
type IRef = HTMLButtonElement;

interface IProps {
  hide?: boolean;

  className?: string;

  color?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'high'
    | 'low'
    | string;

  noMargin?: boolean;

  onChange?: () => any;

  [key: string]: any;
}

const Switch = forwardRef<IRef, IProps>(
  (
    {
      hide,

      className,

      color,

      noMargin,

      onChange,

      ...rest
    },
    ref,
  ) => {
    // Local refs
    const switchRef = useRef(null);

    // Merge external ref with local ref
    useLayoutEffect(() => {
      if (ref) {
        if (typeof ref === 'function') ref(switchRef.current);
        else ref.current = switchRef.current;
      }
    }, [switchRef, ref]);

    return (
      <Container
        className={className}
        color={color}
        hide={!!hide}
        noMargin={!!noMargin}
      >
        <MaterialSwitch {...rest} ref={switchRef} onChange={onChange} />
      </Container>
    );
  },
);

Switch.defaultProps = {
  hide: false,

  color: undefined,

  noMargin: false,

  onChange: undefined,
};

export default Switch;
