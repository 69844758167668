export default function formatDateHour(dateString: string) {
  const date = new Date(dateString);

  const day = addZeroLeft(date.getDate());
  const month = addZeroLeft(date.getMonth() + 1);

  const hour = addZeroLeft(date.getHours());
  const minutes = addZeroLeft(date.getMinutes());

  const dateFormatted = `${day}/${month}`;
  const hourFormatted = `${hour}:${minutes}`;

  return { date: dateFormatted, hour: hourFormatted };
}

function addZeroLeft(value: number) {
  return value < 10 ? `0${value}` : `${value}`;
}
