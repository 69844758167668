import React from 'react';
import { NavLink } from 'react-router-dom';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { Icon } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { PageTitle } from './Style';

export const PageHeader: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const goBack = () => {
    if (history.action === 'PUSH') {
      history.goBack();
    } else {
      const params = new URLSearchParams({
        startDate: '',
        endDate: '',
        status: 'WAIT_ADJUST',
        package: '',
      });

      history.push({
        pathname: '/orders',
        search: `?${params.toString()}`,
      });
    }
  };

  return (
    <PageTitle>
      <NavLink
        to="#"
        onClick={e => {
          e.preventDefault();
          goBack();
        }}
      >
        <Icon>
          <AiOutlineArrowLeft />
        </Icon>
      </NavLink>
      <h2>{t('@orders/STORES_TABLE_TITLE', 'Pedidos')}</h2>
    </PageTitle>
  );
};
