import React, { createContext, useContext, useState } from 'react';

// Model import
import { IOrder, IOrderPayments, ICurrentOrderContextData } from './models';

// Context
export const CurrentOrderContext = createContext<ICurrentOrderContextData>(
  {} as ICurrentOrderContextData,
);

// Provider
export const CurrentOrderProvider: React.FC = ({ children }) => {
  // Local states
  const [current_order, set_current_order] = useState<IOrder>();
  const [current_payment, set_current_payment] = useState<IOrderPayments>();

  return (
    <CurrentOrderContext.Provider
      value={{
        current_order,
        set_current_order,

        current_payment,
        set_current_payment,
      }}
    >
      {children}
    </CurrentOrderContext.Provider>
  );
};

// Hook
export function useCurrentOrder(): ICurrentOrderContextData {
  const context = useContext(CurrentOrderContext);
  return context;
}
