import React, { useContext, useState } from 'react';
import { PageMessage } from '@components/PageMessage';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import InputIcon from 'react-multi-date-picker/components/input_icon';
import { useTranslation } from 'react-i18next';
import { InputBase } from '@mui/material';
import { BiSearchAlt2 } from 'react-icons/bi';
import { ThemeContext } from 'styled-components';
import { Container, SearchBar } from './styles';

interface IUrlParams {
  startDateFilter?: string;
  endDateFilter?: string;
  packageFilter?: string;
  customerDocumentFilter?: string;
  customerDocument?: string;
  statusSplitArray?: string[];
}

const FilterOrderTable: React.FC<{
  dateStart: DateObject[];
  setUrlParams: Function;
}> = ({ dateStart, setUrlParams }) => {
  const [arrayDate, setArrayDate] = useState<DateObject[]>(dateStart);
  const [searchValue, setSearchValue] = useState<string>('');
  const { t } = useTranslation();

  const themeContext = useContext(ThemeContext);

  const setPickedDate = (data: DateObject[] | null): void => {
    if (data?.length === 2) {
      setUrlParams((prevParams: IUrlParams) => ({
        ...prevParams,
        startDateFilter: data[0].toUnix().toString(),
        endDateFilter: data[1].toUnix().toString(),
      }));
      setArrayDate(data);
    }
  };

  const addFilterToUrlParams = () => {
    const cpfValue = searchValue.replace(/[.-]/g, '');
    const searchIsCPF = /^\d{11}$/.test(cpfValue);

    if (searchIsCPF) {
      setUrlParams((prevParams: IUrlParams) => ({
        ...prevParams,
        packageFilter: '',
        customerDocumentFilter: cpfValue,
      }));
      return;
    }
    setUrlParams((prevParams: IUrlParams) => ({
      ...prevParams,
      packageFilter: searchValue,
      customerDocumentFilter: '',
    }));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSearchValue(value);
  };

  const handleEnterKey = (e: any) => {
    if (e?.key === 'Enter') {
      addFilterToUrlParams();
    }
  };

  return (
    <>
      <div className="pageTitle">
        <h2>{t('@orders/STORES_TABLE_TITLE', 'Pedidos')}</h2>
      </div>
      <PageMessage alertMessage="Atenção!">
        A listagem abaixo
        <b> não inclui ajustes relativos a devoluções</b>. Para ver devoluções,
        verifique a lista fornecida pela Yamí.
      </PageMessage>
      <div className="periodo">Selecionar período:</div>
      <div className="tableActions">
        <Container>
          <DatePicker
            value={arrayDate}
            onChange={(date: DateObject[] | null) => {
              setPickedDate(date);
            }}
            format="DD/MM/YYYY"
            range
            onlyShowInRangeDates
            className="bg-dark beige date-pick"
            numberOfMonths={2}
            render={<InputIcon />}
          />
        </Container>
        <SearchBar>
          <InputBase
            placeholder={t('@orders/FILTER_SUBTITLE', 'por Pacote ou CPF')}
            classes={{ input: 'inputInput' }}
            inputProps={{ 'aria-label': 'search' }}
            onKeyUp={(e: any) => handleEnterKey(e)}
            onChange={handleChange}
            value={searchValue}
          />
          <button type="button" onClick={() => addFilterToUrlParams()}>
            <BiSearchAlt2 color={themeContext.font_low} />
          </button>
        </SearchBar>
      </div>
    </>
  );
};

export default FilterOrderTable;
