import React from 'react';
import { useSelector } from 'react-redux';

// Module import
import Header from '@modules/Header';
import Sidebar from '@modules/Sidebar';

// Style import
import { Container, Main, Content } from './styles';

const DefaultLayout: React.FC = ({ children }) => {
  // Global states
  const leftMenu = useSelector(state => state.ui.left_menu);

  return (
    <Container>
      <Header />
      <Main>
        <Sidebar />
        <Content
          leftMenu={leftMenu}
          mobileSize={
            Math.max(
              document.documentElement.clientWidth,
              window.innerWidth || 0,
            ) <= 768
          }
        >
          {children}
        </Content>
      </Main>
    </Container>
  );
};

export default DefaultLayout;
