import {
  IOrder,
  IOrderPaymentsCharges,
  IOrderPaymentsPaymentType,
} from '@hooks/useCurrentOrder/models';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import findValidCharge from '@util/findValidCharge';
import React, { useCallback, useEffect, useState } from 'react';
import { RadioStyle } from './styles';

const ChargesOptions: React.FC<{
  order?: IOrder;
  onSelectedChargeChange: any;
}> = ({ order, onSelectedChargeChange }) => {
  const [value] = useState<string>('option_0');
  const [charges, setCharges] = useState<
    { charge: IOrderPaymentsCharges; type: string; name: string }[]
  >([]);

  const getPaymentType = (paymentType: IOrderPaymentsPaymentType): string => {
    return paymentType.name;
  };

  const getChargesMaped = useCallback(() => {
    const mycharges: {
      charge: IOrderPaymentsCharges;
      type: string;
      name: string;
    }[] = [];
    order?.order.payments.forEach(payment => {
      payment.charges?.forEach(charge => {
        mycharges.push({
          charge,
          type: payment.payment_type.code,
          name: payment.payment_type.name,
        });
      });
    });
    setCharges(mycharges);
  }, [order, setCharges]);

  useEffect(() => {
    getChargesMaped();
  }, [getChargesMaped]);

  const handleRadioChange =
    (charge: IOrderPaymentsCharges, chargeIndex: number, paymentType: string) =>
    () => {
      onSelectedChargeChange({ charge, chargeIndex, paymentType });
    };

  const orderWithCharges = findValidCharge(order);

  if (!orderWithCharges)
    return (
      <>
        <h1> Não há cobranças associadas a esse pedido. </h1>
      </>
    );

  return (
    <>
      <div className="sectionPanelTitle">
        <div className="sectionSubContentVertical">
          <div className="sectionSubContentTitle">
            <FormControl>
              <RadioStyle>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={value}
                  name="radio-buttons-group"
                >
                  {charges.map((charge, chargeIndex) => (
                    <FormControlLabel
                      key={charge.charge.code}
                      onChange={handleRadioChange(
                        charge.charge,
                        chargeIndex + 1,
                        getPaymentType(orderWithCharges.payment_type),
                      )}
                      value={`option_${chargeIndex}`}
                      control={<Radio />}
                      label={
                        <div>
                          <div className="chargeTitle">
                            {`Cobrança ${chargeIndex + 1}: `}
                            <p className="chargeType">{`${charge.name}`}</p>
                          </div>
                        </div>
                      }
                    />
                  ))}
                </RadioGroup>
              </RadioStyle>
            </FormControl>
          </div>
        </div>
      </div>
      <div className="divider" />
      <div />
      <div />
    </>
  );
};

export default ChargesOptions;
