import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  .titleRow {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      font-weight: 700;
      font-size: 1.25rem;
    }

    .wideField {
      width: 50%;
      margin-right: 0.75rem;
    }

    .tightField {
      width: 25%;
      .lastTightField {
        margin-left: 0.75rem;
      }
    }
  }

  .loading {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .editionArea {
    padding: 0.625rem 0 0.625rem 0;
    display: flex;
    flex-direction: column;

    .closedTab {
      display: grid;
      grid-template-columns: 1fr 8fr 10fr;
      .rightArrow {
        button {
          font-size: 1.5rem;
          color: ${({ theme }) => theme.button_high};
        }
      }
      .closedTitle {
        font-weight: 600;
        font-size: 1rem;
      }
      .pill {
        display: flex;
        justify-content: flex-end;
      }
    }

    .openTab {
      display: flex;
      flex-direction: column;
      margin-bottom: 0.75rem;

      .title {
        display: grid;
        grid-template-columns: 1fr 18fr;
        .downArrow {
          button {
            font-size: 1.5rem;
            color: ${({ theme }) => theme.button_high};
          }
        }
        .text {
          font-weight: 600;
          font-size: 1rem;
          width: 40%;
        }
      }
      .content {
        display: flex;
        flex-direction: column;
        .contentNameRow {
          display: grid;
          grid-template-columns: 0.7fr 17.3fr 1fr;
          font-size: 1rem;
          .contentNameRowButton {
            color: ${({ theme }) => theme.button_primary};
            font-size: x-large;
          }
          .contentName {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            p {
              margin-left: 1rem;
            }

            .toDelete {
              text-decoration: line-through;
            }
          }
        }

        .contentNameRowPayment {
          display: grid;
          grid-template-columns: 0.7fr 18.3fr;
          font-size: 1rem;
          .openProvider {
            display: flex;
            flex-direction: column;
            width: 100%;
            /* align-items: flex-start; */
            .openProviderRow {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .openProviderRowButton {
                color: ${({ theme }) => theme.button_primary};
                font-size: x-large;
              }

              .openProviderTitle {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                p {
                  margin-left: 1rem;
                }

                .toDelete {
                  text-decoration: line-through;
                }
              }
            }
            .saveButton {
              margin-bottom: 1rem;
            }
          }
        }
      }
      .addProviderArea {
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        /* align-items: center; */
        margin-left: 10%;
        font-size: 1rem;

        .addProviderAreaTitle {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .addProviderButton {
            color: ${({ theme }) => theme.button_primary};
            font-size: x-large;
          }

          .titleAndSwitch {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            p {
              margin-left: 1rem;
            }
          }
        }

        .saveButton {
          margin-bottom: 1rem;
        }
      }
      .providerButton {
        display: flex;
        justify-content: center;
        margin-top: 0.75rem;
        margin-bottom: 0.75rem;
        button {
          font-weight: 600;
          font-size: 1rem;
          color: ${({ theme }) => theme.button_primary};
        }
      }

      .addButtonSection {
        display: flex;
        justify-content: center;
        margin-top: 0.75rem;
        margin-bottom: 0.75rem;
        .addButton {
          font-weight: 600;
          font-size: 1rem;
          color: ${({ theme }) => theme.button_primary};
        }
        .selectAndSaveButtonSection {
          width: 100%;
        }
      }
    }
  }

  .firstRow {
    display: flex;
    align-items: center;

    div {
      padding-right: 0.2rem;
    }
  }

  .regularRow {
    margin-bottom: 0.75rem;
  }
`;

export const Pill = styled.p`
  background-color: ${props => props.color};
  padding: 0.25rem 0.625rem;
  border-radius: 1rem;
  margin: 0.25rem;
  width: fit-content;
`;
