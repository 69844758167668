import styled from 'styled-components';

export const StatusLabel = styled.p`
  color: ${props => props.color};
  border: 0.15rem solid;
  padding: 0.25rem 0.625rem;
  border-radius: 1rem;
  margin: 0.25rem;
  width: fit-content;
`;
