import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import Login from '@pages/Login';
import Orders from '@pages/Orders';
import Providers from '@pages/Providers';
import Dashboard from '@pages/Dashboard';
import ErrorLog from '@pages/ErrorLog';
import Stores from '@pages/Stores';
import OrderDetail from '@pages/OrderDetail';
import Route from './Route';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={Login} />
    <Route path="/dashboard" isPrivate exact component={Dashboard} />
    <Route path="/orders" isPrivate exact component={Orders} />
    <Route
      path="/orders/:uuid/details"
      isPrivate
      exact
      component={OrderDetail}
    />
    <Route path="/stores" isPrivate exact component={Stores} />
    <Route path="/providers" isPrivate exact component={Providers} />
    <Route path="/logs" isPrivate exact component={ErrorLog} />
    <Redirect to="/" />
  </Switch>
);

export default Routes;
