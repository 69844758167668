import styled from 'styled-components';

export const PageTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1rem;
  align-items: flex-start;

  h2 {
    margin-left: 0.5rem;
  }
`;
