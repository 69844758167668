import { IOrder } from '@hooks/useCurrentOrder/models';
import api from '@services/api';
import { IStore } from '@store/modules/stores/types';
import { IPaginatedResponse } from 'interfaces/IPaginatedResponse';

interface IGetAllOrdersParams {
  pageSize?: number;
  page: number;
  sort_created_at?: string;
  sort_updated_at?: string;
  sort_total?: string;
  sort_customer_document?: string;
  filter_start_date?: string;
  filter_end_date?: string;
  filter_order_status?: string;
  filter_package_code?: string;
  filter_customer_document: string;
}

interface IGetAllStoresParams {
  page: number;
  filter_code: string;
  filter_name: string;
}

export default class SomaStoreApiGateway {
  static async getById(uuid: string): Promise<IOrder> {
    const response = await api.get<IOrder>(`admin/order/${uuid}`);
    return response.data;
  }

  static async changeStatus(uuid: string, messageValue: string): Promise<void> {
    await api.patch(`admin/v1/order/${uuid}`, {
      status: 'ADJUSTED',
      message: messageValue,
    });
  }

  static async getStatusById(id: string): Promise<any> {
    const response = await api.get<any>(
      `admin/v1/order/${id}/status?status_from=WAIT_ADJUST&status_to=ADJUSTED`,
    );
    return response.data;
  }

  static async getAllOrders(
    params: IGetAllOrdersParams,
  ): Promise<IPaginatedResponse<IOrder>> {
    if (!params.filter_package_code) {
      params.filter_package_code = undefined;
    }
    const response = await api.get<IPaginatedResponse<IOrder>>(
      `admin/v1/order`,
      {
        params,
      },
    );
    return response.data;
  }

  static async getAllStores(
    params: IGetAllStoresParams,
  ): Promise<IPaginatedResponse<IStore>> {
    const response = await api.get<IPaginatedResponse<IStore>>(
      `admin/v1/store`,
      {
        params,
      },
    );
    return response.data;
  }
}
