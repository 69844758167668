export default function formatCardBrand(brand: string): string {
  const lowerBrand = brand.toLowerCase();
  const brandMap: { [key: string]: string } = {
    mastercard: 'Mastercard',
    visa: 'Visa',
    elo: 'Elo',
    hipercard: 'Hipercard',
    amex: 'Amex',
  };

  return brandMap[lowerBrand] || brand;
}
