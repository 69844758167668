import produce from 'immer';
import { Reducer } from 'redux';

// Type import
import { IUiState, UiActionTypes } from './types';
import { AuthActionTypes } from '../auth/types';

const INITIAL_STATE: IUiState = {
  left_menu: !(
    Math.max(document.documentElement.clientWidth, window.innerWidth || 0) <=
    768
  ),
  active_page: '',
  help_mode: false,
  language: undefined,
};

const ui: Reducer<IUiState> = (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case UiActionTypes.LEFT_MENU_SET:
        draft.left_menu = action.payload.open;
        break;

      case UiActionTypes.LANGUAGE_SET:
        draft.language = action.payload.language;
        break;

      case UiActionTypes.ACTIVE_PAGE_SET:
        draft.active_page = action.payload.page;
        break;

      case UiActionTypes.HELP_MODE_SET:
        draft.help_mode = action.payload.mode;
        break;

      // Clear UI when session changes
      case AuthActionTypes.SIGN_FAILURE:
      case AuthActionTypes.SIGN_OUT:
        draft.left_menu = true;
        draft.help_mode = false;
        break;

      default:
    }
  });
};

export default ui;
