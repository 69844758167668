import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { BiPencil, BiSearchAlt2 } from 'react-icons/bi';
import { MdAdd } from 'react-icons/md';
import InputBase from '@material-ui/core/InputBase';
import { ThemeContext } from 'styled-components';

// Util import
import setActivePage from '@util/setActivePage';

// Component import
import Modal, { IModalRef } from '@components/Modal';
import Button from '@components/Button';
import Switch from '@components/Switch';
import Loading from '@components/Loading';

// Hook import
import { useCurrentStore, CurrentStoreProvider } from '@hooks/useCurrentStore';

// Service Import
import api from '@services/api';

// Type import
import { IStore } from '@store/modules/stores/types';

// Page import
// import { current } from 'immer';
import { useHistory, useLocation } from 'react-router';
import SomaStoreApiGateway from 'gateways/SomaStoreApiGateway';
import PaginationMenu from '@components/PaginationMenu';
import { IPaginatedResponse } from 'interfaces/IPaginatedResponse';

import StoreCreation from './StoreCreation';
import StoreEdition from './StoreEdition';
import StatusChangeConfirmation from './StatusChangeConfirmation';

// Style import
import { Container, StoresPanel, StoresTable, SearchBar, Pill } from './styles';

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const getDefaultPage = (query: URLSearchParams) => {
  const pageQuery = query.get('page');
  let pageDefault = 1;
  if (pageQuery !== '' && Number(pageQuery) >= 1) {
    pageDefault = Number(pageQuery);
  }
  return pageDefault;
};

const Content: React.FC = () => {
  const query = useQuery();
  const history = useHistory();

  // Local states
  const [loading, setLoading] = useState(false);
  const [storeCodeFilter, setStoreCodeFilter] = useState<string>(
    query.get('filterCode') ?? '',
  );
  const [storeNameFilter, setStoreNameFilter] = useState<string>(
    query.get('filterName') ?? '',
  );
  const [pageNumber, setPageNumber] = useState<number>(getDefaultPage(query));

  // Get context
  const currentStore = useCurrentStore();

  // Local refs
  const storeEditionModalRef = useRef<IModalRef>(null);
  const storeCreationModalRef = useRef<IModalRef>(null);
  const statusChangeConfirmationModalRef = useRef<IModalRef>(null);

  // Get translation function
  const { t } = useTranslation();

  // Theme context
  const themeContext = useContext(ThemeContext);
  const timeoutRefStoreCode = useRef<number>(0);
  const timeoutRefStoreName = useRef<number>(0);
  const [storeCode] = useState(storeCodeFilter);
  const [storeName] = useState(storeNameFilter);
  const [stores, setStores] = useState<IPaginatedResponse<IStore> | undefined>(
    undefined,
  );

  useEffect(() => {
    if (loading) return;
    const params: Record<'filterCode' | 'nameFilter' | 'page', string> = {
      filterCode: storeCodeFilter ?? '',
      nameFilter: storeNameFilter ?? '',
      page: `${pageNumber}`,
    };
    history.push({
      pathname: '/stores',
      search: `?${new URLSearchParams(params).toString()}`,
    });
  }, [loading, storeCodeFilter, storeNameFilter, pageNumber, history]);

  // Initialized
  useEffect(() => {
    // Set page
    setActivePage('@stores');
  }, []);

  useEffect(() => {
    window.clearTimeout(timeoutRefStoreCode.current);
    timeoutRefStoreCode.current = window.setTimeout(() => {
      setStoreCodeFilter(storeCode);
    }, 1000);
  }, [storeCode]);

  useEffect(() => {
    window.clearTimeout(timeoutRefStoreName.current);
    timeoutRefStoreName.current = window.setTimeout(() => {
      setStoreNameFilter(storeName);
    }, 1000);
  }, [storeName]);

  // Get orders
  const getStores = useCallback(async () => {
    // Loading set
    setLoading(true);

    try {
      const data = await SomaStoreApiGateway.getAllStores({
        page: pageNumber,
        filter_code: storeCodeFilter ?? null,
        filter_name: storeNameFilter ?? null,
      });
      setStores(data);
      setPageNumber(data.metadata.page);
      setLoading(false);
    } catch (err: any) {
      // Check if server had return a error
      if (err.response?.data?.message) toast.error(err.response.data.message);
      else {
        toast.error(
          t(
            '@general/CONNECTION_FAILURE_ERROR',
            'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
          ),
        );
      }

      // Loading set
      setLoading(false);

      throw err;
    }
  }, [t, pageNumber, storeCodeFilter, storeNameFilter]);

  // Initial load
  useEffect(() => {
    getStores();
  }, [getStores]);

  // Get brand options
  const getBrandsOptions = useCallback(async () => {
    // Loading set
    setLoading(true);

    try {
      // API call
      const response = await api.get('admin/brand', {});

      currentStore.set_possible_brands(response.data);

      setLoading(false);
    } catch (err: any) {
      // Check if server had return a error
      if (err.response?.data?.message) toast.error(err.response.data.message);
      else {
        toast.error(
          t(
            '@general/CONNECTION_FAILURE_ERROR',
            'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
          ),
        );
      }

      // Loading set
      setLoading(false);

      throw err;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  // Get payment type options
  const getPaymentTypesOptions = useCallback(async () => {
    // Loading set
    setLoading(true);

    try {
      // API call
      const response = await api.get('admin/payment-type', {});

      currentStore.set_possible_payment_types(response.data);

      setLoading(false);
    } catch (err: any) {
      // Check if server had return a error
      if (err.response?.data?.message) toast.error(err.response.data.message);
      else {
        toast.error(
          t(
            '@general/CONNECTION_FAILURE_ERROR',
            'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
          ),
        );
      }

      // Loading set
      setLoading(false);

      throw err;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  // Get payment providers options
  const getPaymentProvidersOptions = useCallback(async () => {
    // Loading set
    setLoading(true);

    try {
      // API call
      const response = await api.get('admin/payment-provider', {});

      currentStore.set_possible_payment_providers(response.data);

      setLoading(false);
    } catch (err: any) {
      // Check if server had return a error
      if (err.response?.data?.message) toast.error(err.response.data.message);
      else {
        toast.error(
          t(
            '@general/CONNECTION_FAILURE_ERROR',
            'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
          ),
        );
      }

      // Loading set
      setLoading(false);

      throw err;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  // Get package type options
  const getPackageTypesOptions = useCallback(async () => {
    // Loading set
    setLoading(true);

    try {
      // API call
      const response = await api.get('admin/package-type', {});

      currentStore.set_possible_package_types(response.data);

      setLoading(false);
    } catch (err: any) {
      // Check if server had return a error
      if (err.response?.data?.message) toast.error(err.response.data.message);
      else {
        toast.error(
          t(
            '@general/CONNECTION_FAILURE_ERROR',
            'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
          ),
        );
      }

      // Loading set
      setLoading(false);

      throw err;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  // Initial load
  useEffect(() => {
    getBrandsOptions();
    getPaymentTypesOptions();
    getPaymentProvidersOptions();
    getPackageTypesOptions();
  }, [
    getBrandsOptions,
    getPackageTypesOptions,
    getPaymentProvidersOptions,
    getPaymentTypesOptions,
  ]);

  // Modals
  const modals = useMemo(
    () => (
      <>
        <Modal ref={storeEditionModalRef} size="small" onHide={getStores}>
          <StoreEdition
            hide={() => {
              storeEditionModalRef.current?.hide();
              getStores();
            }}
          />
        </Modal>
        <Modal ref={storeCreationModalRef} size="small">
          <StoreCreation
            hide={() => {
              storeCreationModalRef.current?.hide();
              storeEditionModalRef.current?.show();
            }}
          />
        </Modal>
        <Modal
          ref={statusChangeConfirmationModalRef}
          size="small"
          onHide={getStores}
        >
          <StatusChangeConfirmation
            hide={() => {
              statusChangeConfirmationModalRef.current?.hide();
              getStores();
            }}
          />
        </Modal>
      </>
    ),
    [getStores],
  );

  const timerRef = useRef<number | null>(null);

  const handleSearchChange = useCallback(
    event => {
      const inputValue = event.target.value;

      const handleSearch = value => {
        if (/^\d+$/.test(value)) {
          setStoreNameFilter('');
          setStoreCodeFilter(value);
        } else {
          setStoreCodeFilter('');
          setStoreNameFilter(value);
        }
      };

      if (event.key === 'Enter') {
        handleSearch(inputValue);
      } else {
        if (timerRef.current !== null) {
          clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(() => {
          handleSearch(inputValue);
        }, 3000) as any;
      }
    },
    [setStoreNameFilter, setStoreCodeFilter],
  );

  return (
    <Container>
      <StoresPanel>
        <h2>{t('@stores/STORES_TABLE_TITLE', 'Lojas')}</h2>
        <>
          <div className="tableActions">
            <SearchBar>
              <div>
                <BiSearchAlt2 color={themeContext.font_low} />
              </div>
              <InputBase
                placeholder={t(
                  '@stores/FILTER_SUBTITLE',
                  'Código Filial ou nome',
                )}
                classes={{
                  input: 'inputInput',
                }}
                inputProps={{ 'aria-label': 'search' }}
                onChange={handleSearchChange}
                onKeyDown={handleSearchChange}
              />
            </SearchBar>
            <Button
              noFullWidth
              startIcon={<MdAdd color={themeContext.font_low} />}
              onClick={() => {
                currentStore.set_selected_brand([]);
                currentStore.set_selected_payment_type([]);
                currentStore.set_selected_payment_provider([]);
                currentStore.set_selected_package_type([]);
                storeCreationModalRef.current?.show();
              }}
            >
              {t('@stores/REGISTER_STORE', 'Cadastrar Loja')}
            </Button>
          </div>

          <StoresTable>
            <div className="header">
              <p>{t('@stores/STATUS_COLUMN', 'Status')}</p>
              <p>{t('@stores/NAME_COLUMN', 'Nome')}</p>
              <p>{t('@stores/FILIAL_CODE_COLUMN', 'Código Filial')}</p>
              <p>{t('@stores/BRANDS_COLUMN', 'Marcas')}</p>
              <p>{t('@stores/PACKAGES_COLUMN', 'Modalidades')}</p>
              <p>{t('@stores/PAYMENTS_COLUMN', 'Pagamentos')}</p>
            </div>
            {loading ? (
              <div className="loading">
                <Loading size={3} />
              </div>
            ) : (
              <>
                {stores?.data?.map(store => (
                  <div className="rowTable">
                    <div>
                      <Switch
                        checked={store.enabled}
                        onChange={() => {
                          currentStore.set_current_store(store);
                          statusChangeConfirmationModalRef.current?.show();
                        }}
                      />
                    </div>
                    <div>{store.name}</div>
                    <div>{store.code}</div>
                    <div>
                      {store.brands.map(item => (
                        <Pill
                          color={
                            item.enabled
                              ? themeContext.button_success
                              : themeContext.button_secondary
                          }
                        >
                          {item.brand.name}
                        </Pill>
                      ))}
                    </div>
                    <div>
                      {store.package_types.map(item => (
                        <Pill
                          color={
                            item.enabled
                              ? themeContext.button_success
                              : themeContext.button_secondary
                          }
                        >
                          {item.package_type.name}
                        </Pill>
                      ))}
                    </div>
                    <div>
                      {store.payment_providers.map(item => (
                        <Pill
                          color={
                            item.enabled
                              ? themeContext.button_success
                              : themeContext.button_secondary
                          }
                        >
                          {item.provider.name}
                        </Pill>
                      ))}
                    </div>
                    <button
                      type="button"
                      onClick={() => {
                        currentStore.set_selected_brand(
                          store.brands.map(brand => brand.brand_id),
                        );
                        currentStore.set_selected_payment_type(
                          store.payment_types.map(
                            payment_type => payment_type.payment_type_id,
                          ),
                        );
                        currentStore.set_selected_payment_provider(
                          store.payment_providers.map(
                            payment_provider =>
                              payment_provider.payment_provider_id,
                          ),
                        );
                        currentStore.set_selected_package_type(
                          store.package_types.map(
                            package_type => package_type.package_type_id,
                          ),
                        );
                        currentStore.set_current_store(store);
                        storeEditionModalRef.current?.show();
                      }}
                    >
                      <BiPencil color={themeContext.font_secondary} />
                    </button>
                  </div>
                ))}
                {modals}
              </>
            )}
          </StoresTable>
          {stores ? (
            <PaginationMenu
              metadata={stores.metadata}
              pageNumber={stores.metadata.page}
              setPageNumber={setPageNumber}
            />
          ) : (
            <></>
          )}
        </>
      </StoresPanel>
    </Container>
  );
};

// Consumer
const Stores: React.FC = () => {
  return (
    <CurrentStoreProvider>
      <Content />
    </CurrentStoreProvider>
  );
};

export default Stores;
