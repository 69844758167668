import React from 'react';

import 'react-multi-date-picker/styles/backgrounds/bg-dark.css';

import { IOrder } from '@hooks/useCurrentOrder/models';

import { DetailsPanel } from './styles';

import ChangeStatus from './Status';
import ChargesPanel from './Charges';
import UpdatedPayment from './Payment';

interface IModalProps {
  order: IOrder;
}

const OrderDetailsPayment: React.FC<IModalProps> = ({ order }) => {
  return (
    <DetailsPanel>
      <div className="buttonsNav">
        <ChangeStatus order={order.order} />
        <UpdatedPayment order={order} />
        <div className="panel">
          <ChargesPanel order={order} />
        </div>
      </div>
    </DetailsPanel>
  );
};

export default OrderDetailsPayment;
