import { transparentize } from 'polished';
import { DefaultTheme } from 'styled-components';

export default function getColor({
  theme,
  color,
  error,
  disabled,
  readOnly,
}: {
  theme: DefaultTheme;
  color?: string;
  error?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
}) {
  if (error) return theme.button_danger;

  let result: string;

  switch (color) {
    case 'primary':
      result = theme.button_primary;
      break;

    case 'secondary':
      result = theme.button_secondary;
      break;

    case 'success':
      result = theme.button_success;
      break;

    case 'danger':
      result = theme.button_danger;
      break;

    case 'warning':
      result = theme.button_warning;
      break;

    case 'info':
      result = theme.button_info;
      break;

    case 'high':
      result = theme.button_high;
      break;

    case 'low':
      result = theme.button_low;
      break;

    default:
      result = color || theme.button_primary;
      break;
  }

  if (disabled || readOnly) return transparentize(0.5, result);

  return result;
}
