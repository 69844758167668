import { TableHead } from '@mui/material';
import styled from 'styled-components';

export const TableHeadStyled = styled(TableHead)`
  background-color: ${({ theme }) => theme.background_secondary};

  .MuiTableCell-root {
    background-color: ${({ theme }) => theme.background_secondary};
    padding: 0.5rem 0.7rem;
    font-weight: 700;
    font-size: 0.875rem;
    border: none;

    .MuiGrid-root {
      display: flex;
    }

    .MuiGrid-root .MuiGrid-item {
      display: flex;
      align-items: center;
    }

    button {
      font-size: 1rem;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .creationDate {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .fitColumn {
    width: 0;
    .fitContent {
      display: flex;
      gap: 1rem;
      justify-content: space-between;
      align-items: center;
      width: max-content;
    }
  }
`;
