import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { Locale, getDate, parseJSON, format } from 'date-fns';
import ptBR from 'date-fns/esm/locale/pt-BR/index.js';
import { useTranslation } from 'react-i18next';
import Checkbox from '@material-ui/core/Checkbox';
import {
  MdKeyboardArrowRight,
  MdKeyboardArrowLeft,
  MdKeyboardArrowUp,
  MdKeyboardArrowDown,
  MdOutlineFilterAlt,
  MdOutlineFilterList,
  MdOutlineCheckBoxOutlineBlank,
  MdOutlineCheckBox,
  MdCheckCircleOutline,
} from 'react-icons/md';
import { RiErrorWarningFill } from 'react-icons/ri';
import { ThemeContext } from 'styled-components';

// Asset impoort
import { ReactComponent as CalendarIcon } from '@assets/calendar.svg';

// Util import
import setActivePage from '@util/setActivePage';

// Component import
import Modal, { IModalRef } from '@components/Modal';

// Hook import
import { useCurrentError, CurrentErrorProvider } from '@hooks/useCurrentError';

// Service Import
import api from '@services/api';

// Type import
import { IError } from '@hooks/useCurrentError/models';

// Modal import
import StatusChangeConfirmationModal from './StatusChangeConfirmationModal';

import ErrorLogDetails from './ErrorLogDetails';

// Style import
import {
  Container,
  LogsPanel,
  LogsContent,
  LogsFooter,
  CategoriesSection,
  CategoryCard,
  LogsList,
  NotCompletedNumber,
  LogsListComponent,
  StatusLabel,
  // SearchBar,
} from './styles';

// Types
interface ICategory {
  action: string;
  completed: number;
  notCompleted: number;
  total: number;
}

interface IStatusErrorFilterOptions {
  not_completed: boolean;
  completed: boolean;
  showAll: boolean;
}

const Content: React.FC = () => {
  // Local states
  const [dateLanguagePack, setDateLanguagePack] = useState<Locale>(ptBR);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<IError[]>([]);
  const [formattedErrors, setFormattedErrors] = useState<IError[]>([]);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [filteredCategories, setFilteredCategories] = useState<ICategory[]>([]);
  const [details, setDetails] = useState(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [selectedCategory, setSelectedCategory] = useState<ICategory | null>();
  const [categoryFilter, setCategoryFilter] = useState('');
  const [statusErrorFilterMenu, setStatusErrorFilterMenu] = useState(false);
  const [statusCategoryFilterMenu, setStatusCategoryFilterMenu] =
    useState(false);
  const [statusFilterChecked, setStatusFilterChecked] =
    useState<IStatusErrorFilterOptions>({
      not_completed: false,
      completed: false,
      showAll: true,
    });
  const [statusFilter, setStatusFilter] = useState('');
  const [selectedStatusCategoryFilter, setSelectedStatusCategoryFilter] =
    useState<'all' | 'only_not_completed' | 'only_completed'>('all');
  const [markedMenu, setMarkedMenu] = useState(false);
  const [markAction, setMarkAction] = useState<
    'none' | 'mark_as_not_completed' | 'mark_as_completed'
  >('none');
  // Add this block when api is enabled to order categories
  // const [selectedCategoryOrderMenu, setSelectedCategoryOrderMenu] = useState<
  //   'recent' | 'old' | 'more_not_completed' | 'more_completed'
  // >('recent');
  // const [categoryOrderMenu, setCategoryOrderMenu] = useState(false);

  // Local refs
  const statusChangeConfirmationModalRef = useRef<IModalRef>(null);

  // Global states
  const language = useSelector(state => state.ui.language);

  // Get translation function
  const { t } = useTranslation();

  // Theme context
  const themeContext = useContext(ThemeContext);

  // Get context
  const currentError = useCurrentError();

  // Initialized
  useEffect(() => {
    // Set page
    setActivePage('@logs');
  }, []);

  // Get language module
  useEffect(() => {
    const languageSet = language;
    import(`date-fns/locale/${languageSet}`)
      .then(res => setDateLanguagePack(res.default as Locale))
      .catch(() => {
        setDateLanguagePack(ptBR);
      });
  }, [language]);

  // Get errors
  const getErrors = useCallback(async () => {
    // Loading set
    setLoading(true);

    try {
      // API call
      const response = await api.get(
        `admin/errors?page=${pageNumber}${categoryFilter}${statusFilter}`,
        {},
      );

      setErrors(response.data);

      setLoading(false);
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.log(err);

      // Loading set
      setLoading(false);

      throw err;
    }
  }, [categoryFilter, pageNumber, statusFilter]);

  // Get categories
  const getCategories = useCallback(async () => {
    // Loading set
    setLoading(true);

    try {
      // API call
      const response = await api.get('admin/errors/categories', {});
      setCategories(response.data);

      setLoading(false);
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.log(err);

      // Loading set
      setLoading(false);

      throw err;
    }
  }, []);

  useEffect(() => {
    const notCompletedCategories = categories.filter(category => {
      return category.notCompleted > 0;
    });
    const completedCategories = categories.filter(category => {
      return category.notCompleted === 0;
    });
    switch (selectedStatusCategoryFilter) {
      case 'all':
        setFilteredCategories(categories);
        break;
      case 'only_not_completed':
        setFilteredCategories(notCompletedCategories);
        break;
      case 'only_completed':
        setFilteredCategories(completedCategories);
        break;
      default:
        setFilteredCategories(categories);
        break;
    }
  }, [categories, selectedStatusCategoryFilter]);

  useEffect(() => {
    if (selectedCategory) {
      setCategoryFilter(`&category=${selectedCategory.action}`);
    } else {
      setCategoryFilter('');
    }
  }, [selectedCategory]);

  // Initial load
  useEffect(() => {
    getErrors();
  }, [getErrors]);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  const getNotCompletedNumberColor = (notCompleted: number, total: number) => {
    const ratio = notCompleted / total;

    if (ratio > 0.5) {
      return themeContext.font_danger;
    }
    if (ratio > 0.25) {
      return '#F2994A';
    }
    if (ratio > 0) {
      return themeContext.font_warning;
    }
    return themeContext.font_success;
  };

  const handleChangeStatusNotCompleted = () => {
    if (
      statusFilterChecked.not_completed === true &&
      statusFilterChecked.completed === false
    ) {
      setStatusFilterChecked({
        not_completed: false,
        completed: false,
        showAll: true,
      });
    } else {
      setStatusFilterChecked({
        not_completed: !statusFilterChecked.not_completed,
        completed: false,
        showAll: false,
      });
    }
  };

  const handleChangeStatusCompleted = () => {
    if (
      statusFilterChecked.not_completed === false &&
      statusFilterChecked.completed === true
    ) {
      setStatusFilterChecked({
        not_completed: false,
        completed: false,
        showAll: true,
      });
    } else {
      setStatusFilterChecked({
        not_completed: false,
        completed: !statusFilterChecked.completed,
        showAll: false,
      });
    }
  };

  const handleChangeStatusShowAll = () => {
    setStatusFilterChecked({
      not_completed: false,
      completed: false,
      showAll: true,
    });
  };

  useEffect(() => {
    if (statusFilterChecked.not_completed === true) {
      setStatusFilter('&completed=false');
    } else if (statusFilterChecked.completed === true) {
      setStatusFilter('&completed=true');
    } else {
      setStatusFilter('');
    }
  }, [statusFilterChecked.completed, statusFilterChecked.not_completed]);

  const getSelectedStatusCategoryFilterName = () => {
    switch (selectedStatusCategoryFilter) {
      case 'all':
        return <>{t('@logs/ALL', 'Todos')}</>;
      case 'only_not_completed':
        return <>{t('@logs/ONLY_NOT_COMPLETED', 'Apenas em aberto')}</>;
      case 'only_completed':
        return <>{t('@logs/ONLY_COMPLETED', 'Apenas resolvidos')} </>;
      default:
        return <> </>;
    }
  };

  useEffect(() => {
    const errorsCopy = errors.slice();
    const objectToMarkFalse = {
      marked: false,
    };
    errorsCopy.forEach(error => Object.assign(error, objectToMarkFalse));
    setFormattedErrors(errorsCopy);
  }, [errors]);

  // Function used in the sort filter that is disabled
  // const getSelectedCategoryOrderMenu = () => {
  //   switch (selectedCategoryOrderMenu) {
  //     case 'recent':
  //       return <>{t('@logs/RECENT', 'Mais recentes')}</>;
  //     case 'old':
  //       return <>{t('@logs/OLD', 'Mais antigos')}</>;
  //     case 'more_not_completed':
  //       return <>{t('@logs/MORE_NOT_COMPLETED', 'Mais abertos')}</>;
  //     case 'more_completed':
  //       return <>{t('@logs/MORE_COMPLETED', 'Mais resolvidos')}</>;
  //     default:
  //       return <> </>;
  //   }
  // };

  const handleChangeMark = (event: React.ChangeEvent<HTMLInputElement>) => {
    const errorsCopy = formattedErrors.slice();

    errorsCopy.forEach(error => {
      // eslint-disable-next-line no-underscore-dangle
      if (error._id === event.target.name) {
        error.marked = !error.marked;
      }
    });
    setFormattedErrors(errorsCopy);
    setMarkedMenu(false);
  };

  const anyMarked = formattedErrors.find(error => error.marked === true);

  // Modals
  const modals = useMemo(
    () => (
      <>
        <Modal
          ref={statusChangeConfirmationModalRef}
          size="smaller"
          onHide={getCategories}
        >
          <StatusChangeConfirmationModal
            hide={() => {
              statusChangeConfirmationModalRef.current?.hide();
              getCategories();
              getErrors();
            }}
            formattedErrors={formattedErrors}
            markAction={markAction}
            setMarkAction={setMarkAction}
          />
        </Modal>
      </>
    ),
    [formattedErrors, getCategories, getErrors, markAction],
  );

  return (
    <>
      {details === false ? (
        <Container>
          <LogsPanel>
            <LogsContent>
              <div className="titleRow">
                <p>{t('@logs/PAGE_TITLE', 'Log de Erros')}</p>
              </div>
              <CategoriesSection>
                <div className="titleAndActionsRow">
                  <div className="title">
                    <p>{t('@logs/CATEGORIES_SECTION_TITLE', 'Categorias')}</p>
                  </div>
                  <div className="actionSection">
                    <div className="actions">
                      <div className="filter">
                        <button
                          disabled={loading}
                          type="button"
                          onClick={() => {
                            setStatusCategoryFilterMenu(
                              !statusCategoryFilterMenu,
                            );
                            // setCategoryOrderMenu(false);
                          }}
                        >
                          <div>
                            <p>{getSelectedStatusCategoryFilterName()}</p>
                            {statusCategoryFilterMenu ? (
                              <MdKeyboardArrowUp />
                            ) : (
                              <MdKeyboardArrowDown />
                            )}
                          </div>
                        </button>
                      </div>
                      <div />
                      {/* Component created but waiting for backend changes */}
                      {/* <div className="filter">
                        <button
                        disabled={loading}
                          type="button"
                          onClick={() => {
                            setCategoryOrderMenu(!categoryOrderMenu);
                            setStatusCategoryFilterMenu(false);
                          }}
                        >
                          <div>
                            <p>{getSelectedCategoryOrderMenu()}</p>
                            {categoryOrderMenu ? (
                              <MdKeyboardArrowUp />
                            ) : (
                              <MdKeyboardArrowDown />
                            )}
                          </div>
                        </button>
                      </div>
                      <div /> */}
                      <div className="filterClean">
                        <button
                          disabled={loading}
                          type="button"
                          onClick={() => {
                            setSelectedCategory(null);
                            setStatusFilterChecked({
                              not_completed: false,
                              completed: false,
                              showAll: true,
                            });
                            setStatusErrorFilterMenu(false);
                            // setCategoryOrderMenu(false);
                            setStatusCategoryFilterMenu(false);
                            setSelectedStatusCategoryFilter('all');
                            setMarkedMenu(false);
                          }}
                        >
                          <div>
                            <div>
                              <MdOutlineFilterList />
                            </div>
                            <p>{t('@logs/FILTER_CLEAR', 'Limpar filtros')}</p>
                          </div>
                        </button>
                      </div>
                    </div>

                    {statusCategoryFilterMenu && (
                      <div className="filterMenu">
                        <div className="options">
                          <button
                            disabled={loading}
                            type="button"
                            onClick={() => {
                              setSelectedStatusCategoryFilter('all');
                            }}
                          >
                            <p>{t('@logs/ALL', 'Todos')}</p>
                          </button>
                          <div className="divider" />
                          <button
                            disabled={loading}
                            type="button"
                            onClick={() => {
                              setSelectedStatusCategoryFilter(
                                'only_not_completed',
                              );
                            }}
                          >
                            <p>
                              {t(
                                '@logs/ONLY_NOT_COMPLETED',
                                'Apenas em aberto',
                              )}
                            </p>
                          </button>
                          <div className="divider" />
                          <button
                            disabled={loading}
                            type="button"
                            onClick={() => {
                              setSelectedStatusCategoryFilter('only_completed');
                            }}
                          >
                            <p>
                              {t('@logs/ONLY_COMPLETED', 'Apenas resolvidos')}
                            </p>
                          </button>
                        </div>
                        <div className="space" />
                      </div>
                    )}

                    {/* Add this block when api is enabled to order categories */}
                    {/*
                    {categoryOrderMenu && (
                      <div className="filterMenu">
                        <div className="space" />
                        <div className="options">
                          <button
                          disabled={loading}
                            type="button"
                            onClick={() => {
                              setSelectedCategoryOrderMenu('recent');
                            }}
                          >
                            <p>{t('@logs/RECENT', 'Mais recentes')}</p>
                          </button>
                          <div className="divider" />
                          <button
                          disabled={loading}
                            type="button"
                            onClick={() => {
                              setSelectedCategoryOrderMenu('old');
                            }}
                          >
                            <p>{t('@logs/OLD', 'Mais antigos')}</p>
                          </button>
                          <div className="divider" />
                          <button
                          disabled={loading}
                            type="button"
                            onClick={() => {
                              setSelectedCategoryOrderMenu('more_completed');
                            }}
                          >
                            <p>
                              {t('@logs/MORE_NOT_COMPLETED', 'Mais abertos')}
                            </p>
                          </button>
                          <div className="divider" />
                          <button
                          disabled={loading}
                            type="button"
                            onClick={() => {
                              setSelectedCategoryOrderMenu(
                                'more_not_completed',
                              );
                            }}
                          >
                            <p>
                              {t('@logs/MORE_NOT_COMPLETED', 'Mais resolvidos')}
                            </p>
                          </button>
                        </div>
                      </div>
                    )} */}
                  </div>
                </div>
                <div className="carouselSection">
                  {filteredCategories.map(category => (
                    <CategoryCard
                      color={
                        selectedCategory?.action === category.action
                          ? themeContext.button_secondary
                          : themeContext.background_primary
                      }
                    >
                      <button
                        disabled={loading}
                        type="button"
                        onClick={() => {
                          setSelectedCategory(category);
                          setPageNumber(1);
                        }}
                      >
                        <div className="categoryContent">
                          <div className="title">
                            <p>{category.action}</p>
                          </div>
                          <div className="content">
                            <div className="numbers">
                              <NotCompletedNumber
                                color={getNotCompletedNumberColor(
                                  category.notCompleted,
                                  category.total,
                                )}
                              >
                                {category.notCompleted}
                              </NotCompletedNumber>
                              <p className="total">/{category.total}</p>
                            </div>

                            <div className="status">
                              <p> {t('@logs/NOT_COMPLETED', 'Em aberto')}</p>
                              {category.notCompleted > 0 ? (
                                <div className="errorIcon">
                                  <RiErrorWarningFill />
                                </div>
                              ) : (
                                <div className="checkIcon">
                                  <MdCheckCircleOutline />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </button>
                    </CategoryCard>
                  ))}
                </div>
              </CategoriesSection>

              <LogsList>
                <div className="titleAndSearchRow">
                  <div className="listTitle">
                    <p>{t('@logs/ERROR_LIST_TITLE', 'Últimas ocorrências')}</p>
                  </div>
                </div>

                <LogsListComponent>
                  <div className="header">
                    <div>
                      {anyMarked ? (
                        <div className="checkBoxHeader">
                          <button
                            disabled={loading}
                            type="button"
                            onClick={() => setMarkedMenu(!markedMenu)}
                          >
                            <div className="icon">
                              <MdOutlineCheckBox />
                            </div>
                            <MdKeyboardArrowDown />
                          </button>
                        </div>
                      ) : (
                        <div className="checkBoxHeader">
                          <div className="icon">
                            <MdOutlineCheckBoxOutlineBlank />
                          </div>
                          <MdKeyboardArrowDown />
                        </div>
                      )}
                    </div>
                    <div>
                      <div className="divider" />
                    </div>
                    <div>
                      <p>{t('@logs/STATUS_COLUMN', 'Status')}</p>
                      <button
                        disabled={loading}
                        type="button"
                        onClick={() =>
                          setStatusErrorFilterMenu(!statusErrorFilterMenu)
                        }
                      >
                        <MdOutlineFilterAlt />
                      </button>
                    </div>
                    <div>
                      <div className="divider" />
                    </div>
                    <div>
                      <p>{t('@logs/CATEGORY_COLUMN', 'Categoria')}</p>
                    </div>
                    <div>
                      <div className="divider" />
                    </div>
                    <div>
                      <p>{t('@logs/DATE_COLUMN', 'Data')}</p>
                    </div>
                    <div>
                      <div className="divider" />
                    </div>
                    <div>
                      <p>{t('@logs/PROVIDER_COLUMN', 'Provedor')}</p>
                      <button disabled={loading} type="button">
                        <MdOutlineFilterAlt />
                      </button>
                    </div>
                    <div>
                      <div className="divider" />
                    </div>
                    <div>
                      <p>{t('@logs/USER_COLUMN', 'Usuário(a)')}</p>
                    </div>
                    <div>
                      <div className="divider" />
                    </div>
                    <div>
                      <p>{t('@logs/BRANCH_COLUMN', 'Loja/Filial')}</p>
                    </div>
                    <div>
                      <div className="divider" />
                    </div>
                    <div />
                  </div>

                  {markedMenu && anyMarked && (
                    <div className="filterMenu">
                      <div className="options">
                        <button
                          disabled={loading}
                          type="button"
                          onClick={() => {
                            setMarkAction('mark_as_completed');
                            statusChangeConfirmationModalRef.current?.show();
                          }}
                        >
                          <p>
                            {t(
                              '@logs/MARK_AS_COMPLETED',
                              'Marcar como resolvidos',
                            )}
                          </p>
                        </button>
                        {/* <div className="divider" />
                        <button
                        disabled={loading}
                          type="button"
                          onClick={() => {
                            setMarkAction('mark_as_not_completed');
                            statusChangeConfirmationModalRef.current?.show();
                          }}
                        >
                          <p>
                            {t(
                              '@logs/MARK_AS_NOT_COMPLETED',
                              'Marcar como em aberto',
                            )}
                          </p>
                        </button> */}
                      </div>
                      <div className="space" />
                    </div>
                  )}

                  {statusErrorFilterMenu && (
                    <div className="statusFilter">
                      <div />
                      <div />
                      <div className="options">
                        <div className="option">
                          <StatusLabel color={themeContext.font_danger}>
                            {t('@logs/NOT_COMPLETED', 'Aberto')}
                          </StatusLabel>
                          <Checkbox
                            checked={statusFilterChecked.not_completed}
                            onChange={handleChangeStatusNotCompleted}
                            color="primary"
                          />
                        </div>
                        <div className="option">
                          <StatusLabel color={themeContext.button_success}>
                            {t('@logs/RESOLVED', 'Resolvido')}
                          </StatusLabel>
                          <Checkbox
                            checked={statusFilterChecked.completed}
                            onChange={handleChangeStatusCompleted}
                            color="primary"
                          />
                        </div>
                        <div className="option">
                          <p className="showAll">
                            {t('@logs/SHOW_ALL', 'Mostrar Todos')}
                          </p>
                          <Checkbox
                            checked={statusFilterChecked.showAll}
                            onChange={handleChangeStatusShowAll}
                            color="primary"
                          />
                        </div>
                      </div>
                      <div />
                      <div />
                      <div />
                      <div />
                      <div />
                      <div />
                      <div />
                      <div />
                      <div />
                      <div />
                      <div />
                      <div />
                    </div>
                  )}

                  {formattedErrors?.map(error => (
                    <div className="rowTable">
                      <div>
                        {/* Remove this conditional when the api is enabled to change from completed to not_completed */}
                        {!error.completed_at ? (
                          <Checkbox
                            color="primary"
                            checked={error.marked}
                            // eslint-disable-next-line no-underscore-dangle
                            name={error._id}
                            value={error.marked}
                            onChange={handleChangeMark}
                          />
                        ) : (
                          <Checkbox
                            color="secondary"
                            checked={error.marked}
                            // eslint-disable-next-line no-underscore-dangle
                            name={error._id}
                            value={error.marked}
                            onChange={handleChangeMark}
                            disabled
                          />
                        )}
                      </div>
                      <div>
                        <div className="divider" />
                      </div>
                      <div>
                        {error?.completed_at ? (
                          <StatusLabel color={themeContext.button_success}>
                            <p>{t('@logs/RESOLVED', 'Resolvido')}</p>
                          </StatusLabel>
                        ) : (
                          <StatusLabel color={themeContext.button_danger}>
                            <p>{t('@logs/TO_RESOVE', 'Aberto')}</p>
                          </StatusLabel>
                        )}
                      </div>
                      <div>
                        <div className="divider" />
                      </div>
                      <div>
                        <div className="category">
                          <p>{error.action}</p>
                        </div>
                      </div>
                      <div>
                        <div className="divider" />
                      </div>
                      <div>
                        <div className="date">
                          <div className="calendar">
                            <CalendarIcon />
                          </div>
                          <div className="hourSection">
                            <p>
                              <b>{getDate(parseJSON(error.created_at))}</b>{' '}
                              {format(parseJSON(error.created_at), 'MMM', {
                                locale: dateLanguagePack,
                              })}{' '}
                              {format(parseJSON(error.created_at), 'yyyy', {
                                locale: dateLanguagePack,
                              })}
                            </p>
                            <p className="hour">
                              {format(parseJSON(error.created_at), 'p', {
                                locale: dateLanguagePack,
                              })}
                              {' min'}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="divider" />
                      </div>
                      <div>
                        {error?.payload?.provider?.provider?.name && (
                          <StatusLabel color={themeContext.font_secondary}>
                            {error?.payload?.provider?.provider?.name}
                          </StatusLabel>
                        )}
                      </div>
                      <div>
                        <div className="divider" />
                      </div>
                      <div>
                        <div className="userOrBranch">
                          <div className="name">
                            <p>{error?.user?.name}</p>
                          </div>
                          <div className="code">
                            <p>{error?.user?.sale_codes[0].code}</p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="divider" />
                      </div>
                      <div>
                        <div className="userOrBranch">
                          <div className="name">
                            <p>{error?.user?.store?.name}</p>
                          </div>
                          <div className="code">
                            <p>{error?.user?.store?.code}</p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="divider" />
                      </div>
                      <button
                        disabled={loading}
                        type="button"
                        onClick={() => {
                          setDetails(true);
                          currentError.set_current_error(error);
                        }}
                      >
                        <div className="detailsButton">
                          <MdKeyboardArrowRight
                            color={themeContext.font_secondary}
                          />
                        </div>
                      </button>
                    </div>
                  ))}
                </LogsListComponent>
              </LogsList>
            </LogsContent>
            <LogsFooter>
              <div className="pageMenu">
                <div>
                  <button
                    type="button"
                    disabled={pageNumber === 1}
                    onClick={() => setPageNumber(pageNumber - 1)}
                  >
                    <MdKeyboardArrowLeft
                      color={
                        pageNumber === 1
                          ? themeContext.button_secondary
                          : themeContext.button_primary
                      }
                    />
                  </button>
                </div>
                <div className="pageNumber">
                  <p>{pageNumber}</p>
                </div>
                <div>
                  <button
                    disabled={loading}
                    type="button"
                    onClick={() => setPageNumber(pageNumber + 1)}
                  >
                    <MdKeyboardArrowRight color={themeContext.button_primary} />
                  </button>
                </div>
              </div>
            </LogsFooter>
          </LogsPanel>
          {modals}
        </Container>
      ) : (
        <ErrorLogDetails
          setDetails={setDetails}
          getErrors={getErrors}
          getCategories={getCategories}
        />
      )}
    </>
  );
};

// Consumer
const ErrorLog: React.FC = () => {
  return (
    <CurrentErrorProvider>
      <Content />
    </CurrentErrorProvider>
  );
};

export default ErrorLog;
