import React, { useRef, useCallback, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles, SubmitHandler } from '@unform/core';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

// Component import
import Button from '@components/Button';
import Input from '@components/Input';
import Switch from '@components/Switch';

// Service import
import api from '@services/api';

// Hook import
import { useBrandAndPaymentSettings } from '@hooks/useBrandAndPaymentSettings';

// Style import
import { Container } from './styles';

// Interfaces
interface IProps {
  hide: () => any;
}

const BrandEdition: React.FC<IProps> = ({ hide }) => {
  // Get context
  const currentBrandAndPayment = useBrandAndPaymentSettings();
  const vtexBrandProvider =
    currentBrandAndPayment.current_brand?.providers.find(
      e => e.code === 'vtex' && e.enabled === true,
    );

  // Local states
  const [brandSwitchEnable, setBrandSwitchEnable] = useState(
    currentBrandAndPayment.current_brand?.enabled,
  );
  const [providerSwitchEnable, setProviderSwitchEnable] = useState(
    vtexBrandProvider?.enabled,
  );
  const [loading, setLoading] = useState(false);

  // Local refs
  const formBrandEditionRef = useRef<FormHandles>(null);

  const brandNameInputRef = useRef<HTMLInputElement>(null);
  const brandCodeInputRef = useRef<HTMLInputElement>(null);
  const imageURLInputRef = useRef<HTMLInputElement>(null);
  const referencePatternInputRef = useRef<HTMLInputElement>(null);
  const colorPatternInputRef = useRef<HTMLInputElement>(null);
  const providerSubdomainInputRef = useRef<HTMLInputElement>(null);
  const providerComercialPoliticsInputRef = useRef<HTMLInputElement>(null);
  const providerPaymentIdInputRef = useRef<HTMLInputElement>(null);
  const providerSalespersonPatternInputRef = useRef<HTMLInputElement>(null);
  const providerKeyInputRef = useRef<HTMLInputElement>(null);
  const providerTokenInputRef = useRef<HTMLInputElement>(null);
  const providerBustokenInputRef = useRef<HTMLInputElement>(null);

  // Get translation function
  const { t } = useTranslation();

  // Change store settings
  const brandEditionSubmit: SubmitHandler<{
    brandName: string;
    brandCode: number;
    imageURL: string;
    referencePattern: string;
    colorPattern: string;
    providerSubdomain: string;
    providerComercialPolitics: number;
    providerPaymentId: number;
    providerSalespersonPattern: string;
    providerKey: string;
    providerToken: string;
    providerBustoken: string;
  }> = useCallback(
    async data => {
      const {
        brandName,
        brandCode,
        imageURL,
        referencePattern,
        colorPattern,
        providerSubdomain,
        providerComercialPolitics,
        providerPaymentId,
        providerSalespersonPattern,
        providerKey,
        providerToken,
        providerBustoken,
      } = data;

      // Setup a schema to be valitated
      const schema = Yup.object()
        .shape({
          brandName: Yup.string()
            .strict()
            .typeError(
              t(
                '@providers/INVALID_NAME_TYPE',
                'O tipo do nome é inválido, deveria ser alfanumérico.',
              ),
            )
            .required(t('@providers/REQUIRED_NAME', 'O nome é obrigatório.')),
          brandCode: Yup.number()
            .typeError(
              t(
                '@providers/INVALID_CODE_TYPE',
                'O tipo do código identificador externo é inválido, deveria ser numérico.',
              ),
            )
            .required(
              t(
                '@providers/REQUIRED_CODE',
                'O código identificador externo é obrigatório.',
              ),
            ),
          imageURL: Yup.string()
            .strict()
            .typeError(
              t(
                '@providers/INVALID_IMAGE_URL_TYPE',
                'O tipo do logotipo é inválido, deveria ser alfanumérico.',
              ),
            )
            .url(
              t('@providers/INVALID_IMAGE_URL', 'A url da imagem é inválida.'),
            )
            .required(
              t(
                '@providers/REQUIRED_IMAGE_URL',
                'A url do logotipo da marca é obrigatória.',
              ),
            ),
          referencePattern: Yup.string()
            .strict()
            .typeError(
              t(
                '@providers/INVALID_PRODUCT_CODE_PATTERN_TYPE',
                'O tipo da máscara do código do produto é inválido, deveria ser alfanumérico.',
              ),
            )
            .required(
              t(
                '@providers/REQUIRED_PRODUCT_CODE_PATTERN',
                'A máscara do código dos produtos desta marca é obrigatório.',
              ),
            ),
          colorPattern: Yup.string()
            .strict()
            .typeError(
              t(
                '@providers/INVALID_PRODUCT_COLOR_PATTERN_TYPE',
                'O tipo da máscara da cor do produto é inválido, deveria ser alfanumérico.',
              ),
            )
            .required(
              t(
                '@providers/REQUIRED_PRODUCT_COLOR_PATTERN',
                'A máscara da cor dos produtos desta marca é obrigatório.',
              ),
            ),
          providerSubdomain: Yup.string()
            .strict()
            .typeError(
              t(
                '@brand_provider_controller_post/INVALID_BASE_SUBDOMAIN_TYPE',
                'O tipo do subdomínio base deste provedor de marca é inválido, deveria ser alfanumérico.',
              ),
            )
            .required(
              t(
                '@brand_provider_controller_post/REQUIRED_BASE_SUBDOMAIN',
                'O subdomínio base deste provedor de marca é obrigatório.',
              ),
            ),
          providerComercialPolitics: Yup.number()
            .typeError(
              t(
                '@brand_provider_controller_post/INVALID_SALE_CHANNEL_CODE_TYPE',
                'O tipo do código do canal de venda da API deste provedor de marca é inválido, deveria ser numérico.',
              ),
            )
            .required(
              t(
                '@brand_provider_controller_post/REQUIRED_SALE_CHANNEL_CODE',
                'O código do canal de venda da API deste provedor de marca é obrigatório.',
              ),
            ),
          providerPaymentId: Yup.number()
            .typeError(
              t(
                '@brand_provider_controller_post/INVALID_PAYMENT_SYSTEM_CODE_TYPE',
                'O tipo do código do canal de pagamento da API deste provedor de marca é inválido, deveria ser numérico.',
              ),
            )
            .required(
              t(
                '@brand_provider_controller_post/REQUIRED_PAYMENT_SYSTEM_CODE',
                'O código do canal de pagamento da API deste provedor de marca é obrigatório.',
              ),
            ),
          providerSalespersonPattern: Yup.string()
            .strict()
            .typeError(
              t(
                '@brand_provider_controller_post/INVALID_SALECODE_PATTERN_TYPE',
                'O tipo da máscara de código de vendedor deste provedor de marca é inválido, deveria ser alfanumérico.',
              ),
            )
            .required(
              t(
                '@brand_provider_controller_post/REQUIRED_SALECODE_PATTERN',
                'A máscara de código de vendedor deste provedor de marca é obrigatória.',
              ),
            ),
          providerKey: Yup.string()
            .strict()
            .typeError(
              t(
                '@brand_provider_controller_post/INVALID_KEY_TYPE',
                'O tipo da chave de API deste provedor de marca é inválido, deveria ser alfanumérico.',
              ),
            )
            .required(
              t(
                '@brand_provider_controller_post/REQUIRED_KEY',
                'A chave de API deste provedor de marca é obrigatória.',
              ),
            ),
          providerToken: Yup.string()
            .strict()
            .typeError(
              t(
                '@brand_provider_controller_post/INVALID_TOKEN_TYPE',
                'O tipo do token de API deste provedor de marca é inválido, deveria ser alfanumérico.',
              ),
            )
            .required(
              t(
                '@brand_provider_controller_post/REQUIRED_TOKEN',
                'O token de API deste provedor de marca é obrigatório.',
              ),
            ),
          providerBustoken: Yup.string()
            .strict()
            .typeError(
              t(
                '@brand_provider_controller_post/INVALID_BUSTOKEN_TYPE',
                'O tipo do bustoken de API deste provedor de marca é inválido, deveria ser alfanumérico.',
              ),
            ),
        })
        .noUnknown(true, t('@stores/INVALID_REQUEST', 'Requisição inválida.'));

      try {
        await schema.validate(data, {
          abortEarly: false,
          stripUnknown: false,
        });
      } catch (err: any) {
        if (err instanceof Yup.ValidationError) {
          const validationErrors: { [key: string]: string } = {};
          const errors: string[] = [];
          err.inner.forEach(error => {
            errors.push(error.message);
            if (error.path) validationErrors[error.path] = error.message;
          });
          if (formBrandEditionRef.current)
            formBrandEditionRef.current.setErrors(validationErrors);
          toast.error(errors[0]);
        }

        return;
      }

      // Loading set
      setLoading(true);
      try {
        const response = await api.patch(
          `admin/brand/${currentBrandAndPayment.current_brand?.id}`,
          {
            code: Number(brandCode),
            name: brandName,
            enabled: brandSwitchEnable,
            image_url: imageURL,
            payload: {
              product_code_pattern: referencePattern,
              product_color_pattern: colorPattern,
            },
          },
        );

        try {
          await api.patch(`admin/brand/provider/${vtexBrandProvider?.id}`, {
            brand_id: response.data.id,
            name: 'Vtex',
            code: 'vtex',
            enabled: providerSwitchEnable,
            payload: {
              key: providerKey,
              token: providerToken,
              base_subdomain: providerSubdomain,
              salecode_pattern: providerSalespersonPattern,
              sale_channel_code: Number(providerComercialPolitics),
              payment_system_code: Number(providerPaymentId),
              bustoken: providerBustoken,
            },
          });
        } catch (err: any) {
          // Check if server had return a error
          if (err.response?.data?.message)
            toast.error(err.response.data.message);
          else {
            toast.error(
              t(
                '@general/CONNECTION_FAILURE_ERROR',
                'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
              ),
            );
          }
        }

        hide();
        setLoading(false);
      } catch (err: any) {
        // Check if server had return a error
        if (err.response?.data?.message) toast.error(err.response.data.message);
        else {
          toast.error(
            t(
              '@general/CONNECTION_FAILURE_ERROR',
              'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
            ),
          );
        }
        setLoading(false);
      }
    },
    [
      t,
      currentBrandAndPayment.current_brand?.id,
      brandSwitchEnable,
      hide,
      vtexBrandProvider?.id,
      providerSwitchEnable,
    ],
  );

  return (
    <Container>
      <Form ref={formBrandEditionRef} onSubmit={brandEditionSubmit}>
        <div className="title">
          <h2>{t('@providers/BRAND_TITLE', 'Marca')}</h2>
          <Switch
            checked={brandSwitchEnable}
            onChange={() => setBrandSwitchEnable(!brandSwitchEnable)}
          />
        </div>
        <div className="formBody">
          <div className="row">
            <div className="brandNameInput">
              <Input
                ref={brandNameInputRef}
                name="brandName"
                label="Nome"
                value={currentBrandAndPayment.current_brand?.name}
              />
            </div>
            <div className="brandCodeInput">
              <Input
                ref={brandCodeInputRef}
                name="brandCode"
                label="Código"
                value={currentBrandAndPayment.current_brand?.code}
                type="tel"
                inputmode="numeric"
                pattern="[0-9]*"
              />
            </div>
          </div>
          <Input
            ref={imageURLInputRef}
            name="imageURL"
            label="URL da Imagem"
            value={currentBrandAndPayment.current_brand?.image_url}
          />
          <div className="row">
            <div className="referencePatternInput">
              <Input
                ref={referencePatternInputRef}
                name="referencePattern"
                label="Padrão de Referência"
                value={
                  currentBrandAndPayment.current_brand?.payload
                    .product_code_pattern
                }
              />
            </div>
            <div className="colorPatternInput">
              <Input
                ref={colorPatternInputRef}
                name="colorPattern"
                label="Padrão de Cor"
                value={
                  currentBrandAndPayment.current_brand?.payload
                    .product_color_pattern
                }
              />
            </div>
          </div>
          <h3>{t('@providers/PROVIDERS_TITLE', 'Provedores:')}</h3>
          <div className="providerForm">
            <div className="providerTitle">
              <h4>{t('@providers/VTEX', 'Vtex')}</h4>
              <Switch
                checked={providerSwitchEnable}
                onChange={() => setProviderSwitchEnable(!providerSwitchEnable)}
              />
            </div>
            <Input
              ref={providerSubdomainInputRef}
              name="providerSubdomain"
              label="Subdomínio"
              value={vtexBrandProvider?.payload.base_subdomain}
            />
            <div className="providerRow">
              <div className="providerComercialPoliticsInput">
                <Input
                  ref={providerComercialPoliticsInputRef}
                  name="providerComercialPolitics"
                  label="Política Comercial"
                  value={vtexBrandProvider?.payload.sale_channel_code}
                />
              </div>

              <div className="providerPaymentIdInput">
                <Input
                  ref={providerPaymentIdInputRef}
                  name="providerPaymentId"
                  label="Id do Pagamento"
                  value={vtexBrandProvider?.payload.payment_system_code}
                />
              </div>
            </div>
            <Input
              ref={providerSalespersonPatternInputRef}
              name="providerSalespersonPattern"
              label="Padrão do Vendedor"
              value={vtexBrandProvider?.payload.salecode_pattern}
            />
            <Input
              ref={providerKeyInputRef}
              name="providerKey"
              label="Key"
              value={vtexBrandProvider?.payload.key}
            />
            <Input
              ref={providerTokenInputRef}
              name="providerToken"
              label="Token"
              value={vtexBrandProvider?.payload.token}
              hide
            />
            <Input
              ref={providerBustokenInputRef}
              name="providerBustoken"
              label="Bustoken"
              value={vtexBrandProvider?.payload.bustoken}
              hide
            />
          </div>
        </div>
        <div className="saveButton">
          <Button
            disabled={loading}
            onClick={() => {
              formBrandEditionRef.current?.submitForm();
            }}
          >
            {t('@providers/SAVE_EDITION_BUTTON', 'Salvar')}
          </Button>
        </div>
        <div>
          <Button variant="outlined" onClick={() => hide()}>
            {t('@providers/CANCEL_EDITION_BUTTON', 'Cancelar')}
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default BrandEdition;
