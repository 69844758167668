import axios from 'axios';
import { toast } from 'react-toastify';
import { t } from '@i18n/index';
import { store } from '../store';
import { signOut } from '../store/modules/auth/actions';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.response.use(
  response => response,
  error => {
    if (error?.response?.status === 401) {
      // When 401 error, sign out
      store.dispatch(signOut());
    }
    if (error?.response?.data?.message) {
      toast.error(`Erro na solicitação: ${error?.response?.data?.message}`);
    } else {
      toast.error(
        t(
          '@general/CONNECTION_FAILURE_ERROR',
          'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
        ),
      );
    }

    return Promise.reject(error);
  },
);

export default api;
