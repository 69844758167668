import React, { useState, useRef, useEffect } from 'react';
import { MdInfoOutline } from 'react-icons/md';

// Model import
import { IOrderPaymentsCharges } from '@hooks/useCurrentOrder/models';

import { Container } from './styles';

interface IProps {
  charge: IOrderPaymentsCharges;
  orderStatus?: string;
}

const ChargeStatus: React.FC<IProps> = ({ charge, orderStatus }) => {
  const [openOverlay, setOpenOverlay] = useState(false);
  const overlayClassName = openOverlay ? 'open' : '';

  // Overlay ref
  const overlayRef = useRef<HTMLDivElement>(null);

  const getStatus = () => {
    switch (charge.status) {
      case 'AUTHORIZED':
        return 'Autorizada';
      case 'UNKNOWN':
        return 'Buscando informações';
      case 'ANTIFRAUD_APPROVED':
        return 'Aprovada pelo Anti-fraude';
      case 'CAPTURED':
        return 'Capturada';
      case 'PARTIAL_CHARGEBACK':
        return 'Cancelada parcialmente';
      case 'ANTIFRAUD_REPROVED':
        if (orderStatus === 'CANCELED') return 'Cancelada pelo anti-fraude';
        return 'Reprovada pelo anti-fraude';
      case 'CANCELED':
        return 'Cancelada';
      default:
        return 'Buscando informações';
    }
  };

  const overlayText = () => {
    switch (charge.status) {
      case 'AUTHORIZED':
        return 'Autorizada reserva de valor para captura. Cobrança ainda em processamento, pendente de verificação. ';
      case 'UNKNOWN':
        return 'Estamos atualizando informações sobre esta cobrança. Tente novamente mais tarde. ';
      case 'ANTIFRAUD_APPROVED':
        return 'Cobrança verificada pelo anti-fraude e aprovada. Aguardando captura do valor.';
      case 'CAPTURED':
        return 'Cobrança efetivamente paga, com valor autorizado, aprovado pelo anti-fraude e capturado.';
      case 'PARTIAL_CHARGEBACK':
        return 'Cobrança efetivamente paga que sofreu estorno parcial. Verifique possíveis motivos na aba Pacotes.';
      case 'ANTIFRAUD_REPROVED':
        if (orderStatus === 'CANCELED')
          return 'Cobrança cancelada por reprovação no anti-fraude, efetivando o cancelamento do pedido.';
        return 'Cobrança reprovada pelo anti-fraude. Aguardando cancelamento da cobrança.';
      case 'CANCELED':
        return 'Cobrança cancelada totalmente. Verifique possíveis motivos na aba Pacotes.';
      default:
        return 'Estamos atualizando informações sobre esta cobrança. Tente novamente mais tarde. ';
    }
  };

  const handleOpenOverlay = () => {
    setOpenOverlay(true);
    setTimeout(() => {
      setOpenOverlay(false);
    }, 5000);
  };

  useEffect(() => {
    const overlayHandler = (e: any) => {
      if (!overlayRef.current) {
        return;
      }
      if (!overlayRef.current.contains(e.target)) {
        setOpenOverlay(false);
      }
    };

    document.addEventListener('click', overlayHandler, true);

    return () => {
      document.removeEventListener('click', overlayHandler);
    };
  }, []);

  return (
    <Container>
      <span>Status: </span>
      <div className="statusContainer">
        <b>{getStatus()}</b>
        <div className="infoContainer" ref={overlayRef}>
          <button
            className="infoIcon"
            type="button"
            onClick={() => handleOpenOverlay()}
          >
            <MdInfoOutline />
          </button>
          <div className={`infoDiv ${overlayClassName}`}>
            <div className="arrowUp" />
            <div className="containerBody">{overlayText()}</div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ChargeStatus;
