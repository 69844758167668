import styled from 'styled-components';

export const Container = styled.div`
  padding: 1.5rem;
  height: 100%;
`;

export const StoresPanel = styled.div`
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .pageTitle {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;

    .backIcon {
      color: ${({ theme }) => theme.button_high};
      font-size: 1.5rem;
    }

    h2 {
      margin-left: 0.5rem;
    }
  }
`;

export const OrdersHeader = styled.div`
  margin-top: 0.5rem;
  width: 100%;

  .header {
    background-color: ${({ theme }) => theme.background_secondary};
    border-top: 0.063rem solid ${({ theme }) => theme.background_secondary};
    border-bottom: 0.063rem solid ${({ theme }) => theme.background_secondary};
    padding: 0.5rem 1rem;
    display: grid;
    grid-template-columns: 1fr 1.2fr 1.2fr repeat(5, 1fr) 0.2fr;
    align-items: center;
    justify-items: center;

    div {
      display: flex;
      align-items: center;

      p {
        color: ${({ theme }) => theme.background_low};
        border: none;
        padding: 0;
        font-weight: 700;
        margin-right: 0.5rem;
        display: flex;
        align-items: center;
      }

      &:nth-child(2),
      &:nth-child(3) {
        justify-content: center;
        align-items: center;
        display: flex;
        width: 100%;
      }

      button {
        font-size: 1rem;
        display: flex;
        align-items: center;
      }
    }
  }

  .rowTable {
    padding: 0.5rem 1rem;
    display: grid;
    grid-template-columns: 1fr 1.2fr 1.2fr repeat(5, 1fr) 0.2fr;
    box-shadow: 0px 0.25rem 0.25rem rgba(0, 0, 0, 0.25);

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-right: ${({ theme }) => theme.background_secondary} solid 1px;
      border-width: 1px;

      .hourSection {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        .hourSectionInfo {
          margin-left: 0.5rem;

          .hour {
            font-size: 0.625rem;
            color: ${({ theme }) => theme.font_secondary};
          }
        }
      }

      .price {
        font-weight: 700;
      }

      .numberCode {
        color: ${({ theme }) => theme.font_secondary};
      }

      .productQuantity {
        color: ${({ theme }) => theme.font_secondary};
        font-size: 0.75rem;
      }
    }

    div:last-child {
      border-right: none;
    }
  }
`;

export const DetailsPanel = styled.div`
  width: 100%;
  height: 40rem;

  .divider {
    height: 75%;
    margin-left: 4rem;
    margin-right: 2rem;
    border: ${({ theme }) => theme.background_secondary} solid 0.063rem;
    justify-self: center;
    align-self: center;
  }

  .buttonsNav {
    width: 100%;
    height: 0%;
    justify-self: center;
    align-self: center;
  }

  .panel {
    display: grid;
    grid-template-columns: 14fr 1fr 13fr 1fr 14fr;
    height: 35rem;
  }

  .sectionPanel {
    display: flex;
    flex-direction: column;
    grid-template-columns: 25fr 3fr 40fr;
    min-height: -webkit-fill-available;
    .sectionPanelTitle {
      display: flex;
      font-weight: 700;
      font-size: 1.3rem;
      margin-top: 1.1rem;
      color: ${({ theme }) => theme.background_secondary};

      .sectionPanelTitleSecondary {
        margin-left: 2rem;
        color: ${({ theme }) => theme.background_high};
      }

      .pill {
        font-weight: 400;
        font-size: 0.875rem;
        margin-left: 1.5rem;
        color: ${({ theme }) => theme.background_high};
      }
    }

    .sectionContent {
      display: flex;
      flex-direction: column;
      height: 100%;
      margin-top: 1rem;

      .sectionSubContentVertical {
        display: flex;
        flex-direction: column;
        height: 33%;
        padding-bottom: 1.5rem;

        .sectionSubContentTitle {
          font-weight: 700;
          font-size: 0.975rem;
          padding-bottom: 0.5rem;
          border-bottom: ${({ theme }) => theme.background_secondary} solid
            0.063rem;
        }

        .sectionSubContentInfo {
          font-size: 0.875rem;

          p {
            margin: 0.5rem 0 0.5rem;
          }
        }
      }

      .sectionSubContentHorizontal {
        display: flex;
        border-bottom: ${({ theme }) => theme.background_secondary} solid
          0.125rem;
        padding-bottom: 0.5rem;

        .sectionSubContentInfo {
          margin-right: 1rem;

          .title {
            display: flex;
            align-items: center;
            font-weight: 700;
            font-size: 0.75rem;
            margin-bottom: 0.5rem;

            p {
              margin-left: 0.25rem;
            }
          }

          .valueText {
            display: flex;
            align-items: center;
            font-weight: 700;
            font-size: 0.75rem;
            margin-bottom: 0.5rem;

            p {
              margin: 0 0.25rem;
            }
          }
        }
      }
      .rolagem {
        margin-top: 0.125rem;
        height: 65%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        overflow-y: scroll;
        padding-right: 0.25rem;
        ::-webkit-scrollbar {
          width: 0.25rem;
        }
        ::-webkit-scrollbar-track {
          background: none;
        }
        ::-webkit-scrollbar-thumb {
          background: ${({ theme }) => theme.background_secondary};
          border-radius: 0.25rem;
        }
        ::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      }
    }
  }
`;
