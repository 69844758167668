import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.font_secondary};
    font-size: 4.375rem;
  }
  p {
    font-size: 1.125rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: center;
  }
  span:last-child {
    margin-top: 1rem;
    width: 100%;
  }
`;
