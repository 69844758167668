import { IOrderPayments } from '@hooks/useCurrentOrder/models';

export default function getStorePaymentProviderPayloadInfos(
  orderPayment: IOrderPayments,
) {
  const {
    credentials,
    account_code: defaultAccountCode,
    merchant_code: defaultMerchantCode,
  } = orderPayment.store_payment_provider.payload;

  const account_code = credentials?.pagarme?.account_code ?? defaultAccountCode;
  const merchant_code =
    credentials?.pagarme?.merchant_code ?? defaultMerchantCode;

  return { account_code, merchant_code };
}
