import { IOrderData } from '@hooks/useCurrentOrder/models';
import SomaStoreApiGateway from 'gateways/SomaStoreApiGateway';
import { useEffect, useState } from 'react';

export const useGetOrderStatus = (order: IOrderData) => {
  const [orderStatus, setOrderStatus] = useState<any>();

  useEffect(() => {
    const getStatus = async () => {
      try {
        const { data } = await SomaStoreApiGateway.getStatusById(order.id);
        setOrderStatus(data);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        throw err;
      }
    };

    getStatus();
  }, [order.id]);

  return { orderStatus };
};
