import React, { useContext, useEffect, useState, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Locale, getDate, parseJSON, format } from 'date-fns';
import ptBR from 'date-fns/esm/locale/pt-BR/index.js';
import { useTranslation } from 'react-i18next';
import {
  MdKeyboardArrowDown,
  MdOutlineCheckBoxOutlineBlank,
  MdOutlineCheckBox,
} from 'react-icons/md';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import Checkbox from '@material-ui/core/Checkbox';
import { ThemeContext } from 'styled-components';

// Util import
import setActivePage from '@util/setActivePage';

// Hook import
import { useCurrentError } from '@hooks/useCurrentError';

// Component import
import Modal, { IModalRef } from '@components/Modal';

// Modal import
import StatusChangeConfirmationModal from './StatusChangeConfirmationModal';

// Style import
import {
  Container,
  LogsPanel,
  LogsListComponent,
  // StatusLabel,
  LogDetails,
} from './styles';
import { StatusLabel } from '../styles';

interface Props {
  setDetails: React.Dispatch<React.SetStateAction<boolean>>;
  getErrors: () => Promise<void>;
  getCategories: () => Promise<void>;
}

const ErrorLogDetails: React.FC<Props> = ({
  setDetails,
  getErrors,
  getCategories,
}) => {
  // Local states
  const [dateLanguagePack, setDateLanguagePack] = useState<Locale>(ptBR);
  const [markedMenu, setMarkedMenu] = useState(false);

  // Local refs
  const statusChangeConfirmationModalRef = useRef<IModalRef>(null);

  // Global states
  const language = useSelector(state => state.ui.language);

  // Get translation function
  const { t } = useTranslation();

  // Theme context
  const themeContext = useContext(ThemeContext);

  // Get context
  const currentError = useCurrentError();

  const getFormattedTime = (timeInMiliseconds: number) => {
    const timeInSeconds = timeInMiliseconds / 1000;
    const hours = Math.floor(timeInSeconds / 3600);
    const minutesRemaining = timeInSeconds - hours * 3600;
    const minutes = Math.floor(minutesRemaining / 60);

    const seconds = minutesRemaining - minutes * 60;
    return (
      <>
        {hours > 0 && <>{hours} hora(s) </>}
        {minutes > 0 && <>{minutes} minuto(s) </>}
        {seconds > 0 && <>{seconds} segundo(s)</>}
      </>
    );
  };

  // Initialized
  useEffect(() => {
    // Set page
    setActivePage('@logs');
  }, []);

  // Get language module
  useEffect(() => {
    const languageSet = language;
    import(`date-fns/locale/${languageSet}`)
      .then(res => setDateLanguagePack(res.default as Locale))
      .catch(() => {
        setDateLanguagePack(ptBR);
      });
  }, [language]);

  const handleChangeMark = () => {
    if (currentError.current_error) {
      currentError.set_current_error({
        ...currentError.current_error,
        marked: !currentError.current_error.marked,
      });
    }
    setMarkedMenu(false);
  };

  // Modals
  const modals = useMemo(
    () => (
      <>
        <Modal ref={statusChangeConfirmationModalRef} size="smaller">
          <StatusChangeConfirmationModal
            hide={() => {
              statusChangeConfirmationModalRef.current?.hide();
              getCategories();
              getErrors();
              setDetails(false);
            }}
          />
        </Modal>
      </>
    ),
    [getCategories, getErrors, setDetails],
  );

  return (
    <Container>
      <LogsPanel>
        <div className="detailsTitleRow">
          <button type="button" onClick={() => setDetails(false)}>
            <div className="backIcon">
              <AiOutlineArrowLeft />
            </div>
          </button>
          <p>{t('@logsDetails/PAGE_TITLE', 'Log de Erros')}</p>
        </div>
        <LogsListComponent>
          <div className="header">
            <div>
              {currentError.current_error?.marked ? (
                <div className="checkBoxHeader">
                  <button
                    type="button"
                    onClick={() => setMarkedMenu(!markedMenu)}
                  >
                    <div className="icon">
                      <MdOutlineCheckBox />
                    </div>
                    <MdKeyboardArrowDown />
                  </button>
                </div>
              ) : (
                <div className="checkBoxHeader">
                  <div className="icon">
                    <MdOutlineCheckBoxOutlineBlank />
                  </div>
                  <MdKeyboardArrowDown />
                </div>
              )}
            </div>
            <div>
              <div className="divider" />
            </div>
            <div>
              <p>{t('@logsDetails/STATUS_COLUMN', 'Status')}</p>
            </div>
            <div>
              <div className="divider" />
            </div>
            <div>
              <p>{t('@logsDetails/CATEGORY_COLUMN', 'Categoria')}</p>
            </div>
            <div>
              <div className="divider" />
            </div>
            <div>
              <p>{t('@logsDetails/DATE_COLUMN', 'Data')}</p>
            </div>
            <div>
              <div className="divider" />
            </div>
            <div>
              <p>{t('@logsDetails/PROVIDER_COLUMN', 'Provedor')}</p>
            </div>
            <div>
              <div className="divider" />
            </div>
            <div>
              <p>{t('@logsDetails/USER_COLUMN', 'Usuário(a)')}</p>
            </div>
            <div>
              <div className="divider" />
            </div>
            <div>
              <p>{t('@logsDetails/BRANCH_COLUMN', 'Loja/Filial')}</p>
            </div>
            <div>
              <div className="divider" />
            </div>
            <div />
          </div>

          {markedMenu && currentError.current_error?.marked && (
            <div className="filterMenu">
              <div className="options">
                <button
                  type="button"
                  onClick={() => {
                    statusChangeConfirmationModalRef.current?.show();
                  }}
                >
                  <p>
                    {t('@logs/MARK_AS_COMPLETED', 'Marcar como resolvidos')}
                  </p>
                </button>
              </div>
              <div className="space" />
            </div>
          )}

          <div className="rowTable">
            <div>
              {/* Remove this conditional when the api is enabled to change from completed to not_completed */}
              {!currentError.current_error?.completed_at ? (
                <Checkbox
                  color="primary"
                  checked={currentError.current_error?.marked}
                  // eslint-disable-next-line no-underscore-dangle
                  name={currentError.current_error?._id}
                  value={currentError.current_error?.marked}
                  onChange={handleChangeMark}
                />
              ) : (
                <Checkbox
                  color="secondary"
                  checked={currentError.current_error?.marked}
                  // eslint-disable-next-line no-underscore-dangle
                  name={currentError.current_error?._id}
                  value={currentError.current_error?.marked}
                  onChange={handleChangeMark}
                  disabled
                />
              )}
            </div>
            <div>
              <div className="divider" />
            </div>
            <div>
              {currentError?.current_error?.completed_at ? (
                <StatusLabel color={themeContext.button_success}>
                  <p>{t('@logsDetails/RESOLVED', 'Resolvido')}</p>
                </StatusLabel>
              ) : (
                <StatusLabel color={themeContext.button_danger}>
                  <p>{t('@logsDetails/TO_RESOVE', 'Aberto')}</p>
                </StatusLabel>
              )}
            </div>
            <div>
              <div className="divider" />
            </div>
            <div>
              <div className="category">
                <p>{currentError.current_error?.action}</p>
              </div>
            </div>
            <div>
              <div className="divider" />
            </div>
            <div>
              <div className="date">
                <div className="hourSection">
                  {currentError.current_error?.created_at && (
                    <>
                      <p>
                        <b>
                          {getDate(
                            parseJSON(currentError?.current_error?.created_at),
                          )}
                        </b>{' '}
                        {format(
                          parseJSON(currentError?.current_error?.created_at),
                          'MMM',
                          {
                            locale: dateLanguagePack,
                          },
                        )}{' '}
                        {format(
                          parseJSON(currentError?.current_error?.created_at),
                          'yyyy',
                          {
                            locale: dateLanguagePack,
                          },
                        )}
                      </p>
                      <p className="hour">
                        {format(
                          parseJSON(currentError?.current_error?.created_at),
                          'p',
                          {
                            locale: dateLanguagePack,
                          },
                        )}
                        {' min'}
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div>
              <div className="divider" />
            </div>
            <div>
              {currentError?.current_error?.payload?.provider?.provider
                ?.name && (
                <StatusLabel color={themeContext.font_secondary}>
                  {
                    currentError?.current_error?.payload?.provider?.provider
                      ?.name
                  }
                </StatusLabel>
              )}
            </div>
            <div>
              <div className="divider" />
            </div>
            <div>
              <div className="userOrBranch">
                <div className="name">
                  <p>{currentError?.current_error?.user?.name}</p>
                </div>
                <div className="code">
                  <p>{currentError?.current_error?.user?.sale_codes[0].code}</p>
                </div>
              </div>
            </div>
            <div>
              <div className="divider" />
            </div>
            <div>
              <div className="userOrBranch">
                <div className="name">
                  <p>{currentError?.current_error?.user?.store?.name}</p>
                </div>
                <div className="code">
                  <p>{currentError?.current_error?.user?.store?.code}</p>
                </div>
              </div>
            </div>
            <div>
              <div className="divider" />
            </div>
            <div className="detailsButton">
              <MdKeyboardArrowDown color={themeContext.font_secondary} />
            </div>
          </div>
        </LogsListComponent>
        <LogDetails>
          <div className="detailsTitle">
            <p>{t('@logsDetails/EVENT_DETAILS', 'Detalhes do Evento')}</p>
          </div>
          <div className="infoSection">
            <div className="generalInfoSection">
              <div className="generalInfo">
                <div className="generalInfoTitle">
                  <p>
                    {t(
                      '@logsDetails/ASSOCIATED_ORDER_DATA',
                      'Dados de Pedido Associado:',
                    )}
                  </p>
                </div>
                <div className="horizontalDivider" />
                <div className="generalInfoContent">
                  <p>
                    {t('@logsDetails/PAYMENT_METHOD', 'Método de Pagamento:')}{' '}
                    {currentError.current_error?.payload?.provider?.provider
                      ?.payment_type.name ? (
                      <>
                        {
                          currentError.current_error?.payload?.provider
                            ?.provider?.payment_type.name
                        }
                      </>
                    ) : (
                      <span>Não houve retorno de informações.</span>
                    )}
                  </p>
                  <p>
                    {t('@logsDetails/COUPON', 'Cupom:')}{' '}
                    {currentError.current_error?.request_body?.coupon ? (
                      <>{currentError.current_error?.request_body?.coupon}</>
                    ) : (
                      <span>Não houve retorno de informações.</span>
                    )}
                  </p>
                  {/* Add this block when the api is enabled to bring associated order */}
                  {/* <p>
                    {t('@logsDetails/ASSOCIATED_ORDER', 'Pedido Associado:')}{' '}
                  </p> */}
                </div>
              </div>
              <div className="generalInfo">
                <div className="generalInfoTitle">
                  <p>{t('@logsDetails/OCCURRENCE', 'Ocorrência')}</p>
                </div>
                <div className="horizontalDivider" />
                <div className="generalInfoContent">
                  <p>
                    {t('@logsDetails/METHOD', 'Método:')}{' '}
                    {currentError.current_error?.method}
                  </p>
                  <p>
                    {t('@logsDetails/HTTP_STATUS', ' Status HTTP:')}{' '}
                    {currentError.current_error?.status}
                  </p>
                  <p>
                    {t('@logsDetails/DURATION', 'Duração:')}{' '}
                    {currentError.current_error?.duration && (
                      <>
                        {getFormattedTime(currentError.current_error?.duration)}
                      </>
                    )}
                  </p>
                  <p>
                    {t('@logsDetails/EVENT', 'Evento:')}{' '}
                    {currentError?.current_error?.payload.completed_at
                      ? 'Resolvido'
                      : 'Aberto'}
                  </p>
                </div>
              </div>
              <div className="generalInfo">
                <div className="generalInfoTitle">
                  <p>{t('@logsDetails/ERROR_MESSAGE', 'Mensagem do Erro')}</p>
                </div>
                <div className="horizontalDivider" />
                <div className="generalInfoContent">
                  <div className="errorMessage">
                    {currentError.current_error?.message}
                  </div>
                </div>
              </div>
            </div>
            <div className="divider" />
            <div className="jsonInfoSection">
              <div className="jsonInfo">
                <div className="jsonInfoTitle">
                  <p>{t('@logsDetails/JSON_REQUEST', 'Requisição JSON')}</p>
                </div>
                <div className="horizontalDivider" />
                <div className="jsonInfoContent">
                  <div className="json">
                    <pre>
                      {JSON.stringify(
                        currentError.current_error?.payload?.request,
                        null,
                        4,
                      )}
                    </pre>
                  </div>
                </div>
              </div>
              <div className="jsonInfo">
                <div className="jsonInfoTitle">
                  <p>{t('@logsDetails/JSON_RESPONSE', 'Retorno JSON')}</p>
                </div>
                <div className="horizontalDivider" />
                <div className="jsonInfoContent">
                  <div className="json">
                    <pre>
                      {JSON.stringify(
                        currentError.current_error?.payload?.response,
                        null,
                        4,
                      )}
                    </pre>
                  </div>
                  <p>
                    {t('@logsDetails/REQUEST_STATUS', 'Status de Retorno:')}{' '}
                    {currentError.current_error?.status}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </LogDetails>
      </LogsPanel>
      {modals}
    </Container>
  );
};

export default ErrorLogDetails;
