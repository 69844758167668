import styled from 'styled-components';

// Interfaces
interface IContainerProps {
  color?: string;
  hide: boolean;
  noMargin: boolean;
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  cursor: pointer;
  user-select: none;

  .MuiSwitch-switchBase {
    &.Mui-checked {
      color: ${({ theme }) => theme.button_success};
    }
    &.Mui-checked + .MuiSwitch-track {
      background-color: ${({ theme }) => theme.button_success};
    }
  }
`;
