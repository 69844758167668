import React, {
  useRef,
  useCallback,
  useState,
  useContext,
  useMemo,
} from 'react';
import { Form } from '@unform/web';
import { FormHandles, SubmitHandler } from '@unform/core';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { IoMdArrowDropright, IoMdArrowDropdown } from 'react-icons/io';
import { MdDeleteOutline } from 'react-icons/md';
import { BiPencil } from 'react-icons/bi';
import { ThemeContext } from 'styled-components';

// Component import
import Button from '@components/Button';
import Input from '@components/Input';
import Select, { ISelectRef } from '@components/Select';
import Switch from '@components/Switch';
import Modal, { IModalRef } from '@components/Modal';
import Loading from '@components/Loading';

// Hook import
import { useCurrentStore } from '@hooks/useCurrentStore';

// Service import
import api from '@services/api';

// Page import
import NoMoreBrandsNotice from './NoMoreBrandsNotice';
import NoMorePackageTypesNotice from './NoMorePackageTypesNotice';
import NoMorePaymentProvidersNotice from './NoMorePaymentProvidersNotice';

// Style import
import { Container, Pill } from './styles';

// Interfaces
interface IProps {
  hide: () => any;
}
interface IPaymentProviderEdition {
  manualPhysicalCard: boolean;
  stoneMundipagg: boolean;
  stoneMundipaggLink: boolean;
}

const StoreEdition: React.FC<IProps> = ({ hide }) => {
  // Get context
  const currentStore = useCurrentStore();

  // Local states
  const [loading, setLoading] = useState(false);
  const [switchExpanded, setSwitchExpanded] = useState({
    expandedBrand: false,
    expandedPackageType: false,
    expandedPayment: false,
  });
  const [addButton, setAddButton] = useState({
    addBrand: false,
    addPackageType: false,
    addPaymentType: false,
    addPaymentProvider: false,
  });
  const [paymentButtons, setPaymentButtons] = useState(false);
  const [manualPhysicalCard, setManualPhysicalCard] = useState(false);
  const [stoneMundipagg, setStoneMundipagg] = useState(false);
  const [stoneMundipaggLink, setStoneMundipaggLink] = useState(false);
  const [
    manualPhysicalCardCreationEnable,
    setManualPhysicalCardCreationEnable,
  ] = useState(true);
  const [stoneMundipaggCreationEnable, setStoneMundipaggCreationEnable] =
    useState(true);
  const [
    stoneMundipaggLinkCreationEnable,
    setStoneMundipaggLinkCreationEnable,
  ] = useState(true);
  const [paymentProviderEdition, setPaymentProviderEdition] =
    useState<IPaymentProviderEdition>({
      manualPhysicalCard: false,
      stoneMundipagg: false,
      stoneMundipaggLink: false,
    });

  // Local refs
  const formStoreEditionRef = useRef<FormHandles>(null);
  const formBrandEditionRef = useRef<FormHandles>(null);
  const formPackageEditionRef = useRef<FormHandles>(null);
  const formPaymentCreationRef = useRef<FormHandles>(null);
  const formPaymentEditionRef = useRef<FormHandles>(null);
  const brandsSelectRef = useRef<ISelectRef>(null);
  const packageTypesSelectRef = useRef<ISelectRef>(null);
  const noMoreBrandsNoticeRef = useRef<IModalRef>(null);
  const noMorePackageTypesNoticeRef = useRef<IModalRef>(null);
  const noMorePaymentProvidersNoticeRef = useRef<IModalRef>(null);
  const storeNameRef = useRef<HTMLInputElement>(null);
  const storeZipCodeRef = useRef<HTMLInputElement>(null);
  const paymentProviderTokenPhysicalCardRef = useRef<ISelectRef>(null);
  const paymentProviderTokenPaymentLinkRef = useRef<ISelectRef>(null);
  const paymentProviderTerminalsPhysicalCardRef = useRef<ISelectRef>(null);
  const paymentProviderMaxInstallmentsQuantityPaymentLinkRef =
    useRef<ISelectRef>(null);
  const paymentProviderMinInstallmentsQuantityPaymentLinkRef =
    useRef<ISelectRef>(null);

  // Local constants
  const allPossibleBrands = currentStore.possible_brands;
  const currentBrandsIds = currentStore.current_store?.brands.map(
    item => item.brand_id,
  );
  const actualPossibleBrands = allPossibleBrands.filter(
    item => !currentBrandsIds?.includes(item.id),
  );
  const allPossiblePackageTypes = currentStore.possible_package_types;
  const currentPackageTypesIds = currentStore.current_store?.package_types.map(
    item => item.package_type_id,
  );
  const actualPossiblePackageTypes = allPossiblePackageTypes.filter(
    item => !currentPackageTypesIds?.includes(item.id),
  );
  const allPossiblePaymentProviders = currentStore.possible_payment_providers;
  const currentPaymentProvidersIds =
    currentStore.current_store?.payment_providers.map(
      paymentProviders => paymentProviders.payment_provider_id,
    );
  const allPossiblePaymentProvidersIds = allPossiblePaymentProviders.map(
    paymentProviders => paymentProviders.id,
  );
  const paymentProviderButtonsIds = allPossiblePaymentProvidersIds.filter(
    item => !currentPaymentProvidersIds?.includes(item),
  );
  const paymentProviderButtons = allPossiblePaymentProviders.filter(item =>
    paymentProviderButtonsIds.includes(item.id),
  );
  const currentPaymentProviders = currentStore.current_store?.payment_providers;
  const currentPaymentTypes = currentStore.current_store?.payment_types;

  // Get translation function
  const { t } = useTranslation();

  // Theme context
  const themeContext = useContext(ThemeContext);

  // Update current store function
  const updateCurrentStore = useCallback(async () => {
    // Loading set
    setLoading(true);

    try {
      const newStoreResponse = await api.get(
        `admin/store/${currentStore.current_store?.id}`,
      );

      currentStore.set_current_store(newStoreResponse.data);

      setLoading(false);
    } catch (err: any) {
      // Check if server had return a error
      if (err.response?.data?.message) toast.error(err.response.data.message);
      else {
        toast.error(
          t(
            '@general/CONNECTION_FAILURE_ERROR',
            'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
          ),
        );
      }

      // Loading set
      setLoading(false);

      throw err;
    }
  }, [currentStore, t]);

  // Form submit functions
  const handleStoreEditionSubmit: SubmitHandler<{
    name: string;
    zipCode: string;
  }> = useCallback(
    async ({ name, zipCode }) => {
      const formattedZipCode = zipCode.replace(/[^0-9]/g, '');

      const currentStoreName = currentStore.current_store?.name;
      const currentStoreZipCode = currentStore.current_store?.zipcode;

      // Loading set
      setLoading(true);
      if (
        currentStoreName !== name ||
        currentStoreZipCode !== formattedZipCode
      ) {
        try {
          await api.patch(`admin/store/${currentStore.current_store?.id}`, {
            name: name.toUpperCase(),
            zipcode: formattedZipCode,
          });
          setLoading(false);
        } catch (err: any) {
          // Check if server had return a error
          if (err.response?.data?.message)
            toast.error(err.response.data.message);
          else {
            toast.error(
              t(
                '@general/CONNECTION_FAILURE_ERROR',
                'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
              ),
            );
          }
          setLoading(false);
        }
      }

      updateCurrentStore();
      hide();
    },
    [
      currentStore.current_store?.id,
      currentStore.current_store?.name,
      currentStore.current_store?.zipcode,
      hide,
      t,
      updateCurrentStore,
    ],
  );
  const handleBrandEditionSubmit: SubmitHandler<{
    brands: string[];
  }> = useCallback(
    async ({ brands }) => {
      // Get brands ids to add
      const currentBrands = currentStore.current_store?.brands.map(
        (brand: { brand_id: any }) => brand.brand_id,
      );

      const brandsToAdd = brands.filter(
        (item: string) => !currentBrands?.includes(item),
      );

      const addBrandsPromises = brandsToAdd.map(async item => {
        setLoading(true);
        try {
          await api.post(`admin/store/brand/`, {
            brand_id: item,
            store_id: currentStore.current_store?.id,
            enabled: true,
          });
          setLoading(false);
        } catch (err: any) {
          // Check if server had return a error
          if (err.response?.data?.message)
            toast.error(err.response.data.message);
          else {
            toast.error(
              t(
                '@general/CONNECTION_FAILURE_ERROR',
                'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
              ),
            );
          }
          setLoading(false);
        }
      });

      await Promise.all(addBrandsPromises);
      updateCurrentStore();
    },
    [
      currentStore.current_store?.brands,
      currentStore.current_store?.id,
      t,
      updateCurrentStore,
    ],
  );
  const handlePackageEditionSubmit: SubmitHandler<{
    package_types: string[];
  }> = useCallback(
    async ({ package_types }) => {
      // Get package types ids to add or remove
      const currentPackageTypes = currentStore.current_store?.package_types.map(
        (package_type: { package_type_id: any }) =>
          package_type.package_type_id,
      );

      const packageTypesToAdd = package_types.filter(
        (item: string) => !currentPackageTypes?.includes(item),
      );

      // Create a promise to add package types
      const addPackageTypesPromises = packageTypesToAdd.map(async item => {
        try {
          await api.post(`admin/store/package-type/`, {
            package_type_id: item,
            store_id: currentStore.current_store?.id,
            enabled: true,
          });
        } catch (err: any) {
          // Check if server had return a error
          if (err.response?.data?.message)
            toast.error(err.response.data.message);
          else {
            toast.error(
              t(
                '@general/CONNECTION_FAILURE_ERROR',
                'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
              ),
            );
          }
        }
      });

      await Promise.all(addPackageTypesPromises);
      updateCurrentStore();
    },
    [
      currentStore.current_store?.id,
      currentStore.current_store?.package_types,
      t,
      updateCurrentStore,
    ],
  );
  const handlePaymentCreationSubmit: SubmitHandler<{
    paymentProviderManualPhysicalCardId: string;
    paymentTypeManualPhysicalCardId: string;

    paymentProviderStoneMundipaggId: string;
    paymentTypeStoneMundipaggId: string;
    paymentProviderTokenPhysicalCard: string;
    paymentProviderTerminalsPhysicalCard: string;

    paymentProviderStoneMundipaggLinkId: string;
    paymentTypeStoneMundipaggLinkId: string;
    paymentProviderTokenPaymentLink: string;
    paymentProviderMaxInstallmentQuantityPaymentLink: string;
    paymentProviderMinInstallmentQuantityPaymentLink: string;
  }> = useCallback(
    async ({
      paymentProviderManualPhysicalCardId,
      paymentTypeManualPhysicalCardId,

      paymentProviderStoneMundipaggId,
      paymentTypeStoneMundipaggId,
      paymentProviderTokenPhysicalCard,
      paymentProviderTerminalsPhysicalCard,

      paymentProviderStoneMundipaggLinkId,
      paymentTypeStoneMundipaggLinkId,
      paymentProviderTokenPaymentLink,
      paymentProviderMaxInstallmentQuantityPaymentLink,
      paymentProviderMinInstallmentQuantityPaymentLink,
    }) => {
      const arrayPaymentProviderTerminalsPhysicalCard =
        paymentProviderTerminalsPhysicalCard.split(',');
      // Create a promise to add payment providers
      const addPaymentProvidersPromises = paymentProviderButtons.map(
        async item => {
          if (item.name === 'Cartão Físico Manual' && manualPhysicalCard) {
            try {
              await api.post(`admin/store/payment-provider/`, {
                store_id: currentStore.current_store?.id,
                payment_provider_id: paymentProviderManualPhysicalCardId,
                enabled: manualPhysicalCardCreationEnable,
                payload: {},
              });

              await api.post(`admin/store/payment-type/`, {
                store_id: currentStore.current_store?.id,
                payment_type_id: paymentTypeManualPhysicalCardId,
                enabled: true,
              });
            } catch (err: any) {
              // Check if server had return a error
              if (err.response?.data?.message)
                toast.error(err.response.data.message);
              else {
                toast.error(
                  t(
                    '@general/CONNECTION_FAILURE_ERROR',
                    'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
                  ),
                );
              }
            }
          }
          if (item.name === 'Stone Mundipagg' && stoneMundipagg) {
            try {
              await api.post(`admin/store/payment-provider/`, {
                store_id: currentStore.current_store?.id,
                payment_provider_id: paymentProviderStoneMundipaggId,
                enabled: stoneMundipaggCreationEnable,
                payload: {
                  token: paymentProviderTokenPhysicalCard,
                  terminals: arrayPaymentProviderTerminalsPhysicalCard,
                },
              });

              await api.post(`admin/store/payment-type/`, {
                store_id: currentStore.current_store?.id,
                payment_type_id: paymentTypeStoneMundipaggId,
                enabled: true,
              });
            } catch (err: any) {
              // Check if server had return a error
              if (err.response?.data?.message)
                toast.error(err.response.data.message);
              else {
                toast.error(
                  t(
                    '@general/CONNECTION_FAILURE_ERROR',
                    'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
                  ),
                );
              }
            }
          }
          if (item.name === 'Stone Mundipagg - Link' && stoneMundipaggLink) {
            try {
              await api.post(`admin/store/payment-provider/`, {
                store_id: currentStore.current_store?.id,
                payment_provider_id: paymentProviderStoneMundipaggLinkId,
                enabled: stoneMundipaggLinkCreationEnable,
                payload: {
                  token: paymentProviderTokenPaymentLink,
                  max_installment_quantity: Number(
                    paymentProviderMaxInstallmentQuantityPaymentLink,
                  ),
                  minimum_installment_quantity: Number(
                    paymentProviderMinInstallmentQuantityPaymentLink,
                  ),
                },
              });

              await api.post(`admin/store/payment-type/`, {
                store_id: currentStore.current_store?.id,
                payment_type_id: paymentTypeStoneMundipaggLinkId,
                enabled: true,
              });
            } catch (err: any) {
              // Check if server had return a error
              if (err.response?.data?.message)
                toast.error(err.response.data.message);
              else {
                toast.error(
                  t(
                    '@general/CONNECTION_FAILURE_ERROR',
                    'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
                  ),
                );
              }
            }
          }
        },
      );

      await Promise.all(addPaymentProvidersPromises);
      updateCurrentStore();
    },
    [
      currentStore.current_store?.id,
      manualPhysicalCard,
      manualPhysicalCardCreationEnable,
      paymentProviderButtons,
      stoneMundipagg,
      stoneMundipaggCreationEnable,
      stoneMundipaggLink,
      stoneMundipaggLinkCreationEnable,
      t,
      updateCurrentStore,
    ],
  );
  const handlePaymentEditionSubmit: SubmitHandler<{
    paymentProviderManualPhysicalCardId: string;
    paymentTypeManualPhysicalCardId: string;

    paymentProviderStoneMundipaggId: string;
    paymentTypeStoneMundipaggId: string;
    paymentProviderTokenPhysicalCard: string;
    paymentProviderTerminalsPhysicalCard: string;
    paymentProviderStoneMundipaggRelationId: string;

    paymentProviderStoneMundipaggLinkId: string;
    paymentTypeStoneMundipaggLinkId: string;
    paymentProviderTokenPaymentLink: string;
    paymentProviderMaxInstallmentQuantityPaymentLink: string;
    paymentProviderMinInstallmentQuantityPaymentLink: string;
    paymentProviderStoneMundipaggLinkRelationId: string;
  }> = useCallback(
    async ({
      paymentProviderTokenPhysicalCard,
      paymentProviderTerminalsPhysicalCard,
      paymentProviderStoneMundipaggRelationId,

      paymentProviderTokenPaymentLink,
      paymentProviderMaxInstallmentQuantityPaymentLink,
      paymentProviderMinInstallmentQuantityPaymentLink,
      paymentProviderStoneMundipaggLinkRelationId,
    }) => {
      let formattedPaymentProviderTerminalsPhysicalCard: string[] | string;

      if (typeof paymentProviderTerminalsPhysicalCard === 'string') {
        formattedPaymentProviderTerminalsPhysicalCard =
          paymentProviderTerminalsPhysicalCard.split(',');
      } else {
        formattedPaymentProviderTerminalsPhysicalCard =
          paymentProviderTerminalsPhysicalCard;
      }

      const currentStoneMundipagg =
        currentStore.current_store?.payment_providers.filter(
          item => item.provider.name === 'Stone Mundipagg',
        )[0];
      const currentStoneMundipaggToken = currentStoneMundipagg?.payload?.token;
      const currentStoneMundipaggTerminals =
        currentStoneMundipagg?.payload?.terminals;

      const currentStoneMundipaggLink =
        currentStore.current_store?.payment_providers.filter(
          item => item.provider.name === 'Stone Mundipagg - Link',
        )[0];
      const currentStoneMundipaggLinkToken =
        currentStoneMundipaggLink?.payload?.token;
      const currentStoneMundipaggLinkMaxInstallmentQuantityPaymentLink =
        currentStoneMundipaggLink?.payload?.max_installment_quantity;
      const currentStoneMundipaggLinkMinInstallmentQuantityPaymentLink =
        currentStoneMundipaggLink?.payload?.minimum_installment_quantity;

      // Create a promise to edit payment providers

      let editPaymentProvidersPromises: any = [];
      if (currentPaymentProviders) {
        editPaymentProvidersPromises = currentPaymentProviders?.map(
          async item => {
            if (
              item.provider.name === 'Stone Mundipagg' &&
              (currentStoneMundipaggToken !==
                paymentProviderTokenPhysicalCard ||
                currentStoneMundipaggTerminals !==
                  formattedPaymentProviderTerminalsPhysicalCard) &&
              paymentProviderStoneMundipaggRelationId
            ) {
              try {
                await api.patch(
                  `admin/store/payment-provider/${paymentProviderStoneMundipaggRelationId}`,
                  {
                    payload: {
                      token: paymentProviderTokenPhysicalCard,
                      terminals: formattedPaymentProviderTerminalsPhysicalCard,
                    },
                  },
                );
              } catch (err: any) {
                // Check if server had return a error
                if (err.response?.data?.message)
                  toast.error(err.response.data.message);
                else {
                  toast.error(
                    t(
                      '@general/CONNECTION_FAILURE_ERROR',
                      'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
                    ),
                  );
                }
              }
            }
            if (
              item.provider.name === 'Stone Mundipagg - Link' &&
              (currentStoneMundipaggLinkToken !==
                paymentProviderTokenPaymentLink ||
                currentStoneMundipaggLinkMaxInstallmentQuantityPaymentLink !==
                  Number(paymentProviderMaxInstallmentQuantityPaymentLink) ||
                currentStoneMundipaggLinkMinInstallmentQuantityPaymentLink !==
                  Number(paymentProviderMinInstallmentQuantityPaymentLink)) &&
              paymentProviderStoneMundipaggLinkRelationId
            ) {
              try {
                await api.patch(
                  `admin/store/payment-provider/${paymentProviderStoneMundipaggLinkRelationId}`,
                  {
                    payload: {
                      token: paymentProviderTokenPaymentLink,
                      max_installment_quantity: Number(
                        paymentProviderMaxInstallmentQuantityPaymentLink,
                      ),
                      minimum_installment_quantity: Number(
                        paymentProviderMinInstallmentQuantityPaymentLink,
                      ),
                    },
                  },
                );
              } catch (err: any) {
                // Check if server had return a error
                if (err.response?.data?.message)
                  toast.error(err.response.data.message);
                else {
                  toast.error(
                    t(
                      '@general/CONNECTION_FAILURE_ERROR',
                      'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
                    ),
                  );
                }
              }
            }
          },
        );
      }

      await Promise.all(editPaymentProvidersPromises);
      updateCurrentStore();
    },
    [
      currentPaymentProviders,
      currentStore.current_store?.payment_providers,
      t,
      updateCurrentStore,
    ],
  );

  // Switch and delete functions
  const changeSwitchStoreStatus = useCallback(async () => {
    // Loading set
    setLoading(true);

    try {
      await api.patch(`admin/store/${currentStore.current_store?.id}`, {
        enabled: !currentStore.current_store?.enabled,
      });

      const newStoreResponse = await api.get(
        `admin/store/${currentStore.current_store?.id}`,
      );

      currentStore.set_current_store(newStoreResponse.data);
      setLoading(false);
    } catch (err: any) {
      // Check if server had return a error
      if (err.response?.data?.message) toast.error(err.response.data.message);
      else {
        toast.error(
          t(
            '@general/CONNECTION_FAILURE_ERROR',
            'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
          ),
        );
      }

      // Loading set
      setLoading(false);

      throw err;
    }
  }, [currentStore, t]);
  const deleteBrand = useCallback(
    async storeBrandId => {
      // Loading set
      setLoading(true);

      try {
        await api.delete(`admin/store/brand/${storeBrandId}`, {});

        const newStoreResponse = await api.get(
          `admin/store/${currentStore.current_store?.id}`,
        );

        currentStore.set_current_store(newStoreResponse.data);
        setLoading(false);
      } catch (err: any) {
        // Check if server had return a error
        if (err.response?.data?.message) toast.error(err.response.data.message);
        else {
          toast.error(
            t(
              '@general/CONNECTION_FAILURE_ERROR',
              'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
            ),
          );
        }

        // Loading set
        setLoading(false);

        throw err;
      }
    },
    [currentStore, t],
  );
  const changeSwitchBrandStatus = useCallback(
    async storeBrand => {
      // Loading set
      setLoading(true);

      try {
        await api.patch(`admin/store/brand/${storeBrand.id}`, {
          enabled: !storeBrand.enabled,
        });

        const newStoreResponse = await api.get(
          `admin/store/${currentStore.current_store?.id}`,
        );

        currentStore.set_current_store(newStoreResponse.data);
        setLoading(false);
      } catch (err: any) {
        // Check if server had return a error
        if (err.response?.data?.message) toast.error(err.response.data.message);
        else {
          toast.error(
            t(
              '@general/CONNECTION_FAILURE_ERROR',
              'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
            ),
          );
        }

        // Loading set
        setLoading(false);

        throw err;
      }
    },
    [currentStore, t],
  );
  const deletePackageType = useCallback(
    async storePackageTypeId => {
      // Loading set
      setLoading(true);

      try {
        await api.delete(`admin/store/package-type/${storePackageTypeId}`, {});

        const newStoreResponse = await api.get(
          `admin/store/${currentStore.current_store?.id}`,
        );

        currentStore.set_current_store(newStoreResponse.data);
        setLoading(false);
      } catch (err: any) {
        // Check if server had return a error
        if (err.response?.data?.message) toast.error(err.response.data.message);
        else {
          toast.error(
            t(
              '@general/CONNECTION_FAILURE_ERROR',
              'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
            ),
          );
        }

        // Loading set
        setLoading(false);

        throw err;
      }
    },
    [currentStore, t],
  );
  const changeSwitchPackageTypeStatus = useCallback(
    async storePackageType => {
      // Loading set
      setLoading(true);

      try {
        await api.patch(`admin/store/package-type/${storePackageType.id}`, {
          enabled: !storePackageType.enabled,
        });

        const newStoreResponse = await api.get(
          `admin/store/${currentStore.current_store?.id}`,
        );

        currentStore.set_current_store(newStoreResponse.data);
        setLoading(false);
      } catch (err: any) {
        // Check if server had return a error
        if (err.response?.data?.message) toast.error(err.response.data.message);
        else {
          toast.error(
            t(
              '@general/CONNECTION_FAILURE_ERROR',
              'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
            ),
          );
        }

        // Loading set
        setLoading(false);

        throw err;
      }
    },
    [currentStore, t],
  );
  const deletePaymentProvider = useCallback(
    async storePaymentProvider => {
      // Loading set
      setLoading(true);

      const paymentTypeToDeleteId = currentPaymentTypes?.filter(
        item =>
          item.payment_type_id ===
          storePaymentProvider.provider.payment_type.id,
      )[0].id;

      try {
        await api.delete(
          `admin/store/payment-provider/${storePaymentProvider.id}`,
          {},
        );

        await api.delete(
          `admin/store/payment-type/${paymentTypeToDeleteId}`,
          {},
        );

        const newStoreResponse = await api.get(
          `admin/store/${currentStore.current_store?.id}`,
        );

        currentStore.set_current_store(newStoreResponse.data);
        setLoading(false);
      } catch (err: any) {
        // Check if server had return a error
        if (err.response?.data?.message) toast.error(err.response.data.message);
        else {
          toast.error(
            t(
              '@general/CONNECTION_FAILURE_ERROR',
              'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
            ),
          );
        }

        // Loading set
        setLoading(false);

        throw err;
      }
    },
    [currentPaymentTypes, currentStore, t],
  );
  const changeSwitchPaymentProviderStatus = useCallback(
    async storePaymentProvider => {
      // Loading set
      setLoading(true);

      try {
        await api.patch(
          `admin/store/payment-provider/${storePaymentProvider.id}`,
          {
            enabled: !storePaymentProvider.enabled,
          },
        );

        const newStoreResponse = await api.get(
          `admin/store/${currentStore.current_store?.id}`,
        );

        currentStore.set_current_store(newStoreResponse.data);
        setLoading(false);
      } catch (err: any) {
        // Check if server had return a error
        if (err.response?.data?.message) toast.error(err.response.data.message);
        else {
          toast.error(
            t(
              '@general/CONNECTION_FAILURE_ERROR',
              'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
            ),
          );
        }

        // Loading set
        setLoading(false);

        throw err;
      }
    },
    [currentStore, t],
  );

  // Modals
  const modals = useMemo(
    () => (
      <>
        <Modal ref={noMoreBrandsNoticeRef} size="small">
          <NoMoreBrandsNotice
            hide={() => {
              noMoreBrandsNoticeRef.current?.hide();
            }}
          />
        </Modal>
        <Modal ref={noMorePackageTypesNoticeRef} size="small">
          <NoMorePackageTypesNotice
            hide={() => {
              noMorePackageTypesNoticeRef.current?.hide();
            }}
          />
        </Modal>
        <Modal ref={noMorePaymentProvidersNoticeRef} size="small">
          <NoMorePaymentProvidersNotice
            hide={() => {
              noMorePaymentProvidersNoticeRef.current?.hide();
            }}
          />
        </Modal>
      </>
    ),
    [],
  );

  return (
    <Container>
      <Form ref={formStoreEditionRef} onSubmit={handleStoreEditionSubmit}>
        <div className="titleRow">
          <p>{t('@stores/STORE_TITLE', 'Loja')}</p>
          <Switch
            checked={currentStore.current_store?.enabled}
            onChange={() => changeSwitchStoreStatus()}
          />
        </div>
        <div className="titleRow">
          <div className="wideField">
            <Input
              ref={storeNameRef}
              name="name"
              label="Nome"
              value={currentStore.current_store?.name}
            />
          </div>
          <div className="tightField">
            <Input
              ref={storeZipCodeRef}
              name="zipCode"
              label="CEP"
              value={currentStore.current_store?.zipcode}
              onKeyUp={(e: any) =>
                String(e.target.value).match(/\d+/g)?.join('')?.length === 8
              }
              mask="99.999-999"
              type="tel"
              inputmode="numeric"
              pattern="[0-9]*"
            />
          </div>
          <div className="tightField">
            <div className="lastTightField">
              <Input
                name="code"
                label="Código Filial"
                value={currentStore.current_store?.code}
                blockEnter
                readOnly
              />
            </div>
          </div>
        </div>
        {loading ? (
          <div className="loading">
            <Loading size={3} />
          </div>
        ) : (
          <div className="editionArea">
            {switchExpanded.expandedBrand === false ? (
              <div className="closedTab">
                <div className="rightArrow">
                  <button
                    type="button"
                    onClick={() =>
                      setSwitchExpanded({
                        expandedBrand: !switchExpanded.expandedBrand,
                        expandedPackageType: switchExpanded.expandedPackageType,
                        expandedPayment: switchExpanded.expandedPayment,
                      })
                    }
                  >
                    <IoMdArrowDropright />
                  </button>
                </div>
                <p className="closedTitle">Marcas</p>
                <div className="pill">
                  {currentStore?.current_store?.brands.map(brand => (
                    <Pill
                      color={
                        brand.enabled
                          ? themeContext.button_success
                          : themeContext.button_secondary
                      }
                    >
                      {brand.brand.name}
                    </Pill>
                  ))}
                </div>
              </div>
            ) : (
              <div className="openTab">
                <div className="title">
                  <div className="downArrow">
                    <button
                      type="button"
                      onClick={() =>
                        setSwitchExpanded({
                          expandedBrand: !switchExpanded.expandedBrand,
                          expandedPackageType:
                            switchExpanded.expandedPackageType,
                          expandedPayment: switchExpanded.expandedPayment,
                        })
                      }
                    >
                      <IoMdArrowDropdown />
                    </button>
                  </div>
                  <p className="text">{t('@stores/BRANDS_TITLE', 'Marcas')}</p>
                </div>
                <div className="content">
                  {currentStore.current_store?.brands?.map(brand => (
                    <div className="contentNameRow">
                      <div />
                      <div className="contentName">
                        <Switch
                          checked={brand.enabled}
                          onChange={() => changeSwitchBrandStatus(brand)}
                        />
                        <p>{brand.brand.name}</p>
                      </div>
                      <button
                        className="contentNameRowButton"
                        type="button"
                        onClick={() => deleteBrand(brand.id)}
                      >
                        <MdDeleteOutline />
                      </button>
                    </div>
                  ))}
                </div>
                <div className="addButtonSection">
                  {addButton.addBrand === false ? (
                    <>
                      {actualPossibleBrands.length ? (
                        <button
                          type="button"
                          className="addButton"
                          onClick={() =>
                            setAddButton({
                              addBrand: !addButton.addBrand,
                              addPackageType: addButton.addPackageType,
                              addPaymentType: addButton.addPaymentType,
                              addPaymentProvider: addButton.addPaymentProvider,
                            })
                          }
                        >
                          {t('@stores/ADD_BRANDS_BUTTON', '+ Adicionar Marca')}
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="addButton"
                          onClick={() => noMoreBrandsNoticeRef.current?.show()}
                        >
                          {t('@stores/ADD_BRANDS_BUTTON', '+ Adicionar Marca')}
                        </button>
                      )}
                    </>
                  ) : (
                    <div className="selectAndSaveButtonSection">
                      <Form
                        ref={formBrandEditionRef}
                        onSubmit={handleBrandEditionSubmit}
                      >
                        <Select
                          name="brands"
                          ref={brandsSelectRef}
                          value={currentStore.selected_brand}
                          multipleSelect
                          options={actualPossibleBrands?.map(brand => {
                            return {
                              label: brand.name,
                              value: brand.id,
                            };
                          })}
                          label={t('@stores/BRANDS_INPUT_LABEL', 'Marcas')}
                        />
                        <Button
                          disabled={loading}
                          onClick={() => {
                            formBrandEditionRef.current?.submitForm();
                            setSwitchExpanded({
                              expandedBrand: false,
                              expandedPackageType: false,
                              expandedPayment: false,
                            });
                          }}
                        >
                          {t(
                            '@stores/SAVE_EDITION_BRAND_BUTTON',
                            'Salvar Alterações',
                          )}
                        </Button>
                      </Form>
                    </div>
                  )}
                </div>
                <hr className="solid" />
              </div>
            )}
            {switchExpanded.expandedPackageType === false ? (
              <div className="closedTab">
                <div className="rightArrow">
                  <button
                    type="button"
                    onClick={() =>
                      setSwitchExpanded({
                        expandedBrand: switchExpanded.expandedBrand,
                        expandedPackageType:
                          !switchExpanded.expandedPackageType,
                        expandedPayment: switchExpanded.expandedPayment,
                      })
                    }
                  >
                    <IoMdArrowDropright />
                  </button>
                </div>
                <p className="closedTitle">Modalidades de Pacote</p>
                <div className="pill">
                  {currentStore.current_store?.package_types.map(
                    packageTypes => (
                      <Pill
                        color={
                          packageTypes.enabled
                            ? themeContext.button_success
                            : themeContext.button_secondary
                        }
                      >
                        {packageTypes.package_type.name}
                      </Pill>
                    ),
                  )}
                </div>
              </div>
            ) : (
              <div className="openTab">
                <div className="title">
                  <div className="downArrow">
                    <button
                      type="button"
                      onClick={() =>
                        setSwitchExpanded({
                          expandedBrand: switchExpanded.expandedBrand,
                          expandedPackageType:
                            !switchExpanded.expandedPackageType,
                          expandedPayment: switchExpanded.expandedPayment,
                        })
                      }
                    >
                      <IoMdArrowDropdown />
                    </button>
                  </div>
                  <p className="text">
                    {t('@stores/PACKAGE_TYPES', 'Modalidades de Pacote')}
                  </p>
                </div>
                <div className="content">
                  {currentStore.current_store?.package_types?.map(
                    packageType => (
                      <div className="contentNameRow">
                        <div />
                        <div className="contentName">
                          <Switch
                            checked={packageType.enabled}
                            onChange={() =>
                              changeSwitchPackageTypeStatus(packageType)
                            }
                          />
                          <p>{packageType.package_type.name}</p>
                        </div>
                        <button
                          className="contentNameRowButton"
                          type="button"
                          onClick={() => deletePackageType(packageType.id)}
                        >
                          <MdDeleteOutline />
                        </button>
                      </div>
                    ),
                  )}
                </div>
                <div className="addButtonSection">
                  {addButton.addPackageType === false ? (
                    <>
                      {actualPossiblePackageTypes.length ? (
                        <button
                          type="button"
                          className="addButton"
                          onClick={() =>
                            setAddButton({
                              addBrand: addButton.addBrand,
                              addPackageType: !addButton.addPackageType,
                              addPaymentType: addButton.addPaymentType,
                              addPaymentProvider: addButton.addPaymentProvider,
                            })
                          }
                        >
                          {t(
                            '@stores/ADD_PACKAGE_TYPES_BUTTON',
                            '+ Adicionar Modalidade de Pacote',
                          )}
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="addButton"
                          onClick={() =>
                            noMorePackageTypesNoticeRef.current?.show()
                          }
                        >
                          {t(
                            '@stores/ADD_PACKAGE_TYPES_BUTTON',
                            '+ Adicionar Modalidade de Pacote',
                          )}
                        </button>
                      )}
                    </>
                  ) : (
                    <div className="selectAndSaveButtonSection">
                      <Form
                        ref={formPackageEditionRef}
                        onSubmit={handlePackageEditionSubmit}
                      >
                        <Select
                          name="package_types"
                          ref={packageTypesSelectRef}
                          value={currentStore.selected_package_type}
                          multipleSelect
                          options={actualPossiblePackageTypes?.map(
                            packageType => {
                              return {
                                label: packageType.name,
                                value: packageType.id,
                              };
                            },
                          )}
                          label={t(
                            '@stores/PACKAGES_INPUT_LABEL',
                            'Modalidades',
                          )}
                        />
                        <Button
                          disabled={loading}
                          onClick={() => {
                            formPackageEditionRef.current?.submitForm();
                            setSwitchExpanded({
                              expandedBrand: false,
                              expandedPackageType: false,
                              expandedPayment: false,
                            });
                          }}
                        >
                          {t(
                            '@stores/SAVE_EDITION_BRAND_BUTTON',
                            'Salvar Alterações',
                          )}
                        </Button>
                      </Form>
                    </div>
                  )}
                </div>
                <hr className="solid" />
              </div>
            )}
            {switchExpanded.expandedPayment === false ? (
              <div className="closedTab">
                <div className="rightArrow">
                  <button
                    type="button"
                    onClick={() =>
                      setSwitchExpanded({
                        expandedBrand: switchExpanded.expandedBrand,
                        expandedPackageType: switchExpanded.expandedPackageType,
                        expandedPayment: !switchExpanded.expandedPayment,
                      })
                    }
                  >
                    <IoMdArrowDropright />
                  </button>
                </div>
                <p className="closedTitle">
                  {t('@stores/PAYMENT_PROVIDERS', 'Modalidades de Pagamento')}
                </p>
                <div className="pill">
                  {currentStore.current_store?.payment_providers.map(
                    paymentProviders => (
                      <Pill
                        color={
                          paymentProviders.enabled
                            ? themeContext.button_success
                            : themeContext.button_secondary
                        }
                      >
                        {paymentProviders.provider.name}
                      </Pill>
                    ),
                  )}
                </div>
              </div>
            ) : (
              <div className="openTab">
                <div className="title">
                  <div className="downArrow">
                    <button
                      type="button"
                      onClick={() =>
                        setSwitchExpanded({
                          expandedBrand: switchExpanded.expandedBrand,
                          expandedPackageType:
                            switchExpanded.expandedPackageType,
                          expandedPayment: !switchExpanded.expandedPayment,
                        })
                      }
                    >
                      <IoMdArrowDropdown />
                    </button>
                  </div>
                  <p className="text">
                    {t('@stores/PAYMENT_PROVIDERS', 'Modalidades de Pagamento')}
                  </p>
                </div>
                <div className="content">
                  {currentStore.current_store?.payment_providers?.map(
                    paymentProvider => (
                      <div className="contentNameRowPayment">
                        <div />
                        <div className="openProvider">
                          {paymentProvider.provider.name ===
                            'Cartão Físico Manual' && (
                            <>
                              {paymentProviderEdition.manualPhysicalCard ===
                              false ? (
                                <div className="openProviderRow">
                                  <div className="openProviderTitle">
                                    <Switch
                                      checked={paymentProvider.enabled}
                                      onChange={() =>
                                        changeSwitchPaymentProviderStatus(
                                          paymentProvider,
                                        )
                                      }
                                    />
                                    <p>{paymentProvider.provider.name}</p>
                                  </div>
                                  <button
                                    type="button"
                                    className="openProviderRowButton"
                                    onClick={() =>
                                      setPaymentProviderEdition({
                                        manualPhysicalCard: true,
                                        stoneMundipagg: false,
                                        stoneMundipaggLink: false,
                                      })
                                    }
                                  >
                                    <BiPencil />
                                  </button>
                                </div>
                              ) : (
                                <Form
                                  ref={formPaymentEditionRef}
                                  onSubmit={handlePaymentEditionSubmit}
                                >
                                  <div className="openProviderRow">
                                    <div className="openProviderTitle">
                                      <Switch
                                        checked={paymentProvider.enabled}
                                        onChange={() =>
                                          changeSwitchPaymentProviderStatus(
                                            paymentProvider,
                                          )
                                        }
                                      />
                                      <p>{paymentProvider.provider.name}</p>
                                    </div>
                                    <button
                                      type="button"
                                      className="openProviderRowButton"
                                      onClick={() =>
                                        deletePaymentProvider(paymentProvider)
                                      }
                                    >
                                      <MdDeleteOutline />
                                    </button>
                                  </div>
                                  <Input
                                    name="paymentTypeFromPaymentProviderManualPhysicalCard"
                                    label="Tipo de Pagamento"
                                    value={
                                      paymentProvider.provider.payment_type.name
                                    }
                                    blockEnter
                                    readOnly
                                  />
                                  <div className="saveButton">
                                    <Button
                                      disabled={loading}
                                      onClick={() => {
                                        setPaymentProviderEdition({
                                          ...paymentProviderEdition,
                                          manualPhysicalCard:
                                            !paymentProviderEdition.manualPhysicalCard,
                                        });
                                      }}
                                    >
                                      {t(
                                        '@stores/SAVE_EDITION_BRAND_BUTTON',
                                        'Salvar Alterações',
                                      )}
                                    </Button>
                                  </div>
                                </Form>
                              )}
                            </>
                          )}
                          {paymentProvider.provider.name ===
                            'Stone Mundipagg' && (
                            <>
                              {paymentProviderEdition.stoneMundipagg ===
                              false ? (
                                <div className="openProviderRow">
                                  <div className="openProviderTitle">
                                    <Switch
                                      checked={paymentProvider.enabled}
                                      onChange={() =>
                                        changeSwitchPaymentProviderStatus(
                                          paymentProvider,
                                        )
                                      }
                                    />
                                    <p>{paymentProvider.provider.name}</p>
                                  </div>
                                  <button
                                    type="button"
                                    className="openProviderRowButton"
                                    onClick={() =>
                                      setPaymentProviderEdition({
                                        manualPhysicalCard: false,
                                        stoneMundipagg: true,
                                        stoneMundipaggLink: false,
                                      })
                                    }
                                  >
                                    <BiPencil />
                                  </button>
                                </div>
                              ) : (
                                <Form
                                  ref={formPaymentEditionRef}
                                  onSubmit={handlePaymentEditionSubmit}
                                >
                                  <div className="openProviderRow">
                                    <div className="openProviderTitle">
                                      <Switch
                                        checked={paymentProvider.enabled}
                                        onChange={() =>
                                          changeSwitchPaymentProviderStatus(
                                            paymentProvider,
                                          )
                                        }
                                      />
                                      <p>{paymentProvider.provider.name}</p>
                                    </div>
                                    <button
                                      type="button"
                                      className="openProviderRowButton"
                                      onClick={() =>
                                        deletePaymentProvider(paymentProvider)
                                      }
                                    >
                                      <MdDeleteOutline />
                                    </button>
                                  </div>
                                  <Input
                                    name="paymentTypeFromPaymentProviderPhysicalCard"
                                    label="Tipo de Pagamento"
                                    value={
                                      paymentProvider.provider.payment_type.name
                                    }
                                    blockEnter
                                    readOnly
                                  />
                                  <Input
                                    hide
                                    name="paymentProviderStoneMundipaggRelationId"
                                    label="Id do Provedor de Pagamento em Relação a Loja"
                                    value={paymentProvider.id}
                                    readOnly
                                  />
                                  <Input
                                    ref={paymentProviderTokenPhysicalCardRef}
                                    name="paymentProviderTokenPhysicalCard"
                                    label="Token"
                                    value={paymentProvider.payload?.token}
                                  />
                                  <Input
                                    ref={
                                      paymentProviderTerminalsPhysicalCardRef
                                    }
                                    name="paymentProviderTerminalsPhysicalCard"
                                    label="Terminais"
                                    value={paymentProvider.payload?.terminals}
                                  />
                                  <div className="saveButton">
                                    <Button
                                      disabled={loading}
                                      onClick={() => {
                                        formPaymentEditionRef.current?.submitForm();
                                        setPaymentProviderEdition({
                                          ...paymentProviderEdition,
                                          stoneMundipagg: false,
                                        });
                                      }}
                                    >
                                      {t(
                                        '@stores/SAVE_EDITION_BRAND_BUTTON',
                                        'Salvar Alterações',
                                      )}
                                    </Button>
                                  </div>
                                </Form>
                              )}
                            </>
                          )}
                          {paymentProvider.provider.name ===
                            'Stone Mundipagg - Link' && (
                            <>
                              {paymentProviderEdition.stoneMundipaggLink ===
                              false ? (
                                <div className="openProviderRow">
                                  <div className="openProviderTitle">
                                    <Switch
                                      checked={paymentProvider.enabled}
                                      onChange={() =>
                                        changeSwitchPaymentProviderStatus(
                                          paymentProvider,
                                        )
                                      }
                                    />
                                    <p>{paymentProvider.provider.name}</p>
                                  </div>
                                  <button
                                    type="button"
                                    className="openProviderRowButton"
                                    onClick={() =>
                                      setPaymentProviderEdition({
                                        manualPhysicalCard: false,
                                        stoneMundipagg: false,
                                        stoneMundipaggLink: true,
                                      })
                                    }
                                  >
                                    <BiPencil />
                                  </button>
                                </div>
                              ) : (
                                <Form
                                  ref={formPaymentEditionRef}
                                  onSubmit={handlePaymentEditionSubmit}
                                >
                                  <div className="openProviderRow">
                                    <div className="openProviderTitle">
                                      <Switch
                                        checked={paymentProvider.enabled}
                                        onChange={() =>
                                          changeSwitchPaymentProviderStatus(
                                            paymentProvider,
                                          )
                                        }
                                      />
                                      <p>{paymentProvider.provider.name}</p>
                                    </div>
                                    <button
                                      className="openProviderRowButton"
                                      type="button"
                                      onClick={() =>
                                        deletePaymentProvider(paymentProvider)
                                      }
                                    >
                                      <MdDeleteOutline />
                                    </button>
                                  </div>
                                  <Input
                                    name="paymentTypeFromPaymentProviderPaymentLink"
                                    label="Tipo de Pagamento"
                                    value={
                                      paymentProvider.provider.payment_type.name
                                    }
                                    blockEnter
                                    readOnly
                                  />
                                  <Input
                                    hide
                                    name="paymentProviderStoneMundipaggLinkRelationId"
                                    label="Id do Provedor de Pagamento em Relação a Loja"
                                    value={paymentProvider.id}
                                    readOnly
                                  />
                                  <Input
                                    ref={paymentProviderTokenPaymentLinkRef}
                                    name="paymentProviderTokenPaymentLink"
                                    label="Token"
                                    value={paymentProvider.payload?.token}
                                  />
                                  <Input
                                    ref={
                                      paymentProviderMaxInstallmentsQuantityPaymentLinkRef
                                    }
                                    name="paymentProviderMaxInstallmentQuantityPaymentLink"
                                    label="Quantidade Máxima de Parcelas"
                                    value={
                                      paymentProvider.payload
                                        ?.max_installment_quantity
                                    }
                                    type="number"
                                  />
                                  <Input
                                    ref={
                                      paymentProviderMinInstallmentsQuantityPaymentLinkRef
                                    }
                                    name="paymentProviderMinInstallmentQuantityPaymentLink"
                                    label="Quantidade Mínima de Parcelas"
                                    value={
                                      paymentProvider.payload
                                        ?.minimum_installment_quantity
                                    }
                                    type="number"
                                  />
                                  <div className="saveButton">
                                    <Button
                                      disabled={loading}
                                      onClick={() => {
                                        formPaymentEditionRef.current?.submitForm();
                                        setPaymentProviderEdition({
                                          ...paymentProviderEdition,
                                          stoneMundipaggLink: false,
                                        });
                                      }}
                                    >
                                      {t(
                                        '@stores/SAVE_EDITION_BRAND_BUTTON',
                                        'Salvar Alterações',
                                      )}
                                    </Button>
                                  </div>
                                </Form>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    ),
                  )}
                </div>
                {paymentButtons === true ? (
                  <>
                    {paymentProviderButtons?.map(item => (
                      <>
                        {item.name === 'Cartão Físico Manual' && (
                          <>
                            {manualPhysicalCard ? (
                              <div className="addProviderArea">
                                <Form
                                  ref={formPaymentCreationRef}
                                  onSubmit={handlePaymentCreationSubmit}
                                >
                                  <div className="addProviderAreaTitle">
                                    <div className="titleAndSwitch">
                                      <Switch
                                        checked={
                                          manualPhysicalCardCreationEnable
                                        }
                                        onChange={() =>
                                          setManualPhysicalCardCreationEnable(
                                            !manualPhysicalCardCreationEnable,
                                          )
                                        }
                                      />
                                      <p>{item.name}</p>
                                    </div>
                                    <button
                                      className="addProviderButton"
                                      type="button"
                                      onClick={() =>
                                        setManualPhysicalCard(false)
                                      }
                                    >
                                      <MdDeleteOutline />
                                    </button>
                                  </div>
                                  <Input
                                    name="paymentTypeFromPaymentProviderManualPhysicalCard"
                                    label="Tipo de Pagamento"
                                    value={item.payment_type.name}
                                    readOnly
                                  />
                                  <Input
                                    hide
                                    name="paymentProviderManualPhysicalCardId"
                                    label="Id do Provedor de Pagamento"
                                    value={item.id}
                                    readOnly
                                  />
                                  <Input
                                    hide
                                    name="paymentTypeManualPhysicalCardId"
                                    label="Id do Tipo de Pagamento"
                                    value={item.payment_type_id}
                                    readOnly
                                  />
                                  <div className="saveButton">
                                    <Button
                                      disabled={loading}
                                      onClick={() => {
                                        formPaymentCreationRef.current?.submitForm();
                                      }}
                                    >
                                      {t(
                                        '@stores/SAVE_EDITION_BRAND_BUTTON',
                                        'Salvar Alterações',
                                      )}
                                    </Button>
                                  </div>
                                </Form>
                              </div>
                            ) : (
                              <div className="providerButton">
                                <button
                                  type="button"
                                  onClick={() => setManualPhysicalCard(true)}
                                >
                                  {`Adicionar ${item.name}`}
                                </button>
                              </div>
                            )}
                          </>
                        )}
                        {item.name === 'Stone Mundipagg' && (
                          <>
                            {stoneMundipagg ? (
                              <div className="addProviderArea">
                                <Form
                                  ref={formPaymentCreationRef}
                                  onSubmit={handlePaymentCreationSubmit}
                                >
                                  <div className="addProviderAreaTitle">
                                    <div className="titleAndSwitch">
                                      <Switch
                                        checked={stoneMundipaggCreationEnable}
                                        onChange={() =>
                                          setStoneMundipaggCreationEnable(
                                            !stoneMundipaggCreationEnable,
                                          )
                                        }
                                      />
                                      <p>{item.name}</p>
                                    </div>
                                    <button
                                      className="addProviderButton"
                                      type="button"
                                      onClick={() => setStoneMundipagg(false)}
                                    >
                                      <MdDeleteOutline />
                                    </button>
                                  </div>
                                  <Input
                                    name="paymentTypeFromPaymentProviderPhysicalCard"
                                    label="Tipo de Pagamento"
                                    value={item.payment_type.name}
                                    readOnly
                                  />
                                  <Input
                                    hide
                                    name="paymentProviderStoneMundipaggId"
                                    label="Id do Provedor de Pagamento"
                                    value={item.id}
                                    readOnly
                                  />
                                  <Input
                                    hide
                                    name="paymentTypeStoneMundipaggId"
                                    label="Id do Tipo de Pagamento"
                                    value={item.payment_type_id}
                                    readOnly
                                  />
                                  <Input
                                    ref={paymentProviderTokenPhysicalCardRef}
                                    name="paymentProviderTokenPhysicalCard"
                                    label="Token"
                                  />
                                  <Input
                                    ref={
                                      paymentProviderTerminalsPhysicalCardRef
                                    }
                                    name="paymentProviderTerminalsPhysicalCard"
                                    label="Terminais"
                                  />
                                  <div className="saveButton">
                                    <Button
                                      disabled={loading}
                                      onClick={() => {
                                        formPaymentCreationRef.current?.submitForm();
                                      }}
                                    >
                                      {t(
                                        '@stores/SAVE_EDITION_BRAND_BUTTON',
                                        'Salvar Alterações',
                                      )}
                                    </Button>
                                  </div>
                                </Form>
                              </div>
                            ) : (
                              <div className="providerButton">
                                <button
                                  type="button"
                                  onClick={() => setStoneMundipagg(true)}
                                >
                                  {`Adicionar ${item.name}`}
                                </button>
                              </div>
                            )}
                          </>
                        )}
                        {item.name === 'Stone Mundipagg - Link' && (
                          <>
                            {stoneMundipaggLink ? (
                              <div className="addProviderArea">
                                <Form
                                  ref={formPaymentCreationRef}
                                  onSubmit={handlePaymentCreationSubmit}
                                >
                                  <div className="addProviderAreaTitle">
                                    <div className="titleAndSwitch">
                                      <Switch
                                        checked={
                                          stoneMundipaggLinkCreationEnable
                                        }
                                        onChange={() =>
                                          setStoneMundipaggLinkCreationEnable(
                                            !stoneMundipaggLinkCreationEnable,
                                          )
                                        }
                                      />
                                      <p>{item.name}</p>
                                    </div>
                                    <button
                                      className="addProviderButton"
                                      type="button"
                                      onClick={() =>
                                        setStoneMundipaggLink(false)
                                      }
                                    >
                                      <MdDeleteOutline />
                                    </button>
                                  </div>
                                  <Input
                                    name="paymentTypeFromPaymentProviderPaymentLink"
                                    label="Tipo de Pagamento"
                                    value={item.payment_type.name}
                                    readOnly
                                  />
                                  <Input
                                    hide
                                    name="paymentProviderStoneMundipaggLinkId"
                                    label="Id do Provedor de Pagamento"
                                    value={item.id}
                                    readOnly
                                  />
                                  <Input
                                    hide
                                    name="paymentTypeStoneMundipaggLinkId"
                                    label="Id do Tipo de Pagamento"
                                    value={item.payment_type_id}
                                    readOnly
                                  />
                                  <Input
                                    ref={paymentProviderTokenPaymentLinkRef}
                                    name="paymentProviderTokenPaymentLink"
                                    label="Token"
                                  />
                                  <Input
                                    ref={
                                      paymentProviderMaxInstallmentsQuantityPaymentLinkRef
                                    }
                                    name="paymentProviderMaxInstallmentQuantityPaymentLink"
                                    label="Quantidade Máxima de Parcelas"
                                    type="number"
                                  />
                                  <Input
                                    ref={
                                      paymentProviderMinInstallmentsQuantityPaymentLinkRef
                                    }
                                    name="paymentProviderMinInstallmentQuantityPaymentLink"
                                    label="Quantidade Mínima de Parcelas"
                                    type="number"
                                  />
                                  <div className="saveButton">
                                    <Button
                                      disabled={loading}
                                      onClick={() => {
                                        formPaymentCreationRef.current?.submitForm();
                                      }}
                                    >
                                      {t(
                                        '@stores/SAVE_EDITION_BRAND_BUTTON',
                                        'Salvar Alterações',
                                      )}
                                    </Button>
                                  </div>
                                </Form>
                              </div>
                            ) : (
                              <div className="providerButton">
                                <button
                                  type="button"
                                  onClick={() => setStoneMundipaggLink(true)}
                                >
                                  {`Adicionar ${item.name}`}
                                </button>
                              </div>
                            )}
                          </>
                        )}
                      </>
                    ))}
                  </>
                ) : (
                  <>
                    {paymentProviderButtons.length !== 0 ? (
                      <div className="addButtonSection">
                        <button
                          className="addButton"
                          type="button"
                          onClick={() => setPaymentButtons(true)}
                        >
                          {t(
                            '@stores/ADD_PAYMENT_PROVIDERS_BUTTON',
                            '+ Adicionar Modalidade de Pagamento',
                          )}
                        </button>
                      </div>
                    ) : (
                      <div className="addButtonSection">
                        <button
                          type="button"
                          className="addButton"
                          onClick={() =>
                            noMorePaymentProvidersNoticeRef.current?.show()
                          }
                        >
                          {t(
                            '@stores/ADD_PAYMENT_PROVIDERS_BUTTON',
                            '+ Adicionar Modalidade de Pagamento',
                          )}
                        </button>
                      </div>
                    )}
                  </>
                )}
                <hr className="solid" />
              </div>
            )}
          </div>
        )}
        <Button
          disabled={loading}
          onClick={() => {
            formStoreEditionRef.current?.submitForm();
          }}
        >
          {t('@stores/SAVE_EDITION_BRAND_BUTTON', 'Salvar Alterações')}
        </Button>
      </Form>
      {modals}
    </Container>
  );
};

export default StoreEdition;
