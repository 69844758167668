import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: left;
  align-items: center;
  border-bottom: 0.09rem solid ${({ theme }) => theme.background_secondary};
  border-top: 0.09rem solid ${({ theme }) => theme.background_secondary};
  padding-top: 1rem;
  padding-bottom: 1rem;

  .alertMessage {
    margin-left: 0.5rem;
    font-size: 1rem;
    color: #f2f2f2;
  }

  .subAlertMessage {
    margin-left: 1.2rem;
    font-size: 0.875rem;
    color: #f2f2f2;
  }
`;
