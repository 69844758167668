import styled, { css } from 'styled-components';

interface DetailsPanelProps {
  checkPage: string;
}

export const Container = styled.div`
  padding: 1.5rem;
  height: 100%;
`;

export const StoresPanel = styled.div`
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .pageTitle {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;

    .backIcon {
      color: ${({ theme }) => theme.button_high};
      font-size: 1.5rem;
    }

    h2 {
      margin-left: 0.5rem;
    }
  }
`;

export const OrdersHeader = styled.div`
  margin-top: 0.5rem;
  width: 100%;

  .header {
    background-color: ${({ theme }) => theme.background_secondary};
    border-top: 0.063rem solid ${({ theme }) => theme.background_secondary};
    border-bottom: 0.063rem solid ${({ theme }) => theme.background_secondary};
    padding: 0.5rem 1rem;
    display: grid;
    grid-template-columns: 1fr 1.2fr 1.2fr repeat(5, 1fr) 0.2fr;
    align-items: center;
    justify-items: center;

    div {
      display: flex;
      align-items: center;

      p {
        color: ${({ theme }) => theme.background_low};
        border: none;
        padding: 0;
        font-weight: 700;
        margin-right: 0.5rem;
        display: flex;
        align-items: center;
      }

      &:nth-child(2),
      &:nth-child(3) {
        justify-content: center;
        align-items: center;
        display: flex;
        width: 100%;
      }

      button {
        font-size: 1rem;
        display: flex;
        align-items: center;
      }
    }
  }

  .rowTable {
    padding: 0.5rem 1rem;
    display: grid;
    grid-template-columns: 1fr 1.2fr 1.2fr repeat(5, 1fr) 0.2fr;
    box-shadow: 0px 0.25rem 0.25rem rgba(0, 0, 0, 0.25);

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-right: ${({ theme }) => theme.background_secondary} solid 1px;
      border-width: 1px;

      .hourSection {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        .hourSectionInfo {
          margin-left: 0.5rem;

          .hour {
            font-size: 0.625rem;
            color: ${({ theme }) => theme.font_secondary};
          }
        }
      }

      .price {
        font-weight: 700;
      }

      .numberCode {
        color: ${({ theme }) => theme.font_secondary};
      }

      .productQuantity {
        color: ${({ theme }) => theme.font_secondary};
        font-size: 0.75rem;
      }
    }

    div:last-child {
      border-right: none;
    }
  }
`;

export const DetailsPanel = styled.div<DetailsPanelProps>`
  width: 100%;
  height: 53rem;
  padding: 1.5rem;
  padding-left: 2.5rem;
  border-radius: 0.25rem;
  margin-top: 1.5rem;

  .buttonsNav {
    width: 100%;
    height: 0%;
    justify-self: center;
    align-self: center;
  }

  ${({ checkPage }) =>
    checkPage === 'packages' &&
    css`
      height: 63rem;
    `}
`;
