import ISortStatus from 'interfaces/ISortStatus';
import React from 'react';
import { BsList, BsSortDown, BsSortUp } from 'react-icons/bs';

type SetSortStatusFunction = React.Dispatch<React.SetStateAction<ISortStatus>>;

const SortDateToggleButton: React.FC<{
  sortStatus: ISortStatus | undefined;
  setSortStatus: SetSortStatusFunction | undefined;
  identifier: keyof ISortStatus;
}> = ({ sortStatus, setSortStatus, identifier }) => {
  const handleToggle = (): void => {
    if (!sortStatus) return;
    const state = sortStatus[identifier] === '1' ? '-1' : '1';
    let update: any;

    if (identifier === 'created_at') {
      update = {
        [identifier]: state,
        updated_at: null,
      };
    } else {
      update = {
        [identifier]: state,
        created_at: null,
      };
    }

    if (setSortStatus) {
      setSortStatus((prevSortStatus: ISortStatus) => ({
        ...prevSortStatus,
        ...update,
      }));
    }
  };

  const showIcon = (v: string | null) => {
    switch (v) {
      case '-1':
        return (
          <>
            <BsSortDown />
          </>
        );
      case '1':
        return (
          <>
            <BsSortUp />
          </>
        );
      default:
        return (
          <>
            <BsList />
          </>
        );
    }
  };

  return (
    <button type="button" onClick={handleToggle}>
      {sortStatus ? showIcon(sortStatus[identifier] ?? null) : showIcon(null)}
    </button>
  );
};

export default SortDateToggleButton;
