/* eslint-disable no-console */
import { Store } from 'redux';
import createSagaMiddleware from 'redux-saga';

// Store import
import createStore from './createStore';

// Type iomport
import { IAuthState } from './modules/auth/types';
import { IUiState } from './modules/ui/types';
import { IStoresState } from './modules/stores/types';

// Root import
import rootReducer from './modules/rootReducer';
import rootSaga from './modules/rootSaga';

// Setup saga
const sagaMonitor =
  process.env.NODE_ENV === 'development'
    ? console.tron.createSagaMonitor()
    : null;

// Handle saga
const sagaMiddleware = createSagaMiddleware({ sagaMonitor });
const middlewares = [sagaMiddleware];

// Application store
export interface IApplicationState {
  auth: IAuthState;
  ui: IUiState;
  stores: IStoresState;
}

// Create store
export const store: Store<IApplicationState> = createStore(
  rootReducer,
  middlewares,
);

sagaMiddleware.run(rootSaga);
