import styled from 'styled-components';

export const Pill = styled.p`
  background-color: ${props => props.color};
  padding: 0.25rem 0.625rem;
  border-radius: 1rem;
  margin: 0.25rem;
  width: fit-content;
  p {
    color: ${({ theme }) => theme.font_low};
  }
`;
