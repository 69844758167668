import styled from 'styled-components';

export const RadioStyle = styled.div`
  .MuiRadio-colorSecondary.Mui-checked {
    margin-left: 3rem;
    margin-right: 1rem;
    display: inline-block;

    color: ${({ theme }) => theme.background_secondary};
  }

  .MuiRadio-root {
    color: ${({ theme }) => theme.background_secondary};
  }
`;
